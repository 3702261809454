@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./common/index.style.scss";
@import "./components/index.style.scss";
@import "./pages/index.scss";

body {
    background: black;
    // background: url("../images/backgrounds/inventory.svg");
    // background-size: cover;
}

body {
    // width: 1636px;
    overflow-x: auto !important;
}

.text-primary {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #70878f;
}

.text-dark {
    color: #70878f;
    font-family: var(--bigShoulders);
}

.dark {
    color: #70878f;
}
.text-yellow {
    color: #e3c96e;
    font-family: var(--bigShoulders);
}

.big-shoulders {
    font-family: var(--bigShoulders);
}

.text-primary-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #70878f;
}

.inactive-image img {
    opacity: 0.1;
    filter: grayscale(100%);
}
.inactive-image2 img {
    opacity: 0.5;
    filter: grayscale(70%);
    padding: 15px;
}

.custom-spinner {
    animation-duration: 0.75s;
    animation: spinner linear infinite forwards;
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360%);
        transform: rotate(360%);
        -ms-transform: rotate(360%);
    }
}

.text-14 {
    font-size: 14px !important;
}
.text-gray {
    color: #70878f;
}
.text-white {
    color: white;
}
.text-red {
    color: #ff5353;
}
.text-orange {
    color: #ff8d24;
}
.text-blue {
    color: #00e1d4;
}
.text-green {
    color: green;
}
.r-container {
    box-sizing: border-box;
    max-width: 90rem !important;
    // padding: 2rem 1rem 1rem 1rem !important;
    margin: auto !important;
    display: flex;
    flex-direction: column;
    min-height: 89vh;
}

.fade-out-canvas {
    animation: fade-out-canvas linear;
    animation-duration: 1s;
}

@keyframes fade-out-canvas {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.minWidthContainer {
    min-width: 1400px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: #000 !important;
        border: 1px solid rgb(190, 188, 188);
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgb(190, 188, 188) !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
}
.minWidthHistoryContainer {
    min-width: 1490px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: #000 !important;
        border: 1px solid rgb(190, 188, 188);
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgb(190, 188, 188) !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
}
