.warOverview {
  padding: 15px;
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .custom-height {
    height: 120px !important;
    width: 120px !important;
    --edge-size: 1rem;
    padding: 1em;
    // img {
    //   height: 100px;
    // }
  }
  .scoreScreen {
    h1 {
      font-size: 38px;
      font-family: var(--bigShoulders);
    }
    .time {
      font-size: 18px;
      font-family: var(--manrope);
    }
  }
  .headingSelectCharacter {
    padding: 40px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border: 2px solid;
    border-image: radial-gradient(circle at center, #e3ca6e5c, #856f1f00) 10;
    //   border-bottom: 2px solid #e3c96e;
    .headingText {
      font-family: var(--bigShoulders);
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      background: linear-gradient(180deg, #856f1f 0%, #e3c96e 100%);
      background-clip: text;
    }
    color: transparent;
    text-transform: uppercase;
  }
}
.flex-center {
  display: flex;
  align-items: center;
}
