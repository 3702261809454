.animation {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes war-event-blink {
  0% {
    box-shadow: none;
  }
  50% {
    box-shadow: -1px -1px 15px 3px red;
  }
  100% {
    box-shadow: none;
  }
}

.slideInFromRight {
  -webkit-animation-name: slideInFromRight;
  animation-name: slideInFromRight;
}
.slideInFromLeft {
  -webkit-animation-name: slideInFromRight;
  animation-name: slideInFromLeft;
}
.bounceFromLeft {
  -webkit-animation-name: bounceFromLeft;
  animation-name: bounceFromLeft;
}
.bounceFromRight {
  -webkit-animation-name: bounceFromRight;
  animation-name: bounceFromRight;
}

@keyframes slideInFromRight {
  0% {
    transform: translate3d(10%, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translate3d(-10%, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}

@keyframes bounceFromLeft {
  0%,
  60%,
  75%,
  90%,
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-500px, 0, 0);
    transform: translate3d(-500px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceFromRight {
  0%,
  60%,
  75%,
  90%,
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-500px, 0, 0);
    transform: translate3d(500px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInFromRight {
  -webkit-animation-name: fadeInFromRight;
  animation-name: fadeInFromRight;
}
.animateDelay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.animateDelay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.animateDelay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.animateDelay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.animateDelay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.animateDelay-6s {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}
.animateDelay-7s {
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
}
.animateDelay-8s {
  -webkit-animation-delay: 8s;
  animation-delay: 8s;
  -webkit-animation-delay: 8s;
  animation-delay: 8s;
}
.animateDelay-9s {
  -webkit-animation-delay: 9s;
  animation-delay: 9s;
  -webkit-animation-delay: 9s;
  animation-delay: 9s;
}
.animateDelay-10s {
  -webkit-animation-delay: 10s;
  animation-delay: 10s;
  -webkit-animation-delay: 10s;
  animation-delay: 10s;
}

@keyframes fadeInFromRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
