.primary-button {
  --background-color: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
  --border-color: linear-gradient(90deg, #856f1f 0%, #e3c96e 166.67%);
  --border-width: 0.1em;
  --edge-size: 0.5em;
  cursor: pointer;
  background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
  padding: 0.5em 1.5em;
  border: 0;
  color: #ffffff;
  text-transform: uppercase;
  font-family: var(--bigShoulders);
  position: relative;
  isolation: isolate;
  display: inline-grid;
  place-content: center;
  font-weight: 700;
  font-size: 20px;
  clip-path: polygon(
    0.5em 0%,
    calc(100% - 0.5em) 0%,
    100% 0.5em,
    100% calc(100% - 0.5em),
    calc(100% - 0.5em) 100%,
    0.5em 100%,
    0% calc(100% - 0.5em),
    0% 0.5em
  );
  transition: color 250ms;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(90deg, #856f1f 0%, #e3c96e 166.67%);
    z-index: -2;
  }
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
    z-index: -1;
    clip-path: polygon(
      0.5em 0.1em,
      calc(100% - 0.5em) 0.1em,
      calc(100% - 0.1em) 0.5em,
      calc(100% - 0.1em) calc(100% - 0.5em),
      calc(100% - 0.5em) calc(100% - 0.1em),
      0.5em calc(100% - 0.1em),
      0.1em calc(100% - 0.5em),
      0.1em 0.5em
    );
  }
  &:disabled {
    background: linear-gradient(90deg, #2d3a41 0%, #485c65d2 166.67%);
    color: #485c65;
    svg {
      color: #485c65;
    }
    &::before {
      background: #485c65;
    }
  }
}

.unSelected {
  filter: grayscale(100%);
}
