.postGameWrapper {
  width: 100%;
  height: 100%;
  min-width: 1400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 710px;
  justify-content: space-between;
  padding: 35px;
  //   min-height: 827px;
  h3 {
    color: #fff;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
  }

  @media screen and (max-width: 1075px) {
    overflow: auto;
  }
}
.pGWrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: radial-gradient(
    233.91% 100% at 50% 0%,
    rgba(10, 14, 16, 0) 0%,
    rgba(34, 114, 47, 0.2) 100%
  );

  @media screen and (max-width: 992px) {
    overflow: auto;
  }
}
.warOverviewWrapper {
  width: 100%;
  height: 100%;
  min-width: 1400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 650px;
  justify-content: space-between;
  padding: 35px;
  //   min-height: 827px;
}
.warOverviewWrapperMain {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: radial-gradient(
    233.91% 100% at 50% 0%,
    rgba(10, 14, 16, 0) 0%,
    rgba(34, 114, 47, 0.2) 100%
  );

  @media screen and (max-width: 1075px) {
    overflow: auto;
  }
}
.postGameHeading {
  h1 {
    color: #fff;
    font-family: "Big Shoulders Display";
    font-style: normal;
    font-weight: 900;
    font-size: 112px;
    line-height: 88px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
}
.teamAGBTableWeapper {
  > h3 {
    color: #00e1d4;
  }
  .postGameTable {
    width: 680px;
    .pgHeading {
      .pgName,
      .pgHealth,
      .pgKills,
      .pgDamageDealth,
      .pgHealed {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        color: #70878f;
        height: 48px;
      }
      .pgName {
        width: 40%;
      }
      .pgHealth {
        background: rgba(255, 255, 255, 0.03);
        width: 20%;
      }
      .pgKills {
        width: 10%;
      }
      .pgDamageDealth {
        width: 15%;
        background: rgba(255, 255, 255, 0.03);
      }
      .pgHealed {
        width: 15%;
      }
    }
    .teamAPGValueWrapper {
      background: rgba(10, 14, 16, 0.3);
      border: 1px solid rgba(0, 225, 212, 0.5);
      box-shadow: 0px 4px 24px rgba(0, 225, 212, 0.25);
    }
  }
}
.teamBGBTableWeapper {
  > h3 {
    color: #ff8d24;
  }
  .postGameTable {
    width: 680px;
    .pgHeading {
      .pgName,
      .pgHealth,
      .pgKills,
      .pgDamageDealth,
      .pgHealed {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        color: #70878f;
        height: 48px;
      }
      .pgName {
        width: 40%;
      }
      .pgHealth {
        background: rgba(255, 255, 255, 0.03);
        width: 20%;
      }
      .pgKills {
        width: 10%;
      }
      .pgDamageDealth {
        width: 15%;
        background: rgba(255, 255, 255, 0.03);
      }
      .pgHealed {
        width: 15%;
      }
    }
    .teamBPGValueWrapper {
      background: rgba(10, 14, 16, 0.3);
      border: 1px solid rgba(255, 141, 36, 0.5);
      box-shadow: 0px 4px 24px rgba(0, 225, 212, 0.25);
      .pgValues {
        border-bottom: 1px solid rgba(255, 142, 36, 0.153);
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        .pgNameValue,
        .pgHealthValue,
        .pgKillsValue,
        .pgDamageDealthValue,
        .pgHealedValue {
          height: 48px;
        }
        .pgNameValue {
          width: 40%;
        }
        .pgHealthValue {
          background: rgba(255, 255, 255, 0.03);
          width: 20%;
        }
        .pgKillsValue {
          width: 10%;
        }
        .pgDamageDealthValue {
          width: 15%;
          background: rgba(255, 255, 255, 0.03);
        }
        .pgHighestDamageDealthValue {
          background: linear-gradient(
              180deg,
              rgba(255, 240, 189, 0) 0%,
              rgba(10, 14, 16, 0) 0.01%,
              rgba(255, 198, 0, 0.7) 100%
            ),
            rgba(255, 255, 255, 0.03);
        }
        .pgHighestHealedValue {
          background: linear-gradient(
              180deg,
              rgba(255, 240, 189, 0) 0%,
              rgba(10, 14, 16, 0) 0.01%,
              #22722f 100%
            ),
            rgba(255, 255, 255, 0.03);
        }
        .pgHealedValue {
          width: 15%;
        }
      }
    }
  }
}
.pgValues {
  border-bottom: 1px solid rgba(0, 225, 214, 0.153);
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  .pgNameValue,
  .pgHealthValue,
  .pgKillsValue,
  .pgDamageDealthValue,
  .pgHealedValue {
    height: 48px;
  }
  .pgNameValue {
    width: 40%;
  }
  .pgHealthValue {
    background: rgba(255, 255, 255, 0.03);
    width: 20%;
  }
  .pgKillsValue {
    width: 10%;
  }
  .pgDamageDealthValue {
    width: 15%;
    background: rgba(255, 255, 255, 0.03);
  }
  .pgHighestDamageDealthValue {
    background: linear-gradient(
        180deg,
        rgba(255, 240, 189, 0) 0%,
        rgba(10, 14, 16, 0) 0.01%,
        rgba(255, 198, 0, 0.7) 100%
      ),
      rgba(255, 255, 255, 0.03);
  }
  .pgHighestHealedValue {
    background: linear-gradient(
        180deg,
        rgba(255, 240, 189, 0) 0%,
        rgba(10, 14, 16, 0) 0.01%,
        #22722f 100%
      ),
      rgba(255, 255, 255, 0.03);
  }
  .pgHealedValue {
    width: 15%;
  }
}
.pgDurationRound {
  width: 538px;
  height: 92px;
  background: rgba(255, 255, 255, 0.08);
  position: relative;
  .pgDuration {
    h1 {
      font-family: "Big Shoulders Display";
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      color: #ffffff;
    }
    p {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
  }
  .pgDuraRoundSep {
    width: 60px;
    rotate: 90deg;
    border-color: #485c65;
  }
  .pgRounds {
    h1 {
      font-family: "Big Shoulders Display";
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      color: #ffffff;
    }
    p {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
  }
  .pgDuraRoundLeftBox {
    position: absolute;
    width: 24px;
    height: 74px;
    background: #ffffff;
    opacity: 0.05;
    left: -24px;
  }
  .pgDuraRoundRightBox {
    position: absolute;
    width: 24px;
    height: 74px;
    background: #ffffff;
    opacity: 0.05;
    right: -24px;
  }
}
.pgMVPWrapper {
  .pgMVP {
    width: 278.38px;
    height: 76px;
    background: rgba(174, 150, 62, 0.12);
    border: 1px solid rgba(227, 201, 110, 0.3);
    .pgMVPImage {
      width: 40.38px;
      height: 52px;
      border: 1px solid #9aa8ac;
    }
    h1 {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      span {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: #ffffff;
      }
    }
    p {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
    }
  }
  .pgMVPSep {
    width: 237.81px;
    border-color: rgba(227, 201, 110, 0.6);
  }
}

.opHeadingText {
  font-family: "Big Shoulders Display";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  background: linear-gradient(180deg, #856f1f 0%, #e3c96e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
