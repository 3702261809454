.loading {
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.573), rgba(0, 0, 0, 0.573)),
    url(../../../images/backgrounds/warscreenBg.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  position: absolute;
  top: 0;
  backdrop-filter: blur(60px);
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1000px) {
    width: 100%;
    overflow: auto;
  }
}
.preparingWarText {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  opacity: 0.8;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
.headingTextTeamSeparation {
  font-family: var(--bigShoulders);
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 48px;
  background: linear-gradient(180deg, #856f1f 0%, #e3c96e 100%);
  background-clip: text;
  color: transparent;
}
