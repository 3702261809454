.gangwars-tab-content {
    .gangwars-table-wrapper {
        .wars-table {
            .objective-block {
                min-width: 300px;
                max-width: 20%;
            }

            .zone-type-block {
                max-width: 12%;
                min-width: 147px;
                text-transform: capitalize;
            }

            .entry-fee-block {
                width: 7%;
                min-width: 110px;
                text-transform: capitalize;
            }

            .rewards-block {
                // width: 21%;
                min-width: 130px;
            }

            .war-status-block {
                max-width: 12%;
                min-width: 148px;
            }

            .war-items {
                .war-item {
                    min-height: 104px;
                    > td {
                        padding-bottom: 23px;
                    }

                    &.watch-live-bg {
                        border-bottom: 1px solid #882c2c;
                        background: linear-gradient(
                            180deg,
                            rgba(10, 14, 16, 0) 27.08%,
                            rgba(136, 44, 44, 0.5) 100%
                        );
                    }

                    &.map-clicked-bg {
                        border-bottom: 1px solid #c0c0c0;
                        background: linear-gradient(
                            180deg,
                            rgba(10, 14, 16, 0) 27.08%,
                            #c0c0c083 100%
                        );
                        animation: blink 1s linear infinite;
                    }

                    &.now-playing-bg {
                        border-bottom: 1px solid var(--yellow-color);
                        background: linear-gradient(
                            180deg,
                            rgba(10, 14, 16, 0) 0%,
                            rgba(255, 198, 0, 0.2) 100%
                        );
                    }
                }

                .circle {
                    margin-right: 8px;
                    width: 12px;
                    height: 12px;
                    background-color: #ff5353;
                    display: inline-block;
                }
                .start-time-block {
                    min-width: 200px;
                    .game-start-time {
                        font-family: normal var(--proximaNova);
                        color: #70878f;
                        font-weight: 100;
                        font-size: 12px;
                        // margin-left: -6px;
                    }
                    .game-start-time-counter {
                        font: var(--font-14);
                        margin-left: -6px;
                        gap: 4px;

                        .time {
                            font-weight: 700;
                        }
                        .react-countdown-simple {
                            color: white !important;
                            font-size: 20px;
                            padding: 0px !important;
                        }
                        .react-countdown-simple__date-time {
                            font-weight: 700;
                            font-size: 20px;

                            span {
                                min-width: 10px !important;
                            }
                        }
                        .react-countdown-simple__colon {
                            padding: 0px 3px;
                        }
                    }
                }

                .objective-block {
                    .obj-name {
                        text-transform: capitalize;
                    }

                    .obj-description {
                        font: var(--font-14);
                    }
                }

                .entry-fee-block {
                    font: normal 700 20px/24px var(--proximaNova);
                    color: #fff;
                    .crown-logo {
                        width: 20px;
                    }
                }

                .war-status-wrapper {
                    width: 12%;
                    min-width: 148px;
                    font: normal 400 12px/16px var(--proximaNova);
                    gap: 0.4rem;

                    .primary-button {
                        // margin: 0.28rem 0 0;
                        margin: 0;
                        font: var(--font-14);
                        font-weight: 700;
                        width: 150px;
                    }

                    .join-disabled {
                        .primary-button {
                            cursor: no-drop;
                            color: var(--grey-color);

                            &::before {
                                background: var(--grey-color);
                            }
                        }
                    }
                }
                .war-action-btn {
                    text-align: center;
                }
                .visit-lobby-btn {
                    &:before {
                        background: linear-gradient(90deg, #fffae8 0%, #d1cec6 166.67%);
                    }
                    font-size: 16px;
                    margin-top: 0px;
                }
            }
        }
    }
}

@keyframes blink {
    0% {
        background: transparent;
    }
    50% {
        background-color: transparent;
    }
    100% {
        background: linear-gradient(180deg, rgba(10, 14, 16, 0) 27.08%, #c0c0c083 100%);
    }
}

.war-list-start-time-block {
    width: 200px;
}
.war-list-entry-fee-block {
    width: 100px;
}
.war-list-rewards-block {
    width: 200px;
}
.war-list-duration-block {
    width: 80px;
}
.war-list-objective-block {
    width: 200px;
}
.war-list-zone-type-block {
    width: 250px;
}

.start-time-block-list {
    width: 200px;
    .playerCount {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #70878f;
    }
}
.entry-fee-block-list {
    width: 100px;
}
.rewards-block-list {
    width: 200px;
}

.duration-block-list {
    width: 80px;
}
.objective-block-list {
    width: 200px;
    .obj-description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #70878f;
    }
}
.zone-type-block-list {
    width: 250px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #70878f;
}

.game-start-time-counter {
    .react-countdown-simple {
        color: #70878f !important;
        font-size: 14px;
        font-weight: 700;
        padding: 0px !important;
        width: 85px !important;
    }
    .react-countdown-simple__date-time {
        font-weight: 700;
        font-size: 14px;

        span {
            min-width: 10px !important;
        }
    }
    .react-countdown-simple__colon {
        padding: 0px 3px;
    }
}
.separator {
    border-left: 2px solid #70878f;
    height: 15px;
    margin: 0 10px;
    padding: 0;
}
.joined-text {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #e3c96e;
}

.free-entry {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.war-list-join-button {
    width: 130px;
}
.war-join-btn-list {
    width: 130px;
}
