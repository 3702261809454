.playerLimitWrapper {
  font-family: "Proxima Nova";
  padding: 7px;
  display: flex;
  flex-direction: column;
  //   gap: 5px;
  align-items: center;
  .playerNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: 400;
    font-size: 18px;
  }
  .playerReachedText {
    width: 85%;
    margin: auto;
    .playerReachedTopText {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      padding: 7px;
      text-align: center;

      color: #ffffff;
    }
    .playerReachedInfo {
      font-style: normal;
      font-weight: 400;
      padding: 7px;
      font-size: 15px;
      line-height: 24px;

      text-align: center;

      color: #ffffff;
    }
  }
}
