@mixin emDash{
    content:"\2014\2014\2014\2014";
    letter-spacing: -0.25em;
    text-align: center;
    white-space: nowrap;
    
}

.loading-container{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #000 url("../../../images/backgrounds/loadingScreenBg.svg") no-repeat fixed;
    background-size: cover;
    

    .loading-content-wrapper{
        height: inherit;
        padding: 0 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: radial-gradient(50% 71.49% at 50% 50%, rgba(38, 52, 61, 0.8) 0%, rgba(10, 14, 16, 0.5) 100%);
        mix-blend-mode: normal;
        backdrop-filter: blur(6px);

        .loading-content{
            display: inherit;
            flex-direction: inherit;
            align-items: center;
            gap:49px;
        }
        .nft-items{
            display: flex;
            flex-direction: row;
            gap: 24px;

            .nftWrapper{
                height: 30vh;
                width: auto;;
            }

            .nft-name{
                text-align: center;
                padding: 8px;
                font: normal 600 12px/16px 'Proxima Nova';
                color: #fff;
            }
        }

        .progress-counter{
            width: 100%;
            font: italic 700 28px/36px 'Proxima Nova';
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            padding: 8px 0;
            height: 55px;
            background: radial-gradient(50% 46044.45% at 50% 50%, #26343D 0%, rgba(38, 52, 61, 0) 100%);
            border: 2px solid;
            border-image: radial-gradient(circle at center, #E3C96E, #856F1F00) 2
        }

        .quick-tip {
            position: absolute;
            bottom: 2.5vh;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            text-align: center;
            font: normal 400 16px/24px 'Proxima Nova';
            color: #70878F;

            &::before{
                @include emDash;
                padding-right: 15px;
            }

            &::after{
                @include emDash;
                padding-left: 15px;
            }

        }
    }
    
}
