.filter-item {
    color: var(--color-blue-50);
    font: normal 400 14px/20px  'Proxima Nova';
    cursor: pointer;
    padding: 8px 24px 8px 32px;
    text-transform: capitalize;

    &:hover {
        background-color: rgba(38, 38, 38, 1);
    }

    .radio-input {
        display: none;

        &:checked {
            + span {
                border: 1px solid #E3C96E;
                border-radius: 50%;
                transform: rotate(90deg);

                &::after {
                    opacity: 1;
                }
            }
        }
    }

    .custom-radio {
        cursor: pointer;
        width: 20px;
        height: 20px;
        border: 1px solid rgba(115, 115, 115, 1);
        border-radius: 50%;
        display: inline-block;
        position: relative;

        &::after {
            content: "";
            width: 12px;
            height: 12px;
            background: #E3C96E;
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity 200;
        }
    }
    .checkbox-input {
        display: none;

        &:checked {
            + .custom-checkbox {
                border: 1px solid #E3C96E;
                &::after {
                    content: "";
                    background-color: #E3C96E;
                    width: 12px;
                    height: 9px;
                    clip-path: polygon(
                        15.08% 50.4%,
                        6.49% 62.82%,
                        46.47% 90.48%,
                        96.94% 17.52%,
                        84.71% 9.06%,
                        42.83% 69.6%
                    );
                }
            }
        }
    }

    .custom-checkbox {
        cursor: pointer;
        width: 20px;
        height: 20px;
        border: 1px solid #70878F;
        display: grid;
        place-content: center;
    }

    .filter-item-info-wrapper {
        .filter-item-title {
            color: white;
            font: normal 400 14px/20px 'Proxima Nova';
            cursor: pointer;
            // padding: 8px;
            text-transform: capitalize;
        }

        .filter-item-sublist {
            list-style: disc;
            padding-left: 18px;
            color: #70878F;
            font: normal 400 12px/16px  'Proxima Nova';
            letter-spacing: 0.24px;
            text-transform: capitalize;
        }
    }
}
