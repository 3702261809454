// .equipmentPlainWrapper {
//   width: 128px;
//   height: 128px;
//   .equipmentPlainComponent {
//     font-family: var(--bigShoulders);
//     overflow: visible;
//     width: inherit;
//     height: inherit;
//     position: relative;
//     isolation: isolate;
//     clip-path: polygon(
//       1.5em 0%,
//       calc(100% - 1.5em) 0%,
//       100% 1.5em,
//       100% calc(100% - 1.5em),
//       calc(100% - 1.5em) 100%,
//       1.5em 100%,
//       0% calc(100% - 1.5em),
//       0% 1.5em
//     );
//     &::before {
//       content: "";
//       position: absolute;
//       inset: 0;
//       background: #e3c96e;
//       z-index: -2;
//     }
//     &::after {
//       content: "";
//       position: absolute;
//       inset: 0;
//       background: linear-gradient(
//         180deg,
//         rgba(2, 13, 20, 0.82) 0%,
//         rgba(2, 13, 20, 0.8) 100%
//       );
//       background-size: cover;
//       background-repeat: no-repeat;
//       z-index: -1;
//       clip-path: polygon(
//         1.5em 0.15em,
//         calc(100% - 1.5em) 0.15em,
//         calc(100% - 0.15em) 1.5em,
//         calc(100% - 0.15em) calc(100% - 1.5em),
//         calc(100% - 1.5em) calc(100% - 0.15em),
//         1.5em calc(100% - 0.15em),
//         0.15em calc(100% - 1.5em),
//         0.15em 1.5em
//       );
//     }
//     padding: 20px;
//   }
// }

.equipmentPlainWrapper {
  width: 60px;
  height: 128px;
  // max-width: 60px;
  // max-height: 64px;
  // min-width: 55px;
  // min-height: 56px;
  position: relative;
  overflow: visible;
  .equipmentPlainComponent,
  .setGrey,
  .setColor {
    min-width: 55px;
    min-height: 56px;
    font-family: var(--bigShoulders);
    overflow: visible;
    width: inherit;
    // min-width: 62px;
    // height: inherit;
    position: relative;
    isolation: isolate;
    clip-path: polygon(
      0.5em 0%,
      calc(100% - 0.5em) 0%,
      100% 0.5em,
      100% calc(100% - 0.5em),
      calc(100% - 0.5em) 100%,
      0.5em 100%,
      0% calc(100% - 0.5em),
      0% 0.5em
    );
    padding: 20px;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: -2;
      background-color: grey;
    }
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(
        180deg,
        rgba(2, 13, 20, 0.82) 0%,
        rgba(2, 13, 20, 0.8) 100%
      );
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;
      clip-path: polygon(
        0.5em 0.15em,
        calc(100% - 0.5em) 0.15em,
        calc(100% - 0.15em) 0.5em,
        calc(100% - 0.15em) calc(100% - 0.5em),
        calc(100% - 0.5em) calc(100% - 0.15em),
        0.5em calc(100% - 0.15em),
        0.15em calc(100% - 0.5em),
        0.15em 0.5em
      );
    }
    img {
      width: 100%;
    }
  }
  .setGrey {
    &::before {
      content: "";
      background: grey;
      z-index: -2;
    }
  }
  .setNone {
    &::before {
      content: "";
      background: transparent;
      z-index: -2;
    }
  }
  .setColor {
    &::before {
      content: "";
      background: #e3c96e;
      box-shadow: 1px 2px 2px #e3c96e;
      z-index: -2;
    }
  }
  .equipment {
    // max-height: 45px;
    // min-height: 35px;
    // min-width: 45px;
    // max-width: 60px;
    .equ-image {
      // width: 100%;
      // height: 100%;
      // display: block;
      // margin: auto;
    }
    .equipmentNumber {
      font-family: proxima-nova, sans-serif;
      position: absolute;
      font-weight: 700;
      font-size: 12px;
      line-height: 24px;
      right: 6px;
      bottom: 0px;
      z-index: 1;
      color: #ffffff;
    }
  }
  .active-tick {
    position: absolute;
    right: 35%;
    top: -6px;
    z-index: 99999;
  }
}

.equipItemLeftPart .equipmentPlainComponent,
.equipItemRightPart .equipmentPlainComponent,
.equipItemMiddlePart .equipmentPlainComponent,
.equipmentPlainComponent {
  padding: 12px !important;
  // max-height: 60px;
  // min-height: 60px;
  // min-width: 60px;
  // min-height: 60px;
}
