@import "./font.style";
@import "./mixins.style";
@import "./variables.style";
@import "./tooltip.style";
@import "./animation.style";
@import "./misc.style";
img {
  @include disable-selection;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}
.skeleton-default-width {
  width: 100%;
  height: 100%;
}
.skeletionCustomClassLogs {
  // min-width: 55px;
  // min-height: 70px;
  // max-height: 70px;
  // max-width: 55px;
  // border: 1px solid #00e1d4;
}

@keyframes skeleton-loading {
  0% {
    background-color: #4b4b4b;
  }
  100% {
    background-color: #242424;
  }
}
.spin {
  perspective: 500px;
  > div {
    transform: rotateY(45deg) scaleX(-1);
    animation: spin-animation 950ms linear infinite;
  }
}
@keyframes spin-animation {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}