.lootClaimAnimationWrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgb(13, 12, 12);
    z-index: 99;
    top: 0;
    left: 0;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    .lootClaimMain {
        position: relative;
        .closeLoot {
            position: absolute;
            color: white;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }
        .lootClaimOverlay {
            //   position: absolute;
            //   top: 50%;
            //   left: 50%;
            //   transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 95vh;
            overflow-y: auto;
            .lootClaimContainer {
                // height: 95vh;
                color: white;
                display: flex;
                // justify-content: center;
                flex-direction: column;
                align-items: center;
                gap: 30px;
                width: 100vw;
                overflow: auto;
                // position: absolute;
                .claimInfo {
                    display: flex;
                    flex-direction: column;
                    .claimHeadingText {
                        font-family: "Big Shoulders Display";
                        font-style: normal;
                        font-weight: 700;
                        font-size: 56px;
                        line-height: 64px;
                        text-align: center;
                        color: #ffffff;
                        margin-top: 35px;
                    }
                    .claimParagraphText {
                        font-family: "Proxima Nova";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;
                        text-align: center;
                        color: #ffffff;
                        max-width: 500px;
                    }
                }
            }
        }
    }
}

.claimCardWrapper {
    padding: 16px 20px 24px;
    // height: 248px;
    background: rgba(255, 255, 255, 0.14);
    display: flex;
    flex-direction: column;
    gap: 5px;
    .cardTitle {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
    }
    .claimCards {
        .claimCard {
            cursor: pointer;
            width: 124px;
            height: 176px;
            background: rgba(10, 14, 16, 0.98);
            border: 1px solid #485c65;
            position: relative;
            .claimCardImg {
                min-width: 92px;
                min-height: 92px;
                position: relative;
                background-color: #201d1db5;
                margin: 10px;
                padding: 5px;
                clip-path: polygon(
                    0.6em 0%,
                    calc(100% - 0.6em) 0%,
                    100% 0.6em,
                    100% calc(100% - 0.6em),
                    calc(100% - 0.6em) 100%,
                    0.6em 100%,
                    0% calc(100% - 0.6em),
                    0% 0.6em
                );
                .countClaim {
                    position: absolute;
                    color: white;
                    //   background: linear-gradient(
                    //     180deg,
                    //     rgba(2, 13, 20, 0) 0%,
                    //     rgba(2, 13, 20, 0.7) 99.99%,
                    //     rgba(2, 13, 20, 0.8) 100%
                    //   );
                    font-size: large;
                    font-size: 14px;
                    // left: 73px;
                    right: 5px;
                    width: inherit;
                    top: 77px;
                    //   width: 100%;
                    overflow: hidden;
                }
            }
            .lootName {
                font-family: "Proxima Nova";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #ffffff;
                padding: 2px 10px;
            }
        }
    }
}
