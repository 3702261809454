.primary-icon-button {
  --background-color: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
  --border-color: linear-gradient(90deg, #856f1f 0%, #e3c96e 166.67%);
  --border-width: 0.1em;
  --edge-size: 0.5em;
  cursor: pointer;
  background: var(--background-color);
  padding: 0.5em;
  border: 0;
  color: #ffffff;
  text-transform: uppercase;
  font-family: var(--bigShoulders);
  position: relative;
  isolation: isolate;
  display: inline-grid;
  place-content: center;
  font-weight: 700;
  font-size: 20px;
  clip-path: polygon(
    var(--edge-size) 0%,
    calc(100% - var(--edge-size)) 0%,
    100% var(--edge-size),
    100% calc(100% - var(--edge-size)),
    calc(100% - var(--edge-size)) 100%,
    var(--edge-size) 100%,
    0% calc(100% - var(--edge-size)),
    0% var(--edge-size)
  );
  transition: color 250ms;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--border-color);
    z-index: -2;
  }
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--background-color);
    z-index: -1;
    clip-path: polygon(
      var(--edge-size) calc(var(--border-width)),
      calc((100% - var(--edge-size))) calc(var(--border-width)),
      calc(100% - var(--border-width)) var(--edge-size),
      calc(100% - var(--border-width)) calc(100% - var(--edge-size)),
      calc(100% - var(--edge-size)) calc(100% - var(--border-width)),
      var(--edge-size) calc(100% - var(--border-width)),
      calc(var(--border-width)) calc(100% - var(--edge-size)),
      calc(var(--border-width)) var(--edge-size)
    );
  }
}

.unSelected {
  filter: grayscale(100%);
}
