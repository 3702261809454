// .nftWrapper {
//     width: 190px;
//     height: 300px;
//     isolation: isolate;
//     position: relative;
//     .unSelectedNFTOuter {
//         background: red;
//         &::before {
//             content: "";
//             background: grey;
//         }
//         .unSelectedNFTInner {
//             &::before {
//                 content: "";
//                 background: grey;
//                 z-index: -2;
//             }
//         }
//     }
//     .selectedNFTOuter {
//         &::before {
//             content: "";
//             background: #e3c96e;
//         }
//         .selectedNFTInner {
//             &::before {
//                 content: "";
//                 background: #e3c96e;
//                 z-index: -2;
//             }
//         }
//     }
//     .nftOuterWrapper,
//     .unSelectedNFTOuter,
//     .selectedNFTOuter {
//         overflow: visible;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         width: inherit;
//         height: inherit;
//         background: rgba(10, 14, 16, 0.98);
//         position: relative;
//         isolation: isolate;
//         clip-path: polygon(
//             0% 0%,
//             calc(100% - 10%) 0%,
//             100% 10%,
//             100% 100%,
//             calc(100% - 10%) 100%,
//             10% 100%,
//             0% calc(100% - 10%),
//             0% 10%
//         );
//         &::before {
//             content: "";
//             position: absolute;
//             inset: 0;
//             // background: #e3c96e;
//             z-index: -2;
//         }
//         &::after {
//             content: "";
//             position: absolute;
//             inset: 0;
//             background: rgba(10, 14, 16, 0.98);
//             background-size: cover;
//             background-repeat: no-repeat;
//             z-index: -1;
//             clip-path: polygon(
//                 0.1em 0.1em,
//                 calc(100% - 10%) 0.1em,
//                 calc(100% - 0.1em) 10%,
//                 calc(100% - 0.1em) calc(100% - 0.1em),
//                 calc(100% - 10%) calc(100% - 0.1em),
//                 10% calc(100% - 0.1em),
//                 0.1em calc(100% - 10%),
//                 0.1em 10%
//             );
//         }
//         .flip {
//             transform: scaleX(-1);
//         }

//         .nftInnerWrapper,
//         .unSelectedNFTInner,
//         .selectedNFTInner {
//             .isNFTDamaged-lg {
//                 position: absolute;
//                 width: 100%;
//                 display: flex;
//                 flex-direction: column;
//                 justify-content: space-between;
//                 justify-content: center;
//                 // align-items: center;
//                 .damageName {
//                     text-transform: uppercase;
//                     font-family: var(--bigShoulders);
//                     color: #ff5353;
//                     background: #26343d7a;
//                     text-align: center;
//                     padding: 10px 0px;
//                     font-weight: 700;

//                     // border-left: 1px solid grey;
//                     // border-right: 1px solid grey;
//                     font-size: 35px;
//                 }
//                 .damageRemainingTime {
//                     // align-self: center;
//                     background: #26343d7a;
//                     text-align: center;
//                     position: absolute;
//                     top: 100%;
//                     padding: 10px 0px;
//                     width: 100%;
//                     font-weight: 500;
//                     // top: 30px;
//                     .react-countdown-simple {
//                         color: #f5f5f1 !important;
//                         padding: 0px;
//                         // font-family: var(--bigShoulders) !important;
//                         font-size: 14px;
//                     }
//                     .react-countdown-simple__date-time {
//                         font-weight: 700;
//                         font-size: 14px;
//                         color: white;
//                         text-transform: lowercase;
//                         span {
//                             min-width: 10px !important;
//                         }
//                     }
//                     .react-countdown-simple__colon {
//                         padding: 0px 3px;
//                     }
//                 }
//             }
//             .isNFTDamaged-sm {
//                 position: absolute;
//                 width: 100%;
//                 display: flex;
//                 top: 40%;
//                 flex-direction: column;
//                 justify-content: center;
//                 background: #26343d7a;

//                 .damageName {
//                     text-transform: uppercase;
//                     font-family: var(--bigShoulders);
//                     color: #ff5353;
//                     text-align: center;
//                     font-weight: 700;
//                     width: 100%;
//                     font-size: 15px;
//                 }
//                 .damageRemainingTime {
//                     text-align: center;
//                     width: 100%;
//                     font-weight: 500;
//                     color: white !important;
//                     font-size: 10px;
//                     .react-countdown-simple {
//                         color: #f5f5f1 !important;
//                         padding: 0px;
//                         // font-family: var(--bigShoulders) !important;
//                         font-size: 10px;
//                     }
//                     .react-countdown-simple__date-time {
//                         font-weight: 700;
//                         font-size: 10px;
//                         color: white;
//                         text-transform: lowercase;
//                         span {
//                             min-width: 10px !important;
//                         }
//                     }
//                     .react-countdown-simple__colon {
//                         padding: 0px 3px;
//                     }
//                 }
//             }
//             .isNFTonRent-lg {
//                 @extend .isNFTDamaged-lg;

//                 .damageName {
//                     color: #fb9d51;
//                     font-size: 24px;
//                 }
//                 .damageRemainingTime {
//                     display: none;
//                 }
//             }
//             .isNFTinRent-sm {
//                 @extend .isNFTDamaged-sm;

//                 .damageName {
//                     color: #fb9d51;
//                     font-size: 20px;
//                 }
//             }
//             .isNFTRented-sm {
//                 @extend .isNFTDamaged-sm;

//                 .damageName {
//                     color: #6ee381;
//                 }
//             }
//             .isNFTDamaged {
//                 position: absolute;
//                 width: 100%;
//                 display: flex;
//                 flex-direction: column;
//                 justify-content: space-between;
//                 justify-content: center;
//                 // align-items: center;
//                 .damageName {
//                     text-transform: uppercase;
//                     font-family: var(--bigShoulders);
//                     color: #ff5353;
//                     background: #26343d7a;
//                     text-align: center;
//                     padding: 10px 0px;
//                     font-weight: 700;

//                     // border-left: 1px solid grey;
//                     // border-right: 1px solid grey;
//                     font-size: 35px;
//                 }
//                 .damageRemainingTime {
//                     // align-self: center;
//                     background: #26343d7a;
//                     text-align: center;
//                     position: absolute;
//                     top: 100%;
//                     padding: 10px 0px;
//                     width: 100%;
//                     font-weight: 500;
//                     // top: 30px;
//                 }
//             }
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             overflow: visible;
//             width: calc(100% - 10px);
//             height: calc(100% - 10px);
//             background: url("../../../images/backgrounds/modalBg.svg"), black;
//             position: relative;
//             isolation: isolate;
//             clip-path: polygon(
//                 0% 0%,
//                 calc(100% - 10%) 0%,
//                 100% 10%,
//                 100% 100%,
//                 calc(100% - 10%) 100%,
//                 10% 100%,
//                 0% calc(100% - 10%),
//                 0% 10%
//             );
//             &::before {
//                 content: "";
//                 position: absolute;
//                 inset: 0;
//                 // background: #e3c96e;
//                 z-index: -2;
//             }
//             &::after {
//                 content: "";
//                 position: absolute;
//                 inset: 0;
//                 background: url("../../../images/backgrounds/modalBg.svg"), rgba(0, 0, 0, 0.836);
//                 background-size: cover;
//                 background-repeat: no-repeat;
//                 z-index: -1;
//                 clip-path: polygon(
//                     0.2em 0.15em,
//                     calc(100% - 10%) 0.15em,
//                     calc(100% - 0.15em) 10%,
//                     calc(100% - 0.15em) calc(100% - 0.2em),
//                     calc(100% - 10%) calc(100% - 0.15em),
//                     10% calc(100% - 0.15em),
//                     0.15em calc(100% - 10%),
//                     0.15em 10%
//                 );
//             }
//             .nftCardImage {
//                 width: calc(100% - 2px);
//                 // z-index: 1;
//                 position: relative;
//                 height: calc(100% - 2px);
//                 clip-path: polygon(
//                     0% 0%,
//                     calc(100% - 10%) 0%,
//                     100% 10%,
//                     100% 100%,
//                     calc(100% - 10%) 100%,
//                     10% 100%,
//                     0% calc(100% - 10%),
//                     0% 10%
//                 );
//             }
//             .nftCardDeadImage {
//                 width: calc(100%);
//                 height: calc(100%);
//                 position: absolute;
//                 z-index: 2;
//                 background-color: #282928;
//                 opacity: 0.9;
//                 padding: 15px;
//             }
//         }
//     }
//     .unSelectedTriangleOne,
//     .unSelectedTriangleTwo {
//         border: 2px solid grey;
//     }
//     .selectedTriangleTwo,
//     .selectedTriangleOne {
//         border: 2px solid #e3c96e;
//     }
//     .selectedTriangleOne,
//     .unSelectedTriangleOne {
//         position: absolute;
//         height: 18px;
//         width: 18px;
//         z-index: -1;
//         left: 2%;
//         bottom: 2%;
//     }
//     .selectedTriangleTwo,
//     .unSelectedTriangleTwo {
//         position: absolute;
//         height: 18px;
//         width: 18px;
//         z-index: -1;
//         right: 2%;
//         top: 2%;
//     }
// }

.nftDefaultCardBorder {
    position: relative;
    z-index: 66;
    border-image-slice: 27 27 27 27;
    border-image-width: 20px 20px 20px 20px;
    border-image-outset: 0px 0px 0px 0px;
    border-image-repeat: stretch stretch;
    border-image-source: url("../../../images/extra/iconGbBorderLight.png");
}
.nftBorderHistoryAndBattle {
    border-image-slice: 20 20 20 20;
    border-image-width: 20px 20px 20px 20px;
    border-image-outset: 0px 0px 0px 0px;
    border-image-repeat: stretch stretch;
    border-image-source: url("../../../images/extra/battleBorder.png");
}
.nftActiveCardBorder {
    border-image-slice: 27 27 27 27;
    border-image-width: 20px 20px 20px 20px;
    border-image-outset: 0px 0px 0px 0px;
    border-image-repeat: stretch stretch;
    border-image-source: url("../../../images/extra/iconGbBorder.png");
}
.nftCardImageCollection {
    min-width: 140px;
    min-height: 181px;
    padding: 6px;
    clip-path: polygon(
        0 0,
        calc(100% - 1.5em) 0,
        100% 1.5em,
        100% 70%,
        100% 100%,
        17% 100%,
        0 calc(100% - 1.5em),
        0% 1.5em
    );
}
.nftCardImageChooseCharacter {
    min-width: 110px;
    min-height: 142px;
    padding: 6px;
    clip-path: polygon(
        0 0,
        calc(100% - 1.7em) 0,
        100% 1.7em,
        100% 70%,
        100% 100%,
        1.7em 100%,
        0 calc(100% - 1.7em),
        0% 1.7em
    );
}
.nftCardImageTeamFormation {
    padding: 6px;
    clip-path: polygon(
        0 0,
        calc(100% - 1.5em) 0,
        100% 1.5em,
        100% 70%,
        100% 100%,
        1.5em 100%,
        0 calc(100% - 1.5em),
        0% 1.5em
    );
}
.nftCardImageHistoryAndBattle {
    padding: 3px;
    clip-path: polygon(
        0 0,
        calc(100% - 0.6em) 0,
        100% 0.6em,
        100% 70%,
        100% 100%,
        0.5em 100%,
        0 calc(100% - 0.5em),
        0% 0.5em
    );
    width: 100%;
}
.plainNftCardImage {
    clip-path: none;
}

.nftCardImage {
    z-index: 1;
    position: relative;
    overflow: hidden;
    height: calc(100% - 2px);
}

.isNFTDamaged-sm {
    position: absolute;
    width: 100%;
    display: flex;
    top: 37%;
    flex-direction: column;
    justify-content: center;
    background: #26343d7a;
    z-index: 1;

    .damageName {
        text-transform: uppercase;
        font-family: var(--bigShoulders);
        color: #ff5353;
        text-align: center;
        font-weight: 700;
        width: 100%;
        font-size: 15px;
    }
    .damageRemainingTime {
        text-align: center;
        width: 100%;
        font-weight: 500;
        color: white !important;
        font-size: 10px;
        .react-countdown-simple {
            color: #f5f5f1 !important;
            padding: 0px;
            // font-family: var(--bigShoulders) !important;
            font-size: 10px;
        }
        .react-countdown-simple__date-time {
            font-weight: 700;
            font-size: 10px;
            color: white;
            text-transform: lowercase;
            span {
                min-width: 10px !important;
            }
        }
        .react-countdown-simple__colon {
            padding: 0px 3px;
        }
    }
}

.nftCardDeadImage {
    width: calc(100%);
    height: calc(100%);
    position: absolute;
    z-index: 2;
    background-color: #282928;
    opacity: 0.5;
    padding: 10px;
    top: 0;
}

.isNFTDamaged {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: center;
    // align-items: center;
    .damageName {
        text-transform: uppercase;
        font-family: var(--bigShoulders);
        color: #ff5353;
        background: #26343d7a;
        text-align: center;
        padding: 10px 0px;
        font-weight: 700;

        // border-left: 1px solid grey;
        // border-right: 1px solid grey;
        font-size: 35px;
    }
    .damageRemainingTime {
        // align-self: center;
        background: #26343d7a;
        text-align: center;
        position: absolute;
        top: 100%;
        padding: 10px 0px;
        width: 100%;
        font-weight: 500;
        // top: 30px;
    }
}

.damageName {
    text-transform: uppercase;
    font-family: var(--bigShoulders);
    color: #ff5353;
    // background: #26343d7a;
    text-align: center;
    padding: 5px 0px;
    font-weight: 700;

    // border-left: 1px solid grey;
    // border-right: 1px solid grey;
    font-size: 35px;
}
.isNFTDamaged-lg {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: center;
    bottom: 0;
    z-index: 1;
    // align-items: center;
    .damageName {
        text-transform: uppercase;
        font-family: var(--bigShoulders);
        color: #ff5353;
        background: #26343d7a;
        text-align: center;
        padding: 10px 0px;
        font-weight: 700;

        // border-left: 1px solid grey;
        // border-right: 1px solid grey;
        font-size: 35px;
    }
    .damageRemainingTime {
        // align-self: center;
        background: #26343d7a;
        text-align: center;
        position: absolute;
        top: 100%;
        padding: 10px 0px;
        width: 100%;
        font-weight: 500;
        // top: 30px;
        .react-countdown-simple {
            color: #f5f5f1 !important;
            padding: 0px;
            // font-family: var(--bigShoulders) !important;
            font-size: 14px;
        }
        .react-countdown-simple__date-time {
            font-weight: 700;
            font-size: 14px;
            color: white;
            text-transform: lowercase;
            span {
                min-width: 10px !important;
            }
        }
        .react-countdown-simple__colon {
            padding: 0px 3px;
        }
    }
}

//
.isNFTonRent-lg {
    @extend .isNFTDamaged-lg;
    bottom: -43%;
    .damageName {
        color: #fb9d51;
        font-size: 24px;
    }
    .damageRemainingTime {
        display: none;
    }
}
.isNFTinRent-sm {
    @extend .isNFTDamaged-sm;
    top: 0px;
    bottom: -20% !important;

    .damageName {
        color: #fb9d51;
        font-size: 20px;
    }
}
.isNFTRented-sm {
    @extend .isNFTDamaged-sm;

    .damageName {
        color: #6ee381;
    }
}
.isNFTDamaged {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: center;
    bottom: 0;
    z-index: 1;
    .damageName {
        text-transform: uppercase;
        font-family: var(--bigShoulders);
        color: #ff5353;
        background: #26343d7a;
        text-align: center;
        padding: 10px 0px;
        font-weight: 700;

        // border-left: 1px solid grey;
        // border-right: 1px solid grey;
        font-size: 35px;
    }
    .damageRemainingTime {
        // align-self: center;
        background: #26343d7a;
        text-align: center;
        position: absolute;
        top: 100%;
        padding: 10px 0px;
        width: 100%;
        font-weight: 500;
        // top: 30px;
    }
}

// style changed for rental and hospitalized

.NFTIsolation {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: center;
    z-index: 1;
    isolation: isolate;
}
.NFTOnRent {
    bottom: 0;
}

// rental
.NFTOnRentLg {
    bottom: 0px;
    background-color: #28292885;
    backdrop-filter: blur(0.5px);
    height: 85px;
    .rentalTextColor {
        text-transform: uppercase;
        font-family: var(--bigShoulders);
        color: #ff5353;
        text-align: center;
        padding: 5px 0px;
        font-weight: 700;
        font-size: 35px;
    }
    .rentalRemainingTime {
        margin-top: -10px;
        text-align: center;
        width: 100%;
        font-weight: 500;
        color: white !important;
        font-size: 14px;
        .react-countdown-simple {
            color: #f5f5f1 !important;
            padding: 0px;
            font-size: 14px;
        }
        .react-countdown-simple__date-time {
            font-weight: 700;
            font-size: 14px;
            color: white;
            text-transform: lowercase;
            span {
                min-width: 10px !important;
            }
        }
        .react-countdown-simple__colon {
            padding: 0px 3px;
        }
    }
}
.NFTOnRentMd {
    bottom: 0px;
    background-color: #28292885;
    backdrop-filter: blur(0.5px);
    height: 60px;
    .rentalTextColor {
        text-transform: uppercase;
        font-family: var(--bigShoulders);
        color: #ff5353;
        text-align: center;
        padding: 5px 0px;
        font-weight: 700;
        font-size: 24px;
    }
    .rentalRemainingTime {
        margin-top: -10px;
        text-align: center;
        width: 100%;
        font-weight: 500;
        color: white !important;
        font-size: 10px;
        .react-countdown-simple {
            color: #f5f5f1 !important;
            padding: 0px;
            font-size: 10px;
        }
        .react-countdown-simple__date-time {
            font-weight: 700;
            font-size: 10px;
            color: white;
            text-transform: lowercase;
            span {
                min-width: 10px !important;
            }
        }
        .react-countdown-simple__colon {
            padding: 0px 3px;
        }
    }
    bottom: 0;
    background-color: #28292885;
    backdrop-filter: blur(0.5px);
}
.NFTOnRentSm {
    .rentalTextColor {
        text-transform: uppercase;
        font-family: var(--bigShoulders);
        color: #ff5353;
        text-align: center;
        padding: 5px 0px;
        font-weight: 700;
        font-size: 18px;
    }
    bottom: 0;
    background-color: #282928b6;
}

// hospitalized
.NFTOnIllLg {
    top: 37%;
    background-color: #28292885;
    backdrop-filter: blur(0.5px);
    height: 85px;
    .hospitalizedTextColor {
        text-transform: uppercase;
        font-family: var(--bigShoulders);
        color: #ff5353;
        text-align: center;
        padding: 5px 0px;
        font-weight: 700;
        font-size: 35px;
    }
    .hospitalizedRemainingTime {
        margin-top: -10px;
        text-align: center;
        width: 100%;
        font-weight: 500;
        color: white !important;
        font-size: 14px;
        .react-countdown-simple {
            color: #f5f5f1 !important;
            padding: 0px;
            font-size: 14px;
        }
        .react-countdown-simple__date-time {
            font-weight: 700;
            font-size: 14px;
            color: white;
            text-transform: lowercase;
            span {
                min-width: 10px !important;
            }
        }
        .react-countdown-simple__colon {
            padding: 0px 3px;
        }
    }
}
.NFTOnIllMd {
    top: 37%;
    background-color: #28292885;
    backdrop-filter: blur(0.5px);
    height: 60px;
    .hospitalizedTextColor {
        text-transform: uppercase;
        font-family: var(--bigShoulders);
        color: #ff5353;
        text-align: center;
        padding: 5px 0px;
        font-weight: 700;
        font-size: 24px;
    }
    .hospitalizedRemainingTime {
        margin-top: -10px;
        text-align: center;
        width: 100%;
        font-weight: 500;
        color: white !important;
        font-size: 10px;
        .react-countdown-simple {
            color: #f5f5f1 !important;
            padding: 0px;
            font-size: 10px;
        }
        .react-countdown-simple__date-time {
            font-weight: 700;
            font-size: 10px;
            color: white;
            text-transform: lowercase;
            span {
                min-width: 10px !important;
            }
        }
        .react-countdown-simple__colon {
            padding: 0px 3px;
        }
    }
    bottom: 0;
    background-color: #28292885;
    backdrop-filter: blur(0.5px);
}
.NFTOnIllSm {
    .hospitalizedTextColor {
        text-transform: uppercase;
        font-family: var(--bigShoulders);
        color: #ff5353;
        text-align: center;
        padding: 5px 0px;
        font-weight: 700;
        font-size: 18px;
    }
    top: 37%;
    background-color: #282928b6;
}
.lockedTextColor {
    text-transform: uppercase;
    font-family: var(--bigShoulders);
    color: #ff5353;
    text-align: center;
    padding: 5px 0px;
    font-weight: 700;
    font-size: 20px;
}
.flip {
    transform: scaleX(-1);
}
