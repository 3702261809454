@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@100;200;300;400;500;600;700;800;900&display=swap");
@font-face {
    font-family: "Proxima Nova";
    src: url("../../fonts/ProximaNova-Black.eot");
    src: local("Proxima Nova Black"), local("ProximaNova-Black"),
        url("../../fonts/ProximaNova-Black.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/ProximaNova-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("../../fonts/ProximaNova-Extrabld.eot");
    src: local("Proxima Nova Extrabold"), local("ProximaNova-Extrabld"),
        url("../../fonts/ProximaNova-Extrabld.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/ProximaNova-Extrabld.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("../../fonts/ProximaNova-SemiboldIt.eot");
    src: local("Proxima Nova Semibold Italic"), local("ProximaNova-SemiboldIt"),
        url("../../fonts/ProximaNova-SemiboldIt.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/ProximaNova-SemiboldIt.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("../../fonts/ProximaNovaT-Thin.eot");
    src: local("Proxima Nova Thin"), local("ProximaNovaT-Thin"),
        url("../../fonts/ProximaNovaT-Thin.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/ProximaNovaT-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("../../fonts/ProximaNova-Light.eot");
    src: local("Proxima Nova Light"), local("ProximaNova-Light"),
        url("../../fonts/ProximaNova-Light.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/ProximaNova-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("../../fonts/ProximaNova-BlackIt.eot");
    src: local("Proxima Nova Black Italic"), local("ProximaNova-BlackIt"),
        url("../../fonts/ProximaNova-BlackIt.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/ProximaNova-BlackIt.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("../../fonts/ProximaNova-BoldIt.eot");
    src: local("Proxima Nova Bold Italic"), local("ProximaNova-BoldIt"),
        url("../../fonts/ProximaNova-BoldIt.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/ProximaNova-BoldIt.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("../../fonts/ProximaNova-ThinIt.eot");
    src: local("Proxima Nova Thin Italic"), local("ProximaNova-ThinIt"),
        url("../../fonts/ProximaNova-ThinIt.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/ProximaNova-ThinIt.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("../../fonts/ProximaNova-Bold.eot");
    src: local("Proxima Nova Bold"), local("ProximaNova-Bold"),
        url("../../fonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/ProximaNova-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("../../fonts/ProximaNova-RegularIt.eot");
    src: local("Proxima Nova Regular Italic"), local("ProximaNova-RegularIt"),
        url("../../fonts/ProximaNova-RegularIt.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/ProximaNova-RegularIt.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("../../fonts/ProximaNova-ExtrabldIt.eot");
    src: local("Proxima Nova Extrabold Italic"), local("ProximaNova-ExtrabldIt"),
        url("../../fonts/ProximaNova-ExtrabldIt.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/ProximaNova-ExtrabldIt.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("../../fonts/ProximaNova-Regular.eot");
    src: local("Proxima Nova Regular"), local("ProximaNova-Regular"),
        url("../../fonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/ProximaNova-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("../../fonts/ProximaNova-LightIt.eot");
    src: local("Proxima Nova Light Italic"), local("ProximaNova-LightIt"),
        url("../../fonts/ProximaNova-LightIt.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/ProximaNova-LightIt.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("../../fonts/ProximaNova-Semibold.eot");
    src: local("Proxima Nova Semibold"), local("ProximaNova-Semibold"),
        url("../../fonts/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/ProximaNova-Semibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
