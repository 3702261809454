html {
  --white: #fff;
  --secondary-color: #bebebe;
  --dm-san: "DM Sans", sans-serif;
  --manrope: "Manrope", sans-serif;
  --cinzel: "Cinzel", serif;
  --primary-color: #e3c96e;
  --primary-blue: #079b9d;
  --bigShoulders: "Big Shoulders Display", cursive;
  --war-flame-1: yellow;
  --war-flame-2: #d8af27ba;
  --blood-red: #d73d008a;
}
