.SpecificType {
    // border-radius: 28px;
    padding: 2px 10px;
    color: var(--Text-White, #fff);
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    max-width: 125px;
    letter-spacing: 0.2px;
}
.cursor-not-allowed {
    cursor: not-allowed;
}
.GangstaBetType {
    // background: linear-gradient(180deg, #664d9c 0%, #43346e 100%);
    border: 0.8px solid #8f7630;
}
.GenderType {
    background: linear-gradient(180deg, #8f7630 0%, #725d20 100%);
}
.AccessoryType {
    background: linear-gradient(180deg, #297a67 0%, #1c5e5e 100%);
}
.WeaponsType {
    // background: linear-gradient(180deg, #314820 0%, #21380f 100%);
    // border: 0.8px solid #314820;
    border: 0.8px solid #6d7d40;
}
.GeneralType {
    // background: #3c41b8;
    border: 0.8px solid #40437d;
}

.tooltipTag {
    max-width: 200px;
    max-height: 130px;
    border: 1px solid rgb(71, 71, 71);
    background: black;
    font-size: 12px;
    text-align: center;
}
