.gangstabetPageWrapper {
    // min-width: 1400px;
    height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
    width: fit-content;
    // height: 700px;
    .gangstabetNftList {
        height: calc(100vh - 200px);
        width: 995px;
        max-width: 995px;
        // margin: auto;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            width: 8px !important;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: #000 !important;
            border: 1px solid rgb(190, 188, 188);
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgb(190, 188, 188) !important;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
    }
    .right-section-width,
    .nftSelectedCard {
        height: calc(100vh - 200px);
        overflow-y: auto;
        width: 500px;
        max-width: 500px;
        &::-webkit-scrollbar {
            width: 8px !important;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: #000 !important;
            border: 1px solid rgb(190, 188, 188);
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgb(190, 188, 188) !important;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
    }
    .header {
        // position: sticky;
        // top: 0;
        display: flex;
        justify-content: center;
        gap: 24px;
        background: rgba(28, 36, 42, 0.3);
        align-items: flex-end;
        padding: 48px;
        padding-bottom: 24px;
        .button {
            font-style: normal;
            font-weight: 500;
            // font-size: 30px;
            border: 2px solid #856f1f;
            background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
            padding: 4px 8px;
            gap: +px;
            display: flex;
            align-items: center;
            min-width: 113px;
        }

        .search {
            display: flex;
            align-items: center;
            border-bottom: 1px solid gray;
            padding-bottom: 6px;
            gap: 8px;
            max-width: 365px;
            input {
                background-color: transparent;
                color: white;
                size: 14px;
                width: 300px;
            }
        }
        .sort-dropdown {
            .dropdown-title-wrapper {
                color: #fff;
                gap: 8px;
                border: 1px solid grey;
                padding: 4px 10px;
                font: var(--font-14);

                .filter-title {
                    gap: 4px;
                    .filter-text {
                        font-weight: 700;
                        width: 150px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
            .filter-menu {
                box-shadow: 0px 0px 16px rgba(10, 14, 16, 0.75);
                background: black;
                position: absolute;
                color: gray;
                min-width: 185px;
                max-width: max-content;
                z-index: 90;
                padding: 12px 0px;
                border: 1px solid grey;
                top: 100%;
                right: 0;
                .option {
                    &:hover {
                        color: var(--primary-color);
                    }
                }
                p {
                    cursor: pointer;
                    font: var(--font-14);
                    color: var(--grey-color);
                    padding: 10px 16px;
                }

                .filter-selected {
                    font-weight: 700;
                    color: var(--primary-color);
                }
                // .filterOptionText {
                //   width: 152px;
                //   overflow: hidden;
                //   white-space: nowrap;
                //   text-overflow: ellipsis;
                // }
            }
        }
    }
    .body {
        padding-top: 5px;
        .cardsSection {
            // height: 700px;
            // margin-top: 40px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 8px !important;
            }
            /* Track */
            &::-webkit-scrollbar-track {
                background: #000 !important;
                border: 1px solid rgb(190, 188, 188);
            }
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: rgb(190, 188, 188) !important;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }
        }
    }
    .right-section {
        .nftWeapons {
            margin-top: 15px;
            .weapons {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                .target {
                    -webkit-filter: grayscale(100%);
                    -moz-filter: grayscale(100%);
                    -ms-filter: grayscale(100%);
                    -o-filter: grayscale(100%);
                    filter: grayscale(1);
                    border-left: 1px solid rgb(102, 101, 101);
                    padding-left: 10px;
                }
            }
        }

        .nftSkills {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 20px;
            .nftSkillTitle {
                font-weight: 400;
                font-size: 18px;
                line-height: 20px;
                color: #70878f;
            }
            .nftActualSkills {
                display: flex;
                width: inherit;
                height: inherit;
                position: relative;
                isolation: isolate;
                border-image-slice: 27 27 27 27;
                border-image-width: 20px 20px 20px 20px;
                border-image-outset: 0px 0px 0px 0px;
                border-image-repeat: stretch stretch;
                border-image-source: url("../../../images/backgrounds/base.png");
                border-style: solid;
                border-width: 2px 2px 2px 2px;

                .skillsLeft {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    padding: 10px;
                    border-right: 1px solid grey;
                    .skillInfo {
                        display: flex;
                        // gap: 30px;
                        justify-content: space-between;

                        .skillName {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #70878f;
                        }
                        .skillNumber {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #ffffff;
                        }
                    }
                }
                .skillsRight {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    padding: 10px;
                    .skillInfo {
                        display: flex;
                        // gap: 30px;
                        justify-content: space-between;
                        .skillName {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #70878f;
                        }
                        .skillNumber {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
    .rapidRecoverNFTTopText {
        font-size: 16px !important;
    }
    .notNowBtn {
        font-family: var(--bigShoulders);
        font-size: 28px !important;
    }
    /* width */
}

.dot-gb {
    width: 20px;
    border-radius: 100%;
    background-color: white;
}

// out of hospitalization buttons
.outOfHospitalization {
    .fullRecoverBtn {
        padding: 8px 16px;
        gap: 8px;
        width: 244px;
        height: 36px;
        background: #0a0e10;
        border: 1px solid #ae963e;
        color: white;
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 600;
    }
    .halfRecoverBtn {
        padding: 8px 16px;
        gap: 8px;
        width: 244px;
        height: 36px;
        background: #26343d;
        color: white;
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 600;
    }
    .recoveryBtnSeparator {
        background-color: #485c65;
        width: 2px;
        height: 88px;
    }
    .recoveeryTimerBox {
        .recoveryTimer {
            .recoveryTimerText {
                font-family: "Proxima Nova";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #70878f;
            }
            .react-countdown-simple {
                color: white !important;
                font-size: 14px;
                padding: 0px !important;
            }
            .react-countdown-simple__date-time {
                font-weight: 700;
                font-size: 14px;

                span {
                    min-width: 10px !important;
                }
            }
            .react-countdown-simple__colon {
                padding: 0px 3px;
            }
        }
    }
}
.batchLoading {
    font-family: "Big Shoulders Display";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 64px;
    color: #ffffff;
    opacity: 0.3;
}

// gangstaBet collection pagination css
.gbCollectionPagination {
    &_prev {
        border: 2px solid #e3c96e;
        padding: 5px;
    }
    &_current {
        font-size: 18px;
        font-weight: 800;
    }
    &_next {
        border: 2px solid #e3c96e;
        padding: 5px;
    }
}

.rentalLoading {
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-family: "Big Shoulders Display";
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.05em;
}
.hospitalizationTimerInRentMarket {
    .react-countdown-simple {
        color: #ff5353 !important;
        font-size: 12px;
    }
    .react-countdown-simple__date-time {
        font-weight: 400;
        font-size: 12px;
        span {
            min-width: 10px !important;
        }
    }
    .react-countdown-simple__colon {
        padding: 0px 3px;
    }
}

.rentalMarketplaceHospitalization {
    position: absolute;
    background-color: black;
    bottom: 0;
    &_title {
        color: #ff5353;
        font-size: 15px;
        font-weight: 600;
    }
}

.gangstabetRentNftList {
    max-width: 960px;
    width: 960px;
    max-height: calc(100vh - 90px);
    overflow-y: auto;
}
.right-section-rent-width,
.nftSelectedCard {
    height: calc(100vh - 90px);
    overflow-y: auto;
    width: 500px;
    max-width: 500px;
    &::-webkit-scrollbar {
        width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: #000 !important;
        border: 1px solid rgb(190, 188, 188);
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgb(190, 188, 188) !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
}

// .nftWeapons {
//     margin-top: 15px;
//     .weapons {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         gap: 10px;
//         .target {
//             -webkit-filter: grayscale(100%);
//             -moz-filter: grayscale(100%);
//             -ms-filter: grayscale(100%);
//             -o-filter: grayscale(100%);
//             filter: grayscale(1);
//             border-left: 1px solid rgb(102, 101, 101);
//             padding-left: 10px;
//         }
//     }
// }
// .nftActualSkills {
//     min-width: 330px;
// }
