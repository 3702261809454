.rental-modal-outer-wrapper {
    .titleText {
        padding-bottom: 8px;
        font-family: "Big Shoulders Display";
        font-size: 28px;
        font-display: normal;
        font-weight: 700;
        line-height: 36px;
        color: #e3c96e;
        background-image: none;
        border-bottom: 2px solid;
        border-image: linear-gradient(to right, transparent, #d6bd63, transparent) 1;
    }
    .modalWrapper {
        animation: slideDown 0.5s ease-in-out forwards;
    }
}

.claim-modal-outer-wrapper {
    .titleText {
        font-family: "Big Shoulders Display";
        font-size: 28px;
        font-display: normal;
        padding-left: 2%;
        padding-right: 2%;
        font-weight: 700;
        line-height: 36px;
        color: #e3c96e;
        background-image: none;
        border-bottom: 2px solid;
        border-image: linear-gradient(to right, transparent, #d6bd63) 1;
    }
    .modalComponent {
        animation: slideDown 0.5s ease-in-out forwards;
        font-family: "Proxima Nova";
        overflow: visible;
        width: inherit;
        height: inherit;
        background: #0a0e10;
        position: relative;
        isolation: isolate;
        min-width: 80vw;
        min-height: 80vh;
        border: 1px solid;
        border-color: #e3c96e;
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(-100vh);
    }
    100% {
        transform: translateY(0vh);
    }
}

.description-text {
    margin-top: 8px;
    width: 100%;
    font-family: "Proxima Nova";
    font-style: normal;
    font-size: 16px;
    color: #c3c3c4;
    line-height: 24px;
    letter-spacing: 0.8px;
    font-weight: 400;
    span {
        color: white;
        font-weight: 700;
    }
}
.success-description-text {
    color: #c3c3c4;
    width: 100%;
    border: 2px solid;
    border-image: linear-gradient(to right, transparent, #d6bd63, transparent) 1;
    span {
        color: white;
        font-weight: 700;
    }
}
.input-box {
    background: transparent;
    border: 1px solid;
    color: white;
    font-size: 20px;
    font-weight: 600;
    border-color: #485c65;
    outline: none;
}

.input-area,
.input-area-text {
    color: white;
    outline: none;
    background: transparent;
    font-family: "Big Shoulders Display";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
    letter-spacing: 0.6px;
    z-index: 10;
}
