.inventoryContent {
    background: url("../../../images/backgrounds/inventory.svg");
    background-size: cover;
    background-position: right;
    .inventoryMain {
        height: calc(100vh - 72px) !important;
    }
    .sidebar {
        border-right: 1px solid #9aa8ac;
        padding-top: 50px;
        backdrop-filter: blur(60px);
        ul {
            .activeClassName {
                background: linear-gradient(
                    270deg,
                    rgba(10, 14, 16, 0) 3.77%,
                    rgba(255, 198, 0, 0.4) 100%
                ) !important;
            }
            .hoverClass {
                &:hover {
                    background: linear-gradient(
                        270deg,
                        rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, 0.2) 100%
                    );
                }
            }
            li {
                font-size: 36px;
                color: white;
                font-family: var(--bigShoulders);
                display: flex;
                letter-spacing: 0.1rem;
                // line-height: 24px;
            }
            a {
                padding: 8px 16px;
                width: 100%;
            }
        }
    }
    .options {
        border-right: 1px solid #9aa8ac;
        // padding: 22px;
        overflow-y: auto;
        padding-top: 50px;

        .activeOption {
            border: 1px solid var(--primary-color);
            box-shadow: 0 0 10px #d8b027;
        }
        img {
            margin: auto;
        }
    }

    .abilitiesOptions,
    .itemsOptions,
    .lootcrateOptions {
        border-right: 1px solid rgb(136, 131, 131);
        padding: 22px;
        overflow-y: auto;
        padding-top: 50px;

        .activeOption {
            border: 1px solid var(--primary-color);
            box-shadow: 0 0 10px #d8b027;
        }
        img {
            // margin: auto;
        }
        .inactive-image {
            filter: grayscale(100%);
        }
        .number {
            font-weight: bold;
            font-size: 14px;
            position: absolute;
            // right: 40px;
            padding-right: 20px;
            height: 30px;
            // bottom: 55px;
            overflow: hidden;
            top: 106px;
            font-family: var(--manrope);
            background: linear-gradient(
                180deg,
                rgba(2, 13, 20, 0) 0%,
                rgba(2, 13, 20, 0.7) 99.99%,
                rgba(2, 13, 20, 0.8) 100%
            );
            width: 130px;
            left: -10px;
            margin: auto;
            text-align: right;
        }
    }

    .itemsOptions {
        .itemsNumber {
            .badgeImage {
                margin: 0;
            }
            .badgeNumber {
                color: var(--primary-color);
                font-weight: 700;
                font-size: 18px;
                font-family: var(--manrope);
            }
        }
        .newLabel {
            position: absolute;
            top: -15px;
            left: 50%;
            transform: translateX(-50%);
            width: fit-content;
            font-weight: bold;
            border: 1px solid var(--primary-color);
            border-radius: 8px;
            padding: 1px 4px;
            font-size: 20px;
            background-color: black;
            text-align: center;
            margin: auto;
        }
    }
    .details {
        padding: 22px;
        padding-top: 50px;
        backdrop-filter: blur(64px);
    }
    .contineWarBtn {
        padding: 4px 8px 4px 8px;
    }
}
.unSelectedTextState {
    font-family: "Big Shoulders Display";
    font-style: normal;
    font-weight: 700;
}
.number {
    font-weight: bold;
    font-size: 18px;
    position: absolute;
    right: 25px;
    bottom: 40px;
    font-family: var(--manrope);
}
.lootcrateOptions,
.details,
.itemsOptions,
.abilitiesOptions,
.weaponOption {
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: #000 !important;
        border: 1px solid rgb(190, 188, 188);
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgb(190, 188, 188) !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
}
.inventoryBoxHover {
    &:hover {
        border: 1px solid #9aa8ac;
        box-shadow: 2px 2px 8px rgba(255, 255, 255, 0.08);
    }
}
.equipmentCount {
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    right: 24px;
    bottom: 52px;
    background: linear-gradient(
        180deg,
        rgba(2, 13, 20, 0) 0%,
        rgba(2, 13, 20, 0.7) 99.99%,
        rgba(2, 13, 20, 0.8) 100%
    );
    width: 70%;
    margin: auto;
    text-align: right;
    font-family: var(--manrope);
}
.equipmentCount-inuse {
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    right: 10px;
    bottom: 34px;
    background: linear-gradient(
        180deg,
        rgba(2, 13, 20, 0) 0%,
        rgba(2, 13, 20, 0.7) 99.99%,
        rgba(2, 13, 20, 0.8) 100%
    );
    width: 90%;
    margin: auto;
    text-align: right;
    font-family: var(--manrope);
}
.lootcrateOptions,
.details {
    overflow-y: auto;

    .lootCrateNumber {
        font-weight: bold;
        font-size: 16px;
        position: absolute;
        right: 6%;
        bottom: 1%;
        font-family: var(--manrope);
    }
}

.abilityTypeBtn {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    font-family: var(--manrope);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 120px;

    border: 2px solid #856f1f;
    // background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
    // padding: 4px 8px 4px 8px;
    // gap: 4px;
    cursor: none;
    height: 35px;
}

.inventoryPage {
    .header-right-items {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        color: white;
        margin-right: 15px;
        position: relative;
        .active {
            position: relative;

            &::after {
                content: " ";
                width: 100%;
                height: 6px;
                background-color: var(--primary-color);
                position: absolute;
                bottom: -10px;
                left: 0;
                right: 0;
            }
        }
    }

    .notification-menu {
        width: 400px;

        li {
            cursor: pointer;
            color: #70878f;
            background: rgba(28, 36, 42, 0.1);
        }
        position: absolute;
        list-style: none;
        border: 1px solid #fff;
        background: black;
        top: 60px;
        z-index: 999;
        right: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        .notification-body {
            background: rgba(50, 50, 50, 0.493);
            overflow-y: auto;
            height: 500px;

            &::-webkit-scrollbar {
                width: 8px !important;
            }
            /* Track */
            &::-webkit-scrollbar-track {
                background: #000 !important;
                border: 1px solid rgb(190, 188, 188);
            }
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: rgb(190, 188, 188) !important;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }
            .old-mail {
                padding: 8px 0px;
                background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
            }

            li {
                cursor: pointer;
                padding: 15px;
                &:hover {
                    background: linear-gradient(90deg, #0a0e10 0%, #26343d 166.67%);
                }
            }
        }
    }
    .user-menu {
        z-index: 4;
        width: 143px;
        background: rgba(10, 14, 16, 0.98);
        li {
            margin: 8px 0px;
            cursor: pointer;
            padding: 8px 15px;
            &:hover {
                background: rgb(25, 25, 25);
                background: linear-gradient(90deg, #0a0e10 0%, #26343d 166.67%);
            }
        }
        position: absolute;
        list-style: none;
        color: #70878f;
        border: 1px solid #fff;
        padding: 1px 0px;
        top: 60px;
        left: 22px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
}
.overlayScreen {
    background: linear-gradient(180deg, rgb(0, 0, 0) 100%, rgba(0, 0, 0, 0.9) 100%);
    mix-blend-mode: lighten;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    top: 0;
    left: 0;
    backdrop-filter: blur(20px);
    .imageShadow {
        filter: drop-shadow(10px 10px 122px rgba(255, 198, 0, 0.78));
    }
    // .bounceInDown {
    //   animation: bounceInDown 3s ease;
    // }
    // @keyframes bounceInDown {
    //   0% {
    //     opacity: 0;
    //     transform: translateY(-2000px);
    //   }
    //   60% {
    //     opacity: 1;
    //     transform: translateX(-30px);
    //   }
    //   80% {
    //     transform: translateX(10px);
    //   }
    //   100% {
    //     transform: translateX(0);
    //   }
    // }
}
.stylishCard {
    --background-color: linear-gradient(
        0deg,
        rgba(2, 13, 20, 0) 0%,
        rgba(2, 13, 20, 0.7) 99.99%,
        rgba(2, 13, 20, 0.8) 100%
    );
    --border-color: #25561d;
    --border-width: 0.15em;
    --edge-size: 1.2em;
    cursor: pointer;
    // width: 100%;
    height: 155px;
    background: var(--background-color);
    padding: 1.5em;
    border: 0;
    color: #ffffff;
    min-width: 100%;
    text-transform: uppercase;
    font-family: var(--bigShoulders);
    position: relative;
    isolation: isolate;
    display: inline-grid;
    place-content: center;
    font-weight: 700;
    font-size: 20px;
    clip-path: polygon(
        1.2em 0%,
        calc(100% - 1.2em) 0%,
        100% 1.2em,
        100% calc(100% - 1.2em),
        calc(100% - 1.2em) 100%,
        1.2em 100%,
        0% calc(100% - 1.2em),
        0% 1.2em
    );

    transition: color 250ms;
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        background: var(--border-color);
        z-index: -2;
    }
    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: var(--background-color);
        z-index: -1;
        clip-path: polygon(
            1.2em calc(0.15em),
            calc((100% - 1.2em)) calc(0.15em),
            calc(100% - 0.15em) 1.2em,
            calc(100% - 0.15em) calc(100% - 1.2em),
            calc(100% - 1.2em) calc(100% - 0.15em),
            1.2em calc(100% - 0.15em),
            calc(0.15em) calc(100% - 1.2em),
            calc(0.15em) 1.2em
        );
    }
}
.purple {
    background: linear-gradient(180deg, rgba(60, 11, 108, 0) 9.09%, rgba(70, 18, 122, 0.9) 100%);
    --border-color: #522a7a;
}
.brown {
    background: linear-gradient(180deg, rgba(108, 35, 11, 0) 9.09%, rgba(122, 44, 18, 0.9) 100%);
    --border-color: #542a1d;
}
.yellow {
    background: linear-gradient(180deg, rgba(133, 111, 31, 0) 0%, #856f1f 100%);
    --border-color: #ae963e;
}
.gray {
    background: linear-gradient(180deg, rgba(112, 135, 143, 0) 0%, #70878f 100%);
    --border-color: #70878f;
}
.lootCrateNumber {
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    right: 10%;
    bottom: 10%;
    font-family: var(--manrope);
    // right: 36px;
    // height: 30px;
    // bottom: 75px;
    // background: linear-gradient(
    //   180deg,
    //   rgba(2, 13, 20, 0) 0%,
    //   rgba(2, 13, 20, 0.7) 99.99%,
    //   rgba(2, 13, 20, 0.8) 100%
    // );
    // width: 100%;
    margin: auto;
    text-align: right;

    /* Shadow/Text/Hard shadow */
    text-shadow: 2px 1px 0px #000;
    /* Sub-heading/H3 */
    font-family: Proxima Nova;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.image-background-inventory-inuse {
    background-color: #1c242a7d;
    min-height: 110px;
    min-width: 110px;
    max-height: 110px;
    max-width: 110px;
    clip-path: polygon(
        1.2em 0%,
        calc(100% - 1.2em) 0%,
        100% 1.2em,
        100% calc(100% - 1.2em),
        calc(100% - 1.2em) 100%,
        1.2em 100%,
        0% calc(100% - 1.2em),
        0% 1.2em
    );

    position: relative;
}

.image-background-inventory {
    background-color: #1c242a7d;
    min-height: 120px;
    min-width: 120px;
    max-height: 120px;
    max-width: 120px;
    clip-path: polygon(
        1.2em 0%,
        calc(100% - 1.2em) 0%,
        100% 1.2em,
        100% calc(100% - 1.2em),
        calc(100% - 1.2em) 100%,
        1.2em 100%,
        0% calc(100% - 1.2em),
        0% 1.2em
    );

    position: relative;
}

.itemCount {
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    // right: 40px;
    padding-right: 20px;
    height: 30px;
    // bottom: 55px;
    overflow: hidden;
    top: 95px;
    font-family: var(--manrope);
    background: linear-gradient(
        180deg,
        rgba(2, 13, 20, 0) 0%,
        rgba(2, 13, 20, 0.7) 99.99%,
        rgba(2, 13, 20, 0.8) 100%
    );
    width: 130px;
    left: -10px;
    margin: auto;
    text-align: right;
}

.apply-border {
    border: 1px solid rgba(88, 104, 110, 0.393);
    backdrop-filter: blur(140px);
}

.moreInfoText {
    // border-top: 1px solid grey;
    // padding: 4px;
    margin-top: 10px;
    .seperatorInventory {
        width: 500px;
        overflow: hidden;
        margin-left: -55px;
        color: #485c65;
        background-color: #485c65;
    }
    p {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 200;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        color: #70878f;
        letter-spacing: 0.75px;
    }
    a {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 200;
        font-size: 14px;
        line-height: 20px;
        // text-decoration-line: underline;
        color: #00ccff;
    }
}

.moreInfoTextEmpty {
    // border-top: 1px solid grey;
    // padding: 4px;
    // margin-bottom: 20px;
    padding: 25px;
    .seperatorInventory {
        // width: 100%;
        overflow: hidden;
        margin-left: -55px;
        color: #485c65;
        background-color: #485c65;
    }
    p {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 200;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        color: #70878f;
        letter-spacing: 0.75px;
    }
    a {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 200;
        font-size: 14px;
        line-height: 20px;
        // text-decoration-line: underline;
        color: #00ccff;
    }
}

.item-images {
    // margin-left: 20px;
    // margin-top: 11px;
    padding: 15px;
}

.change-inventory-seperator {
    border-color: #485c65;
}

.moreInfoWeapons {
    position: relative;
    .moreInfoSeperator {
        // position: absolute;
        border-color: #485c65;
    }
    p {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #70878f;
    }
    p:nth-child(2) {
        // color: #00ccff;
    }
}

.viewInCraft {
    // cursor: no-drop;
    width: 100%;
    height: 40px;
    background: rgba(28, 36, 42, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    // cursor: pointer;
    button {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #70878f;
    }
    img {
        width: 25px;
    }
    opacity: 0.9;
    &:hover {
        opacity: 1;
    }
}
.no-drop {
    cursor: no-drop !important;
}
// .help-text-weapons::before {
//   content: attr(title);
//   background-color: black;
//   color: white;
// }

//crate color
.stylishCard {
    --background-color: linear-gradient(
        0deg,
        rgba(2, 13, 20, 0) 0%,
        rgba(2, 13, 20, 0.7) 99.99%,
        rgba(2, 13, 20, 0.8) 90%
    );
    --border-color: #25561d;
    --border-width: 0.15em;
    --edge-size: 1.2em;
    cursor: pointer;
    width: 100%;
    height: fit-content;
    background: var(--background-color);
    padding: 1.5em;
    border: 0;
    color: #ffffff;
    text-transform: uppercase;
    font-family: var(--bigShoulders);
    position: relative;
    isolation: isolate;
    display: inline-grid;
    place-content: center;
    font-weight: 700;
    font-size: 20px;
    clip-path: polygon(
        1.2em 0%,
        calc(100% - 1.2em) 0%,
        100% 1.2em,
        100% calc(100% - 1.2em),
        calc(100% - 1.2em) 100%,
        1.2em 100%,
        0% calc(100% - 1.2em),
        0% 1.2em
    );

    transition: color 250ms;
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        background: var(--border-color);
        z-index: -2;
    }
    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: var(--background-color);
        // linear-gradient(
        //   to left bottom,
        //   #321a42,
        //   #341a45,
        //   #361b48,
        //   #381b4c,
        //   #3a1b4f
        // );
        z-index: -1;
        clip-path: polygon(
            1.2em calc(0.15em),
            calc((100% - 1.2em)) calc(0.15em),
            calc(100% - 0.15em) 1.2em,
            calc(100% - 0.15em) calc(100% - 1.2em),
            calc(100% - 1.2em) calc(100% - 0.15em),
            1.4em calc(100% - 0.15em),
            calc(0.15em) calc(100% - 1.2em),
            calc(0.15em) 1.2em
        );
    }
}
.grey {
    background: linear-gradient(180deg, rgba(112, 135, 143, 0) 0%, #5a6c72 100%);
    --border-color: #70878f;
}
.lightYellow {
    background: linear-gradient(180deg, #1f1a08 0%, #856f1f 100%);
    --border-color: #ae963e;
}
.orange {
    background: linear-gradient(180deg, #181000 0%, #956300 100%);
    --border-color: #956300;
}
.lightOrange {
    background: linear-gradient(180deg, #180f00 0%, #794900 100%);
    --border-color: #7a4a00;
}
.skyBlue {
    background: linear-gradient(180deg, rgba(0, 62, 81, 0) 0%, #0082a9 100%);
    --border-color: #0082a9;
}
.lightGreen {
    background: linear-gradient(180deg, rgba(24, 108, 11, 0) 9.09%, rgba(32, 122, 18, 0.9) 100%);
    --border-color: #25561d;
}
.lightRed {
    background: linear-gradient(180deg, rgba(108, 35, 11, 0) 9.09%, rgba(122, 44, 18, 0.9) 100%);
    --border-color: #542a1d;
}
.lightYellow {
    background: linear-gradient(180deg, #151300 0%, #b8a500 100%);
    --border-color: #a39300;
}
.purple {
    background: linear-gradient(180deg, rgba(60, 11, 108, 0) 9.09%, rgba(70, 18, 122, 0.9) 100%);
    --border-color: #522a7a;
}
.brown {
    background: linear-gradient(180deg, rgba(108, 35, 11, 0) 9.09%, rgba(122, 44, 18, 0.9) 100%);
    --border-color: #542a1d;
}
.yellow {
    background: linear-gradient(180deg, rgba(133, 111, 31, 0) 0%, #856f1f 100%);
    --border-color: #ae963e;
}
.gray {
    background: linear-gradient(180deg, rgba(112, 135, 143, 0) 0%, #70878f 100%);
    --border-color: #70878f;
}

.lightBlue {
    background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(5, 109, 226, 1) 35%,
        rgba(8, 30, 134, 1) 100%
    );
    --border-color: #056de2;
}

.darkOrange {
    background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(226, 75, 5, 1) 44%,
        rgba(131, 46, 0, 1) 100%
    );
    --border-color: #c74407;
}

.goldenLight {
    background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(199, 168, 7, 1) 44%,
        rgba(131, 112, 0, 1) 100%
    );
    --border-color: #c7a807;
}

.gotoDiscordText {
    font-family: "Proxima Nova";
}

//weapons in use state
.inUseState {
    padding: 12px 24px;
    width: 100%;
    // height: 104px;
    border: 1px solid #1c242a;
    .inUseTexts {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #70878f;
        span {
            color: #fb9d51;
        }
    }
}

//weapons in cooldown state
.inCooldownState {
    padding: 12px 24px;
    width: 100%;
    border: 1px solid #1c242a;
    .autoRepairTimerBlock {
        .autoTimer {
            .repairText {
                font-family: "Proxima Nova";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #70878f;
            }
            .repairTimer {
                .react-countdown-simple {
                    color: white !important;
                    font-size: 14px;
                    padding: 0px !important;
                }
                .react-countdown-simple__date-time {
                    font-weight: 700;
                    font-size: 14px;

                    span {
                        min-width: 10px !important;
                    }
                }
                .react-countdown-simple__colon {
                    padding: 0px 3px;
                }
            }
        }
    }
    .repairText {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #70878f;

        span {
            color: #fb9d51;
        }
    }
}
.speInventoryTop {
    border-color: #485c65;
}

.cratePlainCardWrapper {
    --background-color: linear-gradient(
        0deg,
        rgba(2, 13, 20, 0) 0%,
        rgba(2, 13, 20, 0.7) 99.99%,
        rgba(2, 13, 20, 0.8) 90%
    );
    --border-width: 0.15em;
    --edge-size: 1.2em;
    cursor: pointer;
    width: 100%;
    height: fit-content;
    background: var(--background-color);
    border: 0;
    color: #ffffff;
    text-transform: uppercase;
    font-family: var(--bigShoulders);
    position: relative;
    isolation: isolate;
    display: inline-grid;
    place-content: center;
    font-weight: 700;
    font-size: 20px;
    clip-path: polygon(7% 0%, 94% 0%, 100% 7%, 100% 92.5%, 93% 100%, 6.5% 100%, 0% 93%, 0% 6.5%);
    transition: color 250ms;
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        background: var(--border-color);
        z-index: -2;
    }
    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: var(--background-color);
        z-index: -1;
        clip-path: polygon(
            7% 0.1em,
            94% 0.1em,
            calc(100% - 0.1em) 7%,
            calc(100% - 0.1em) 92.5%,
            93% calc(100% - 0.1em),
            6.5% calc(100% - 0.1em),
            0.1em 93%,
            0.1em 6.5%
        );
    }
}
