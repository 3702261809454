.equipmentStylizedWrapper {
  width: 128px;
  height: 128px;
  position: relative;
  .equipmentStylizedComponent {
    font-family: var(--bigShoulders);
    overflow: visible;
    width: inherit;
    height: inherit;
    position: relative;
    isolation: isolate;
    clip-path: polygon(
      1.5em 0%,
      calc(100% - 1.5em) 0%,
      100% 1.5em,
      100% calc(100% - 1.5em),
      calc(100% - 1.5em) 100%,
      1.5em 100%,
      0% calc(100% - 1.5em),
      0% 1.5em
    );
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background: #e3c96e;
      z-index: -2;
      //   background-color: grey;
      //   background-color: transparent;
      //   box-shadow: inset 0px 0px 0px 10px grey;
    }
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(
        180deg,
        rgba(2, 13, 20, 0.82) 0%,
        rgba(2, 13, 20, 0.8) 100%
      );
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;
      clip-path: polygon(
        1.5em 0.15em,
        calc(100% - 1.5em) 0.15em,
        calc(100% - 0.15em) 1.5em,
        calc(100% - 0.15em) calc(100% - 1.5em),
        calc(100% - 1.5em) calc(100% - 0.15em),
        1.5em calc(100% - 0.15em),
        0.15em calc(100% - 1.5em),
        0.15em 1.5em
      );
    }
    padding: 20px;
    img {
      width: 100%;
    }
  }
  .backgroundStylizer {
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    position: absolute;
    border: 2px solid #e3c96e;
    top: 5px;
    left: 5px;
    z-index: -1;
    bottom: 2px;
  }
}

.equipItemRightPart {
  .equipmentStylizedWrapper {
    padding: 0px;
  }
}
