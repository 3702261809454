.modalOverlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(13, 12, 12, 0.546);
  z-index: 9999999;
  top: 0;
  left: 0;
  backdrop-filter: blur(2px);

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    left: 50%;
    top: 0;
    width: 100%;
    transform: translate(-50%, 0);
  }

  &.overflowModal {
    @media screen and (max-width: 768px) {
      left: 50%;
      top: 0;
      width: 100%;
      transform: none;
    }
  }
  
}
.childOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;

  &.overflowModal {
    @media screen and (max-width: 768px) {
      transform: translate(0, -50%);
    }
  }
}
.modalWrapper {
  position: relative;
  min-width: 600px;
  // @media screen and (max-width: 1000px) {
  //   min-width: 100%;
  //   width: 100% !important;
  //   height: 600px !important;
  //   overflow-y: scroll;
  // }
  .modalCloseButton {
    position: absolute;
    right: -13.8px;
    z-index: 200;
    top: -13.1px;
  }
  .modalCutterTopLeft {
    position: absolute;
    height: 2px;
    background-color: #e3c96e;
    border: none;
    z-index: 1;
    width: 30px;
    top: 13px;
    left: -3px;
    transform: rotate(130deg);
  }
  .modalCutterBottomLeft {
    position: absolute;
    height: 2px;
    background-color: #e3c96e;
    border: none;
    z-index: 1;
    width: 30px;
    bottom: 13px;
    left: -3px;
    transform: rotate(230deg);
  }
  .modalCutterBottomRight {
    position: absolute;
    height: 2px;
    background-color: #e3c96e;
    border: none;
    z-index: 1;
    width: 30px;
    bottom: 13px;
    right: -3px;
    transform: rotate(130deg);
  }
  .modalComponent {
    font-family: var(--bigShoulders);
    overflow: visible;
    width: inherit;
    height: inherit;
    background: url("../../../images/backgrounds/modalBg.svg"), black;
    position: relative;
    isolation: isolate;
    min-width: 600px;
    clip-path: polygon(
      0.6em 0%,
      calc(100% - 0.6em) 0%,
      100% 0.6em,
      100% calc(100% - 0.6em),
      calc(100% - 0.6em) 100%,
      0.6em 100%,
      0% calc(100% - 0.6em),
      0% 0.6em
    );

    @media screen and (max-width: 1000px) {
      min-width: 100%;
      width: 100% !important;
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background: #e3c96e;
      z-index: -2;
    }
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: url("../../../images/backgrounds/modalBg.svg"),
        rgba(0, 0, 0, 0.91);
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;
      clip-path: polygon(
        0.6em 0.15em,
        calc(100% - 0.6em) 0.15em,
        calc(100% - 0.15em) 0.6em,
        calc(100% - 0.15em) calc(100% - 0.6em),
        calc(100% - 0.6em) calc(100% - 0.15em),
        0.6em calc(100% - 0.15em),
        0.15em calc(100% - 0.6em),
        0.15em 0.6em
      );
    }
    padding: 20px;
    .modalTitle {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 10px;

      .titleText {
          &.success {
              color: rgba(227, 201, 110, 1);
          }
          &.fail {
              color: rgba(255, 83, 83, 1);
          }
        }
    }

    .modalBody {
      margin: 10px 0px;
      display: flex;
      // align-items: center;
      justify-content: center;
    }
  }
}

.modal-countdown {
  .react-countdown-simple {
    color: white !important;
    font-size: 10px;
    background-clip: text !important;
  }
  .react-countdown-simple__date-time {
    font-weight: 700;
    font-size: 10px;

    span {
      min-width: 10px !important;
    }
  }
  .react-countdown-simple__colon {
    padding: 0px 3px;
  }
}

.titleText {
  font-weight: 600;
  font-size: 40px;
  background-image: url("../../../images/extra/underline.svg");
  background-repeat: repeat-x;
  background-position: bottom;
  padding-bottom: 3%;
  padding-left: 20%;
  padding-right: 20%;
  color: #e3c96e;
  min-width: 400px;
  background-size: contain;
}
.notNowBtn {
  font-family: "Big Shoulders Display";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  color: #fff5d0;
  text-shadow: 0px 0px 4px rgba(245, 209, 136, 0.5);
}

.glowEllipse {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  filter: blur(90px);
  &.success {
      background: rgba(227, 201, 110, 1)
  }
  &.fail {
      background:rgba(255, 83, 83, 1);
  }
}
