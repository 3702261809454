.warNotCloseWrapper {
  font-family: "Proxima Nova";
  padding: 7px;
  display: flex;
  flex-direction: column;
  //   gap: 5px;
  align-items: center;
  .warTimerInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    .warTimerText {
      font-weight: 400;
    }
    .warTime {
      .warTimeStyle {
        height: 1px;
        background-color: #e3c96e;
        width: 50px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      .warTimeClock {
        color: #e3c96e;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
  .warNotCloseText {
    width: 90%;
    margin: auto;
    .warNotCloseTopText {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      padding: 7px;
      text-align: center;

      color: #ffffff;
    }
    .warNotCloseInfo {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      padding: 7px;
      text-align: center;

      color: #ffffff;
    }
  }
}
