.warDetailsSideBar {
  display: flex;
  overflow-x: hidden;
  transition: transform 1s;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  // height: 100vh !important;
  // backdrop-filter: blur(7px);
  .drawer_overlay {
    width: 75%;
    height: 100%;
    // backdrop-filter: blur(7px);
    // -webkit-backdrop-filter: blur(7px);
  }
  .drawer {
    width: 375px;
    margin: 20px;
    max-height: 630px;
    margin-top: 120px;
    min-width: 375px;
    background: rgba(10, 14, 16, 0.98);
    z-index: 999;
    border: 1px solid #e3c96e;
    // box-shadow: 1px 1px 24px 0px rgba(227, 201, 110, 1);
    // -webkit-box-shadow: 1px 1px 24px 0px rgba(227, 201, 110, 1);
    // -moz-box-shadow: 1px 1px 24px 0px rgba(227, 201, 110, 1);
    .drawer_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid var(--primary-color);
      border-width: 2px;
      border-style: solid;
      border-image: radial-gradient(circle at center, #e3c96e, #856f1f00) 2;
      .title {
        color: white;
        font-family: "Big Shoulders Display";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 48px;
      }
    }
    .drawer_body {
      color: white;
      overflow-y: auto;
      z-index: 9999;
      // height: calc(100vh - 50px) !important;
      @media (max-width: 1700px) {
        // height: calc(100vh - 240px) !important;
      }
      &::-webkit-scrollbar {
        width: 8px !important;
      }
      /* Track */

      &::-webkit-scrollbar-track {
        background: #000 !important;
        border: 1px solid rgb(190, 188, 188);
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgb(190, 188, 188) !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
      .custom-height {
        height: 100px !important;
        --edge-size: 1rem;
        padding: 1em;
        img {
          height: 70px;
        }
      }
      .fightScreen {
        padding: 20px;
        // margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .modifiedButton {
          font-size: 1.875rem;
          width: 100%;
        }
      }
      .image-container {
        img {
          height: 70px;
          padding: 4px;
        }
        .blue-image {
          border: 1px solid #00e1d4;
        }
        .orange-image {
          border: 1px solid #ff8d24;
        }
      }
    }
  }

  .text-gray {
    color: #70878f;
  }
  .text-white {
    color: white;
  }
  .text-red {
    color: #ff5353;
  }
  .text-orange {
    color: #ff8d24;
  }
  .text-blue {
    color: #00e1d4;
  }
  .text-green {
    color: green;
  }
}

.quickWarStart {
  .react-countdown-simple {
    color: red !important;
    font-size: 12px;
  }
  .react-countdown-simple__date-time {
    font-weight: 400;
    font-size: 12px;
    span {
      min-width: 10px !important;
    }
  }
  .react-countdown-simple__colon {
    padding: 0px 3px;
  }
}

.no-war-available{
  
}