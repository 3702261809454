.sideDrawerWrapper {
  display: flex;
  overflow-x: hidden;
  transition: transform 1s;
  position: fixed;
  z-index: 9999;
  top: 0;
  // left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  .drawer_overlay {
    width: 75%;
    height: 100%;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
  }
  .drawer {
    width: 450px;
    height: 100%;
    background: rgba(10, 14, 16, 0.98);
    z-index: 999;
    // max-height: 615px;
    .overlay-log-top {
      position: fixed;
      background: linear-gradient(
        0deg,
        #0a0f11 0%,
        rgba(10, 15, 17, 0.9) 37.4%,
        rgba(10, 14, 16, 0) 100%
      );
      width: 100%;
      height: 130px;
      top: 65px;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
    .overlay-log-bottom {
      position: fixed;
      background: linear-gradient(
        0deg,
        #0a0f11 0%,
        rgba(10, 15, 17, 0.9) 37.4%,
        rgba(10, 14, 16, 0) 100%
      );
      width: 100%;
      height: 170px;
      bottom: 0;
      //   transform: matrix(1, 0, 0, -1, 0, 0);
    }
    .drawer_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      border-bottom: 1px solid var(--primary-color);
      // border-width: 1px;
      // border-style: solid;
      // border-image: radial-gradient(
      //     circle at right bottom,
      //     #e3ca6e5c,
      //     #856f1f00
      //   )
      //   10;
      .title {
        font-family: "Big Shoulders Display";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        background: linear-gradient(180deg, #856f1f 0%, #e3c96e 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
    .drawer_body {
      text-align: center;
      color: white;
      overflow-y: auto;
      scroll-behavior: smooth;
      overflow-x: hidden;
      z-index: 9999;
      height: calc(100vh - 90px) !important;
      &::-webkit-scrollbar {
        width: 8px !important;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: #000 !important;
        border: 1px solid rgb(190, 188, 188);
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgb(190, 188, 188) !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
      .fightScreen {
        padding: 0px 20px;
        display: flex;
        // margin-top: 20px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid rgba(128, 128, 128, 0.699);
        &:first-child {
          margin-top: 80px;
        }
        &:last-child {
          margin-bottom: 170px;
          border-bottom: none;
        }
      }
      .image-container {
        img {
          height: 70px;
          padding: 4px;
        }
        .blue-image {
          border: 1px solid #00e1d4;
        }
        .orange-image {
          border: 1px solid #ff8d24;
        }
      }
    }
  }
}
