.war-page-wrapper {
    --yellow-color: #e3c96e;
    --grey-color: #70878f;
    --light-grey-color: #9aa8ac;
    --green-color: #6ee381;
    --proximaNova: "Proxima Nova";
    --font-14: normal 400 14px/20px var(--proximaNova);

    // padding: 0 24px;
    background-color: black;
    // min-width: 1400px;
    .text-yellow {
        color: var(--yellow-color) !important;
    }

    .text-green {
        color: var(--green-color) !important;
    }

    .text-red {
        color: #ff5353;
    }

    .green-gradient-bg {
        background: linear-gradient(180deg, rgba(10, 14, 16, 0) 0%, rgba(34, 114, 47, 0.3) 100%);
    }

    .red-gradient-bg {
        background: linear-gradient(180deg, rgba(10, 14, 16, 0) 0%, rgba(136, 44, 44, 0.5) 100%);
    }

    .war-tabs-wrapper {
        margin: 20px 0;

        .war-nav-tabs {
            display: flex;
            flex-direction: row;
            text-transform: uppercase;
            color: #fff;
            align-items: flex-start;
            //   padding: 16px 24px 0px;

            .war-nav-tab {
                cursor: pointer;
                font: normal 700 32px/48px "Big Shoulders Display";
                padding: 16px 16px 4px;

                &.active-tab {
                    background: linear-gradient(180deg, rgba(10, 14, 16, 0) 0%, #6c5c3c 100%);
                }
            }
        }

        .tab-content-wrapper {
            border-width: 1px 1px 0px 1px;
            border-style: solid;
            border-image: linear-gradient(
                    180deg,
                    var(--yellow-color) 0%,
                    rgba(227, 201, 110, 0) 100%
                )
                1;
            padding: 16px 24px 0px;
            color: #fff;

            // min-width: 1392px;

            .filter-section {
                .dropdown-wrapper {
                    gap: 16px;
                }

                .dropdown-title-wrapper {
                    color: #fff;
                    gap: 8px;
                    border: 1px solid grey;
                    padding: 4px 10px;
                    font: var(--font-14);

                    .filter-title {
                        gap: 4px;
                        .filter-text {
                            font-weight: 700;
                        }
                    }
                }

                .filter-menu {
                    box-shadow: 0px 0px 16px rgba(10, 14, 16, 0.75);
                    background: black;
                    position: absolute;
                    color: #fff;
                    min-width: 148px;
                    max-width: 179px;
                    z-index: 3;
                    padding: 12px 0px;
                    border: 1px solid grey;
                    top: 100%;

                    p {
                        cursor: pointer;
                        font: var(--font-14);
                        color: var(--grey-color);
                        padding: 10px 16px;
                    }

                    .filter-selected {
                        font-weight: 700;
                        color: var(--yellow-color);
                    }
                }

                .war-join-filter {
                    color: #fff;
                    font: var(--font-14);
                }

                .sort-dropdown {
                    .filter-menu {
                        right: 0;
                        width: 179px;
                    }
                }

                .radio-label {
                    color: #fff;
                    font: var(--font-14);
                    cursor: pointer;

                    .radio-input {
                        display: none;
                        &:checked {
                            + span {
                                border: 1px solid var(--yellow-color);
                                border-radius: 50%;
                                transform: rotate(90deg);

                                &::after {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    .custom-radio {
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        border: 2px solid var(--grey-color);
                        border-radius: 50%;
                        display: inline-block;
                        position: relative;

                        &::after {
                            content: "";
                            width: 12px;
                            height: 12px;
                            background: var(--yellow-color);
                            position: absolute;
                            border-radius: 50%;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            opacity: 0;
                            transition: opacity 200;
                        }
                    }
                }

                .clear-filter {
                    font: var(--font-14);
                }
            }

            .wars-table-wrapper {
                width: 100%;
                padding: 32px 0;

                .wars-table {
                    width: inherit;

                    .start-time-block {
                        min-width: 140px;
                        // width: 16%;
                    }

                    .duration-block {
                        min-width: 97px;
                        width: 7%;
                        font-size: 16px;
                    }

                    .wars-table-header {
                        border-bottom: 1px solid var(--grey-color);
                        text-transform: uppercase;
                        font: var(--font-14);
                        color: var(--grey-color);

                        tr {
                            padding: 0 12px 8px;
                            // gap: 16px;

                            td {
                                padding: 4px 0;
                            }
                        }
                    }
                }

                .war-items {
                    // padding-top: 32px;
                    display: block;
                }
            }
        }
    }
}
.duraammoWarPage {
    width: 60px;
    height: 24px;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(2px);
    padding: 0px 6px;
    .duraammoPresent {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
    }
    .duraammoInitial {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;

        color: #ffffff;
    }
}
.watchSeparator {
    border-left: 2px solid #70878f;
    height: 15px;
    margin: 0 3px;
    padding: 0;
}
.war-item {
    font: normal 400 20px/24px var(--proximaNova);
    // gap: 16px;
    color: var(--grey-color);
    padding: 24px 5px 0px;
    border-bottom: 1px solid #485c65;

    .rewards-block {
        // gap: 6px;

        .reward {
            width: 75px;
            height: 75px;
            background: linear-gradient(180deg, rgba(10, 14, 16, 0) 0%, #0a0e10 100%);
            border: 1px solid var(--grey-color);
            position: relative;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .reward-number {
                position: absolute;
                bottom: 2px;
                right: 4px;
                font: normal 600 12px/16px var(--proximaNova);
                color: #fff;
            }
        }
    }
}
.showWinners {
    /* Hide the default checkbox */
    input[type="checkbox"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 20px;
        height: 20px;
        border: 2px solid #333;
        border-radius: 4px;
    }

    /* Style the checkbox when it is checked */
    input[type="checkbox"]:checked {
        background-color: #333;
    }

    /* Style the custom checkbox */
    input[type="checkbox"]::before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        background-color: #fff;
        border-radius: 2px;
        margin-right: 5px;
    }

    /* Style the custom checkbox when it is checked */
    input[type="checkbox"]:checked::before {
        background-color: #ffc600;
        content: "✓";
        text-align: center;
        color: #333;
    }
}
//upcomming wars
.toggleUpcomingWar {
    input[type="checkbox"] {
        visibility: hidden;
        &:checked + label {
            transform: rotate(360deg);
            background-color: #e3c96e;
            &:before {
                transform: translateX(90px);
                background-color: #fff;
            }
        }
    }

    label {
        display: flex;
        width: 180px;
        height: 90px;
        border: 6px solid;
        border-radius: 99em;
        position: relative;
        transition: transform 0.75s ease-in-out;
        transform-origin: 50% 50%;
        cursor: pointer;

        &:before {
            transition: transform 0.75s ease;
            transition-delay: 0.5s;
            content: "";
            display: block;
            position: absolute;
            width: 54px;
            height: 54px;
            background-color: #000;
            border-radius: 50%;
            top: 12px;
            left: 12px;
        }
    }
}
.availableToJoin {
    .react-countdown-simple {
        color: #fff !important;
        font-size: 16px;
    }
    .react-countdown-simple__date-time {
        font-weight: 600;
        font-size: 16px;
        span {
            min-width: 10px !important;
        }
    }
    .react-countdown-simple__colon {
        padding: 0px 3px;
    }
}

.toggleCommingWar {
    .checkbox {
        display: none;
    }

    .slider {
        width: 45px;
        height: 20px;
        background-color: rgba(211, 211, 211, 0.406);
        // border-radius: 20px;
        overflow: hidden;
        display: flex;
        align-items: center;
        border: 3px solid transparent;
        transition: 0.3s;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25) inset;
        cursor: pointer;
    }

    .slider::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: #fff;
        transform: translateX(-30px);
        // border-radius: 10px;
        transition: 0.3s;
        box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.25);
    }

    .checkbox:checked ~ .slider::before {
        transform: translateX(30px);
        box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.25);
    }

    .checkbox:checked ~ .slider {
        background-color: #e3c96e;
    }

    .checkbox:active ~ .slider::before {
        transform: translate(0);
    }
}
