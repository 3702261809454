.wallet-connect-options-wrapper {
  font-family: proxima-nova, sans-serif;
  width: 300px;
  .wallet-connect-options {
    display: flex;
    justify-content: space-around;
    color: #e0e3eb;
    .connect-icon-div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      .connect-icon {
        width: 70px;
      }
    }
    .connect-or-line {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;
      .or-seperator {
        height: 35px;
        width: 2px;
        background-color: #e0e3eb;
      }
    }
    .connect-ledger-div {
      display: flex;
      gap: 10px;
      cursor: pointer;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .connect-ledger {
        filter: invert(1);
      }
    }
  }
  .connect-revert-btn {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
