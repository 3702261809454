$top-rank-colors: (
    1: linear-gradient(90deg, #856f1f 0%, #e3c96e 120.52%),
    2: linear-gradient(90deg, #383838 0%, #dbdbdb 120.52%),
    3: linear-gradient(90deg, #85441f 0%, #e3a26e 120.52%),
);

$table-row-height: 60px;

$breakpoints: (
    tablet: 768px,
    mobile: 400px,
    laptop: 1024px,
    desktop: 1368px,
);

.leaderboard-container {
    padding: 20px 30px;
    width: 90%;
    max-width: 1800px;
    min-width: 1400px;
    margin-top: 20px;
}
#leaderboard-table {
    width: 100%;
    min-width: 500px;
    overflow: auto;
    thead {
        border-bottom: 1px solid #485c65;
    }

    .user-row {
        background-color: rgba(189, 195, 200, 0.2) !important;
        td:nth-child(1) {
            &:before {
                position: absolute;
                height: $table-row-height;
                width: 10px;
                content: "";
                background-color: #97a7aa;
            }
        }
    }

    tr {
        height: $table-row-height;
        &:nth-child(2n + 1) {
            background: rgba(50, 50, 50, 0.2);
        }
        td {
            text-align: left;
            padding: 0px;
            padding-left: 10px;
            font-family: "Proxima Nova";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 32px;
            color: #ffffff;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
            &:nth-child(2n + 1) {
                background: rgba(50, 50, 50, 0.2);
            }
            &:not(:nth-child(1)):not(:nth-child(2)) {
                text-align: center;
            }
        }
        .lb-rank {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 110px;
            max-width: 120px;
            height: $table-row-height;
            font-size: 24px;
            .rank-number {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: -webkit-fill-available;
                margin-left: 20px;
                gap: 20px;
            }
        }
        .lb-gbet-img-container {
            margin-left: 4px;
            max-height: $table-row-height;
            overflow: hidden;
            > a {
                transition: color 0.4s ease-out;
                &:hover {
                    color: #b09b3c;
                }
            }
        }
        .lb-gbet-img {
            height: 100%;
            width: 75px;
            display: inline;
        }
        .lb-gbet-name {
            line-height: 24px;
            top: 50%;
            transform: translate(0, -50%);
            position: absolute;
            margin-left: 20px;
            @media (max-width: map-get($breakpoints, laptop)) {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 60%;
            }
        }
        .lb-rank-rewards {
            svg {
                display: inline;
                margin: 0 10px 0 10px;
            }
            min-width: 100px;
            font-weight: 600;
            font-size: 14px;
            text-align: right;
            margin-right: 10vw;
            color: #70878f;
            @media (max-width: map-get($breakpoints, desktop)) {
                margin-right: 0;
            }
        }
        @for $i from 1 through 3 {
            .rank-#{$i} {
                position: relative;
                background: map-get($top-rank-colors, $i);
                span:nth-child(2) {
                    width: 90px;
                    height: 100%;
                    background: map-get($top-rank-colors, $i);
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    img {
                        max-height: 20px;
                        width: auto;
                    }
                }
            }
        }
    }

    th {
        padding-top: 15px;
        padding-left: 15px;
        line-height: 3;
        text-align: left;
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-transform: uppercase;
        color: #70878f;
        &:nth-child(3) {
            background: rgba(50, 50, 50, 0.2);
        }
        &:not(:nth-child(1)):not(:nth-child(2)) {
            text-align: center;
        }
    }
}

.performance-indicator {
    background: rgba(10, 14, 16, 0.98);
    opacity: 0.8;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 9px;
    }
    .triangle-up {
        color: #6ee381;
    }
    .triangle-down {
        color: #f76e6e;
        transform: rotate(180deg);
    }
}
.interval-selector {
    position: relative;
    span {
        position: relative;
        cursor: pointer;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        width: 115px;
        height: 48px;
        font-family: "Big Shoulders Display";
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.05em;
        &:before {
            position: absolute;
            content: "";
            height: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: radial-gradient(
                80.62% 114.47% at 50.43% 0%,
                rgba(10, 14, 16, 0) 38.02%,
                #ffc800c0 100%
            );
            z-index: -1;
            transition: opacity 0.5s ease, height 0.5s ease;
            opacity: 0;
        }
        &:hover:before {
            opacity: 1;
            height: 100%;
        }
    }
    .selected-interval {
        background: linear-gradient(180deg, rgba(10, 14, 16, 0) 0%, rgba(255, 198, 0, 0.5) 100%);
    }
}
.leaderboard-inner-container {
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-image: linear-gradient(180deg, #e3c96e 0%, #2c2818 104.63%) 100;
    .leaderboard-table-container {
        padding: 0 24px 24px 24px;
    }
    .stats-selector,
    .stats-selector-np {
        background: rgba(28, 36, 42, 0.2);
        padding-top: 16px;
        .stats-tabs {
            border-bottom: 1px solid #1c242a;
            span {
                cursor: pointer;
                display: table-cell;
                padding: 6px 8px 6px 8px;
                color: #485c65;
                font-family: "Proxima Nova";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                transition: border, color 0.4s ease;
                &:hover {
                    color: #e3c96e;
                }
            }
            .selected-filter {
                color: #e3c96e;
                border-bottom: 4px solid #ae963e;
            }
            .right-section {
                button {
                    color: #fff !important;
                    width: 230px;
                    height: 36px;
                    background: #26343d;
                    display: inline-flex;
                    align-items: center;
                    justify-content: space-around;
                    padding: 8px 12px;
                    font-size: 14px;
                    transition: transform 0.5s ease, box-shadow 0.5s ease;
                    outline: none;
                    span {
                        color: #fff !important;
                        font-weight: 400;
                    }
                    &:hover {
                        box-shadow: 1px 1px 4px 0px #f0c101;
                    }
                }
            }
        }
    }

    .stats-selector {
        padding: 0px 24px 0px 24px;
    }

    .stats-selector-np {
        padding: 0px;
    }

    .stats-sec-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
        .left-section {
            display: inline-flex;
            align-items: center;
            gap: 20px;
            font-weight: 600;
            > span {
                display: block;
                width: 100%;
                &:nth-child(1) {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    position: relative;
                }
                &:nth-child(2) {
                    width: 109px;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        .right-section {
            button {
                color: #fff;
                width: 218px;
                height: 36px;
                background: #26343d;
                display: inline-flex;
                align-items: center;
                justify-content: space-around;
                padding: 8px 16px;
                font-size: 14px;
                transition: transform 0.5s ease, box-shadow 0.5s ease;
                &:hover {
                    box-shadow: 1px 1px 4px 0px #f0c101;
                    transform: translateY(-1px);
                }
            }
        }
    }
}
.popup-overlay {
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(6px);
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .close-icon {
        z-index: 1;
        position: absolute;
        right: -13px;
        top: -13px;
        background: black;
        border: 2px solid #e3c96e;
        transform: rotate(45deg);
        padding: 4px;
        cursor: pointer;
        svg {
            transform: rotate(-45deg);
        }
    }
    .popup-container {
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        min-height: 430px;
        padding: 30px;
        background: #000;
        border: 1px solid rgba(10, 14, 16, 0.1);
        -webkit-clip-path: polygon(
            1em 0,
            calc(100% - 1em) 0,
            100% 1em,
            100% calc(100% - 1em),
            calc(100% - 1em) 100%,
            1em 100%,
            0 calc(100% - 1em),
            0 1em
        );
        clip-path: polygon(
            1em 0,
            calc(100% - 1em) 0,
            100% 1em,
            100% calc(100% - 1em),
            calc(100% - 1em) 100%,
            1em 100%,
            0 calc(100% - 1em),
            0 1em
        );
        &:before {
            background: gray;
            content: "";
            inset: 0;
            position: absolute;
            z-index: -2;
        }
        &:after {
            background: rgba(0, 0, 0, 0.91);
            background-repeat: no-repeat;
            background-size: cover;
            -webkit-clip-path: polygon(
                1em 0.15em,
                calc(100% - 1em) 0.15em,
                calc(100% - 0.15em) 1em,
                calc(100% - 0.15em) calc(100% - 1em),
                calc(100% - 1em) calc(100% - 0.15em),
                1em calc(100% - 0.15em),
                0.15em calc(100% - 1em),
                0.15em 1em
            );
            clip-path: polygon(
                1em 0.15em,
                calc(100% - 1em) 0.15em,
                calc(100% - 0.15em) 1em,
                calc(100% - 0.15em) calc(100% - 1em),
                calc(100% - 1em) calc(100% - 0.15em),
                1em calc(100% - 0.15em),
                0.15em calc(100% - 1em),
                0.15em 1em
            );
            content: "";
            inset: 0;
            position: absolute;
            z-index: -1;
        }
        h5 {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #70878f;
        }
        h3 {
            font-weight: 700;
            font-size: 40px;
            line-height: 48px;
            font-family: "Big Shoulders Display";
            color: #ffffff;
        }
        p {
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #70878f;
        }
    }
    .popup-small {
        padding: 27px 100px;
        @media (min-width: map-get($breakpoints, tablet)) {
            width: 611px;
        }
        @media (max-width: map-get($breakpoints, tablet)) {
            width: 80vw;
        }
        .reward-crate {
            img {
                height: 176px;
                width: 176px;
            }
        }
    }
    .popup-medium {
        justify-content: space-evenly;
        max-height: 90vh;
        overflow-y: auto;
        .past-lb-table-container {
            width: 100%;
            padding: 10px 25px;
            overflow-x: auto;
            text-align: center;
            text-align: -webkit-center;
            text-align: -moz-center;
        }
        @media (min-width: map-get($breakpoints, tablet)) {
            width: 80vw;
            max-width: 1000px;
            #leaderboard-table {
                min-width: 636px;
            }
        }
        @media (max-width: map-get($breakpoints, tablet)) {
            width: 95vw;
            #leaderboard-table {
                min-width: 636px;
            }
        }
        .last-winners-header {
            .navigators {
                display: flex;
                color: #fff;
                svg {
                    transition: transform 0.2s linear;
                    cursor: pointer;
                    margin: 0px 5px;
                    &:hover {
                        transform: translate(1px, 1px);
                    }
                }
            }
            .navigators-disabled {
                color: #000;
                pointer-events: none;
            }
            > div:nth-child(2) {
                @media (max-width: 600px) {
                    max-width: 90vw;
                }
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 500px;
            }
        }
    }
}
.empty-leaderboard {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: #485c65;
    > p {
        text-align: center;
    }
    .trophy-img-container {
        > div {
            width: 111px;
            height: 143px;
            .nftInnerWrapper {
                padding: 14px;
            }
        }
        img {
            filter: saturate(0.3) sepia(1) hue-rotate(190deg);
        }
    }
    h4 {
        font-family: "Big Shoulders Display";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        text-transform: uppercase;
        color: #fff;
    }
    p {
        letter-spacing: 0.03em;
        width: 80%;
        max-width: 650px;
    }
    button {
        color: #fff;
        font-family: "Big Shoulders Display";
        font-style: normal;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 20px;
        margin-top: 24px;
        background: url("../../images/leaderboard/btnBg.svg");
        background-repeat: no-repeat;
        height: 44px;
        width: 180px;
    }
}
.stats-selector,
.stats-selector-np {
    background: rgba(28, 36, 42, 0.2);
    padding-top: 16px;
    .stats-tabs {
        border-bottom: 1px solid #1c242a;
        span {
            cursor: pointer;
            display: table-cell;
            padding: 6px 8px 6px 8px;
            color: #485c65;
            font-family: "Proxima Nova";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            transition: border, color 0.4s ease;
            &:hover {
                color: #e3c96e;
            }
        }
        .selected-filter {
            color: #e3c96e;
            border-bottom: 4px solid #ae963e;
        }
        .right-section {
            button {
                color: #fff !important;
                width: 230px;
                height: 36px;
                background: #26343d;
                display: inline-flex;
                align-items: center;
                justify-content: space-around;
                padding: 8px 12px;
                font-size: 14px;
                transition: transform 0.5s ease, box-shadow 0.5s ease;
                outline: none;
                span {
                    color: #fff !important;
                    font-weight: 400;
                }
                &:hover {
                    box-shadow: 1px 1px 4px 0px #f0c101;
                }
            }
        }
    }
}
