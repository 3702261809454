.crownLimitWrapper {
  font-family: "Proxima Nova";
  padding: 7px;
  display: flex;
  flex-direction: column;
  //   gap: 5px;
  align-items: center;

  .playerNumber {
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
  }
  .crownLimitText {
    width: 100%;
    margin: auto;
    .crownLimitTopText {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      padding: 7px;
      text-align: center;

      color: #ffffff;
    }
    .crownLimitInfo {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      padding: 7px;
      text-align: center;

      color: #ffffff;
    }
  }
}
