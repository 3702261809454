.postGame {
  position: relative;
  .winning {
    filter: blur(8px);
  }
  .bg_feather {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      margin: auto;
      opacity: 0.3;
    }
  }
  .victoryScreen {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-top: 1px solid var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
    background: hsla(177, 100%, 44%, 1);
    background: radial-gradient(
      circle,
      rgba(0, 224, 213, 0.781) 0%,
      rgba(0, 224, 213, 0.788) 0%,
      hsla(206, 20%, 14%, 1) 100%
    );

    background: -moz-radial-gradient(
      circle,
      rgba(0, 224, 213, 0.788) 0%,
      rgba(0, 224, 213, 0.781) 0%,
      hsla(206, 20%, 14%, 1) 100%
    );

    background: -webkit-radial-gradient(
      circle,
      hsla(177, 100%, 44%, 0.781) 0%,
      hsla(177, 100%, 44%, 0.781) 0%,
      hsla(206, 20%, 14%, 1) 100%
    );

    p {
      font-size: 88px;
      font-family: var(--bigShoulders);
      font-weight: 700;
      color: white;
      width: 100%;
      text-align: center;
    }
  }
}

.scoreScreen {
  h1 {
    font-size: 38px;
    font-family: var(--bigShoulders);
  }
  .time {
    font-size: 18px;
    font-family: var(--manrope);
  }
}

.progressbar {
  div {
    background: linear-gradient(270deg, #6ee381 -20.38%, #1e5628 100%);
  }
}
.victory {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
  padding-top: 30px;
  background: linear-gradient(
    90deg,
    rgba(0, 225, 212, 0.42) 2.33%,
    rgba(28, 36, 42, 0) 100%
  );
  border: 2px solid;
  border-image: radial-gradient(circle at center, #6cfff7, rgba(28, 36, 42, 0))
    10;
  border-left: 0;
  border-right: 0;
  @media only screen and (max-width: 1550px) {
    padding-right: 0px !important;
  }

  .custom-height {
    height: 170px !important;
  }
  .reward {
    color: white;
    font-size: 48px;
    font-family: var(--bigShoulders);
    position: absolute;
    top: -15px;
    right: 50%;
    transform: translateX(50%);
    z-index: 9;
    line-height: 36px;
  }
  .opened {
    color: white;
    font-size: 28px;
    font-family: var(--bigShoulders);
    position: absolute;
    bottom: -20px;
    right: 50%;
    transform: translateX(50%);
    z-index: 9;
    line-height: 36px;
  }
  .primary-button1 {
    --background-color: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
    --border-color: linear-gradient(90deg, #856f1f 0%, #e3c96e 166.67%);
    --border-width: 0.1em;
    --edge-size: 0.5em;
    cursor: pointer;
    background: var(--background-color);
    padding: 0em 1.2em;
    border: 0;
    width: 130px;
    color: #ffffff;
    text-transform: uppercase;
    font-family: var(--bigShoulders);
    position: relative;
    isolation: isolate;
    display: inline-grid;
    place-content: center;
    font-weight: 700;
    font-size: 20px;
    clip-path: polygon(
      var(--edge-size) 0%,
      calc(100% - var(--edge-size)) 0%,
      100% var(--edge-size),
      100% calc(100% - var(--edge-size)),
      calc(100% - var(--edge-size)) 100%,
      var(--edge-size) 100%,
      0% calc(100% - var(--edge-size)),
      0% var(--edge-size)
    );
    transition: color 250ms;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background: var(--border-color);
      z-index: -2;
    }
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: var(--background-color);
      z-index: -1;
      clip-path: polygon(
        var(--edge-size) calc(var(--border-width)),
        calc((100% - var(--edge-size))) calc(var(--border-width)),
        calc(100% - var(--border-width)) var(--edge-size),
        calc(100% - var(--border-width)) calc(100% - var(--edge-size)),
        calc(100% - var(--edge-size)) calc(100% - var(--border-width)),
        var(--edge-size) calc(100% - var(--border-width)),
        calc(var(--border-width)) calc(100% - var(--edge-size)),
        calc(var(--border-width)) var(--edge-size)
      );
    }
    &:disabled {
      background: linear-gradient(90deg, #2d3a41 0%, #485c65d2 166.67%);
      color: #485c65;
      &::before {
        background: #485c65;
      }
    }
  }
}
.defeat {
  background: linear-gradient(
    90deg,
    rgba(225, 0, 0, 0.42) 2.33%,
    rgba(28, 36, 42, 0) 100%
  );
  border: 2px solid;
  border-image: radial-gradient(circle at center, #ff2626, rgba(28, 36, 42, 0))
    10;
  border-left: 0;
  border-right: 0;
  @media only screen and (max-width: 1550px) {
    padding-right: 0px !important;
  }
  .yourGang {
    color: #f76e6e;
  }
}

.gangB {
  background: linear-gradient(
    90deg,
    rgba(225, 109, 0, 0.42) 2.33%,
    rgba(28, 36, 42, 0) 100%
  );
  border: 2px solid;
  border-image: radial-gradient(circle at center, #ffb36c, rgba(28, 36, 42, 0))
    10;
  border-left: 0;
  border-right: 0;
  @media only screen and (max-width: 1550px) {
    padding-right: 0px !important;
  }
  .gangBText {
    color: #ff8d24;
  }
}

.tableA {
  width: 100%;
  .tableHeader {
    th {
      color: #485c65;
      margin-bottom: 10px;
    }
  }
  .tableBody {
    border: 1px solid #0e827f;
    td {
      vertical-align: middle;
    }
    &:hover {
      background: linear-gradient(
        90deg,
        rgba(0, 225, 212, 0.42) 2.33%,
        rgba(28, 36, 42, 0) 100%
      );
    }
    p,
    span {
      width: 100%;
      height: 100%;
      font-size: 18px;
      font-weight: 500;
      color: white;
    }
    .health {
      height: 56px;
      // position: relative;
      min-width: 100px;
      max-width: 120px;
      background: transparent;
      div {
        font-size: 18px;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: white;
        text-align: center;
        height: 100%;
        background: linear-gradient(270deg, #6ee381 -20.38%, #1e5628 100%);
      }
    }
  }
}
.tableB {
  width: 100%;
  .tableBody {
    border: 1px solid rgba(255, 141, 36, 0.5);
    &:hover {
      background: linear-gradient(
        90deg,
        rgba(255, 141, 36, 0.2) 2.33%,
        rgba(28, 36, 42, 0) 100%
      );
    }
    p,
    span {
      width: 100%;
      height: 100%;
      font-size: 18px;
      font-weight: 500;
      color: white;
    }
    .health {
      height: 56px;
      // position: relative;
      min-width: 100px;
      max-width: 120px;
      background: transparent;
      div {
        font-size: 18px;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: white;
        text-align: center;
        height: 100%;
        background: linear-gradient(270deg, #6ee381 -20.38%, #1e5628 100%);
      }
    }
  }
}
