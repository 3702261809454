.closePrimary {
  background-color: #e3c96e;
}
.closeOutline {
  border: 2px solid #e3c96e;
  background-color: rgb(66, 66, 44);
}

.close-button {
  transform: rotate(45deg);
  .close-icon {
    transform: rotate(180deg) !important;
  }
}
