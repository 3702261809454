.gameLobbyWrapper {
  background: rgba(10, 14, 16, 0.98);
  padding: 10px;
  .userLoadoutForGame {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 650px;
    .lobbyFirstCol {
      display: flex;
      flex-direction: column;
      gap: 11px;
      .participatedLoadoutForGame {
        width: 448px;
        height: 447px;
        background: rgba(10, 14, 16, 0.98);
        border: 1px solid #485c65;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(4px);
        padding: 20px 16px;
        .participantedLoadout {
          // width: 448px;
          // height: 140px;
          background: rgba(10, 14, 16, 0.98);
          display: flex;
          gap: 10px;
          .gbDetails {
            .gbName {
              font-family: "Proxima Nova";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              color: #ffffff;
            }
            .isGangster,
            .gbType {
              font-family: "Proxima Nova";
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              color: #70878f;
            }
          }
        }
      }
    }
    .lobbySecondCol,
    .lobbyThirdCol {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
  .takenLoadouts {
    width: 32px;
    height: 32px;
  }
}
.participatedLoadoutForGame {
  width: 448px;
  height: 429px;
  background: rgba(10, 14, 16, 0.98);
  border: 1px solid #485c65;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
  padding: 20px 16px;
  .participantedLoadout {
    // width: 448px;
    // height: 140px;
    background: rgba(10, 14, 16, 0.98);
    display: flex;
    gap: 10px;
    .gbDetails {
      .gbName {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
      }
      .isGangster,
      .gbType {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #70878f;
      }
    }
  }
}
.userloadout {
  border-image-slice: 27 27 27 27;
  border-image-width: 20px 20px 20px 20px;
  border-image-outset: 0px 0px 0px 0px;
  border-image-repeat: stretch stretch;
  border-image-source: url("../../../images/components/loadoutBorder.png");
  border-style: solid;
}
.changeSized {
  min-width: 1378px !important;
  min-height: 700px !important;
}

.tooltipWeaponsLoadout {
  background-color: #111111;
  width: 300px !important;
  // height: 350px;
  opacity: 1 !important;
  z-index: 99;
  border: 1px solid #485c65;
  .tooltipLoadout {
    width: 80px;
    height: 80px;
  }
  .weaponsDetails {
    .tooltipLoadoutName {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
    .weaponsInnerDetails {
      .weaponsInnerDetails {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  }

  .loadoutDescription {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
  .weaponsStats {
    .statsKeys {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #70878f;
    }
    .statsValue {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
    }
  }
  .react-tooltip-arrow {
    // border-right: 1px solid #485c65 !important;
    // border-bottom: 1px solid #485c65 !important;
    // border-top: 1px solid #485c65 !important;
    // border-left: 1px solid #485c65 !important;
  }
}

//participated details
.otherParticipantLoadoutForGame {
  padding: 20px 16px;
  width: 448px;
  height: 140px;
  background: rgba(10, 14, 16, 0.98);
  border: 1px solid #485c65;
  display: flex;
  gap: 10px;
  .gbDetails {
    .gbName {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
    }
    .isGangster,
    .gbType {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #70878f;
    }
  }
}

.tooltipSneakMoveLoadout {
  background-color: #111111;
  width: 300px !important;
  // height: 200px;
  opacity: 1;
  z-index: 99999999;
  border: 1px solid #485c65;
  .tooltipLoadout {
    width: 80px;
    height: 80px;
  }
  .weaponsDetails {
    .tooltipLoadoutName {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
    .weaponsInnerDetails {
      .weaponsInnerDetails {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  }
  .isActive {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.02em;
    color: #ffffff;
    border: 1px solid #485c65;
    background-color: #111111;
    padding: 5px 10px;
  }
  .horizontalSeperator {
    border-color: #485c65;
  }
  .loadoutDescription {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
  .react-tooltip-arrow {
    // border-right: 1px solid #485c65 !important;
    // border-bottom: 1px solid #485c65 !important;
    // border-top: 1px solid #485c65 !important;
    // border-left: 1px solid #485c65 !important;
  }
}

.tooltipItemsLoadout {
  background-color: #111111;
  width: 315px !important;
  max-width: 320px;
  max-height: 288px;
  opacity: 1;
  z-index: 99;
  border: 1px solid #485c65;
  .tooltipLoadout {
    width: 80px;
    height: 65px;
  }
  .statsItems {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 16px;
    width: 285px;
    height: 52px;
    background: rgba(54, 55, 56, 0.2);
    .statsItemsName {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #70878f;
      .statsValue {
        color: #6ee381;
      }
    }
  }
  .itemsDetails {
    .tooltipLoadoutName {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
    .itemsInnerDetails {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      .itemTypeName {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        .itemType {
          color: #70878f;
        }
        .itemName {
          color: #ffffff;
        }
      }
      .targetBasic {
        width: 91px;
        height: 40px;
        background: rgba(10, 14, 16, 0.2);
      }
    }
  }

  .horizontalSeperator {
    border-color: #485c65;
  }
  .loadoutDescription {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
  .react-tooltip-arrow {
    // border-right: 1px solid #485c65 !important;
    // border-bottom: 1px solid #485c65 !important;
    // border-top: 1px solid #485c65 !important;
    // border-left: 1px solid #485c65 !important;
  }
}

.emptyParticipantLoadoutForGame {
  padding: 10px 16px;
  width: 448px;
  height: 140px;
  background: rgba(10, 14, 16, 0.98);
  border: 1px solid #485c65;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  .emptyStateLobby {
    font-family: "Proxima Nova";
    font-family: "Big Shoulders Display";
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 64px;

    color: #ffffff;

    opacity: 0.1;
  }
}

//modal

.detailsLoadoutModal {
  display: flex;
  gap: 20px;
  flex-direction: column;
  .otherParticipantLoadoutModal {
    width: 448px;
    display: flex;
    gap: 10px;
    .gbDetails {
      .gbName {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
      }
      .isGangster,
      .gbType {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #70878f;
      }
    }
  }
}
.skillsStats {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  .primarySkillsStats {
    width: 50%;
  }
  .secondarySkillsStats {
    width: 50%;
  }
  .statsSkillKeys {
    color: #70878f;
  }
  .statsSkillValues {
    color: #ffffff;
  }
}
.changeStatsModal {
  margin: 10px 10px;
}
.horizontalSeperator {
  border-color: #485c65;
}

.tooltipPlace_bottom {
  .react-tooltip-arrow {
    // border-right: 1px solid #485c65 !important;
    // border-bottom: 1px solid #485c65 !important;
    border-top: 1px solid #485c65 !important;
    border-left: 1px solid #485c65 !important;
  }
}

.tooltipPlace_top {
  .react-tooltip-arrow {
    border-right: 1px solid #485c65 !important;
    border-bottom: 1px solid #485c65 !important;
    // border-top: 1px solid #485c65 !important;
    // border-left: 1px solid #485c65 !important;
  }
}

.tooltipPlace_right {
  .react-tooltip-arrow {
    // border-right: 1px solid #485c65 !important;
    border-bottom: 1px solid #485c65 !important;
    // border-top: 1px solid #485c65 !important;
    border-left: 1px solid #485c65 !important;
  }
}

.tooltipPlace_left {
  .react-tooltip-arrow {
    border-right: 1px solid #485c65 !important;
    // border-bottom: 1px solid #485c65 !important;
    border-top: 1px solid #485c65 !important;
    // border-left: 1px solid #485c65 !important;
  }
}

//team formation modal
.teamFormation {
  .formationHead {
    height: 100px;
    background: rgba(38, 52, 61, 0.5);
    h2 {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #ffffff;
    }
    .react-countdown-simple {
      color: #ffffff !important;
      font-family: var(--bigShoulders) !important;
      font-size: 40px;
      font-weight: 700;
    }
    .react-countdown-simple__date-time {
      font-weight: 700;
      font-size: 40px;
      span {
        min-width: 10px !important;
      }
    }
    .react-countdown-simple__colon {
      padding: 0px 3px;
    }
  }
  .teamSeparationWrapper {
    .teamFormationTeamA {
      .teamAText {
        background: rgba(38, 52, 61, 0.5);
        height: 52px;
        h2 {
          padding: 5px;
          font-family: "Big Shoulders Display";
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 36px;
          color: #00e1d4;
          text-align: center;
        }
      }
      border: 1px solid #485c65;
      width: 483.43px;
    }
    .teamFormationTeamB {
      .teamBText {
        background: rgba(38, 52, 61, 0.5);
        height: 52px;
        h2 {
          padding: 5px;
          font-family: "Big Shoulders Display";
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 36px;
          color: #ff8d24;
          text-align: center;
        }
      }
      border: 1px solid #485c65;
      width: 483.43px;
    }
  }
}


.team-formationBox {
  @media screen and (max-width: 768px) {
    // flex-direction: column;
    // overflow-y: scroll;
    // width: 100%;
  }
}