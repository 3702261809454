.war-screen {
  // height: 100vh;
  background-image: url(../../images/backgrounds/warscreenBg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  @include max-break-point(mobile-phone) {
    transform: rotate(90deg);
  }
  .battleScreen {
    position: relative;
    width: 100%;
    color: white;
    display: flex;
  }
  .jumpToLatestEvent {
    position: absolute;
    padding: 15px;
    top: 50px;
    right: 50%;
    transform: translateX(50%);
    background: linear-gradient(360deg, #856f1f -8.93%, #e3c96e 107.14%);
    opacity: 0.8;
    color: black;
    border-radius: 8px;
    font-weight: bold;
    z-index: 1340;
    animation: blink 2s ease-in-out infinite forwards;
    min-height: 50px;
    min-width: 150px;
    vertical-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @keyframes blink {
    0% {
      box-shadow: none;
    }
    50% {
      box-shadow: 0 1px 13px 2px #fff944;
    }
    100% {
      box-shadow: none;
    }
  }
  .dead-cover {
    position: absolute;
    z-index: 1;
    opacity: 0.5;
    top: -19px;
    scale: 0.8;
  }
  .battle-target-highlight {
    box-shadow: 3px 3px 6px var(--blood-red), -3px -3px 6px var(--blood-red),
      -3px 3px 6px var(--blood-red), 3px -3px 6px var(--blood-red);
  }
  .target-highlight-child {
    > div:first-child {
      box-shadow: 3px 3px 6px var(--blood-red), -3px -3px 6px var(--blood-red),
        -3px 3px 6px var(--blood-red), 3px -3px 6px var(--blood-red);
    }
  }
  .battle-main-highlight {
    box-shadow: 3px 3px 6px var(--war-flame-2), -3px -3px 6px var(--war-flame-2),
      -3px 3px 6px var(--war-flame-2), 3px -3px 6px var(--war-flame-2);
  }
  // box-shadow: inset 0 0 0 2000px rgba(0, 48, 82, 0.7);
  .left-section {
    width: 75%;
    height: 100%;
    position: relative;
    .top-section {
      height: calc(100vh - 30px);
      position: relative;
      .war-group {
        transition: transform 0.5s;
      }
      .topMiddleSection {
        .trailsContainer {
          // display: flex;
          // flex-direction: row;
          position: absolute;
          top: 80px;
          z-index: 1;
        }
        .trailsText {
          pointer-events: none;
          position: relative;
          width: 100%;
          height: 3rem;
          // line-height: 80px;
          color: white;
          font-size: 3rem;
          font-weight: 800;
          letter-spacing: -0.05em;
          will-change: transform, opacity;
          margin-left: 5px;
          margin-right: 5px;
          // overflow: hidden;
        }

        .trailsText > div {
          padding-right: 0.05em;
          // overflow: hidden;
        }
      }
      .topRightSection {
        z-index: 2;
        position: absolute;
        right: 20px;
        top: 55%;
        transform: translateY(-50%);
        .dead-cover {
          left: -70%;
        }
        .active-war-group {
          transform: translateX(-60px);
        }
        .war-attack-flame {
          position: relative;
          // box-shadow: 5px 5px 10px var(--war-flame-2),
          //   -5px -5px 10px var(--war-flame-2), -5px 5px 10px var(--war-flame-2),
          //   5px -5px 10px var(--war-flame-2);
          // // border: 5px solid var(--war-flame-2);
          // background: var(--war-flame-2);
        }
        .war-take-hit {
          position: relative;
          // box-shadow: 5px 5px 10px var(--blood-red),
          //   -5px -5px 10px var(--blood-red), -5px 5px 10px var(--blood-red),
          //   5px -5px 10px var(--blood-red);
          // // border: 5px solid var(--blood-red);
          // background: var(--blood-red);
        }
      }
      .topLeftSection {
        z-index: 2;
        position: absolute;
        left: 20px;
        top: 55%;
        transform: translateY(-50%);
        .dead-cover {
          right: -70%;
        }
        .active-war-group {
          transform: translateX(60px);
        }
        .war-attack-flame {
          position: relative;
          // border: 5px solid var(--war-flame-2);
          // background: var(--war-flame-2);
          // box-shadow: none;
          // box-shadow: 5px 5px 10px var(--war-flame-2),
          //   -5px -5px 10px var(--war-flame-2), -5px 5px 10px var(--war-flame-2),
          //   5px -5px 10px var(--war-flame-2);
        }
        .war-take-hit {
          position: relative;
          // border: 5px solid var(--blood-red);
          // background: var(--blood-red);
          // box-shadow: none;
          // box-shadow: 5px 5px 10px var(--blood-red),
          //   -5px -5px 10px var(--blood-red), -5px 5px 10px var(--blood-red),
          //   5px -5px 10px var(--blood-red);
        }
      }
      .topTopSection {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .volumeControllerWrapper {
          top: 40px;
          left: 50%;
          transform: translateX(-95%);
          z-index: 9999;
        }

        .blueProgress {
          // clip-path: polygon(0 0%, 100% 0%, 100% 36%, 8% 36%);
          transform: skewX(34deg);
          margin-right: -7px;
          width: 300px;
          background: #26343d;
          border: 4px solid #1c242a;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
          height: 32px;
          div {
            height: 23px;
            background: linear-gradient(270deg, #015c49 0.37%, #01e1d4 99.64%);
            transition: width ease-in 1s;
          }
        }
        .cycleWrapper {
          clip-path: polygon(0 0, 100% 0%, 79% 100%, 21% 100%);
          background: #485c65;
          width: 200px;
          height: 65px;
          z-index: 999;
          .cycleSection {
            clip-path: polygon(0 0, 100% 0%, 79% 100%, 21% 100%);
            height: 63px;
            width: 195px;
            background-color: black;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            text-align: center;
          }
        }
        .orangeProgress {
          // clip-path: polygon(0% 0%, 100% 0%, 92% 36%, 0% 36%);
          transform: rotate(180deg) skewX(-34deg);
          margin-left: -7px;
          width: 300px;
          background: #26343d;
          border: 4px solid #1c242a;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
          height: 32px;
          div {
            height: 23px;
            background: linear-gradient(90deg, #ed7303 0.24%, #802f02 100.24%);
            transition: width ease-in 1s;
          }
        }
      }
      .topBottomSection {
        width: 80%;
        margin: auto;
        position: absolute;
        bottom: -15px;
        left: 46%;
        transform: translateX(-50%);
        // img {
        //   border: 3px solid #22722f;
        //   border-radius: 50%;
        //   border-spacing: 10px;
        //   padding: 3px;
        //   background-color: black;
        // }
      }
    }
    .bottom-section {
      margin: auto;
      position: absolute;
      bottom: -34px;
      // left: 15%;
      left: -265px;

      height: 220px;
      background: rgba(0, 0, 0, 0.295);
      backdrop-filter: blur(60px);
      border: 1px solid var(--primary-color);
      display: flex;
      width: 630px;
      .section1,
      .section2 {
        border-right: 1px solid var(--primary-color);
      }
      .section1 {
        // width: 35%;
        min-width: 360px;
      }
      .section2 {
        // width: 30%;
        min-width: 270px;
        .section2-abilities {
          width: 260px;
          height: 70px;
        }
        .border-t {
          min-width: 270px;
          height: 92px;
        }
      }
      .section3 {
        ul li {
          padding-right: 4px;
          font-size: 16px;
          color: rgb(231, 231, 231);
          font-weight: 600;
          margin-top: 20px;
          margin-right: 10px;
          cursor: pointer;
        }

        ul li.active {
          color: white;
          border-bottom: 2px solid var(--primary-color);
        }
      }
    }
  }
  .right-section {
    width: 25%;
    height: 100%;
    background: #00000094;
    backdrop-filter: blur(60px);
    .heading {
      border-bottom: 1px solid gray;
    }
    .body {
      text-align: center;
      color: white;
      overflow-y: auto;
      overflow-x: hidden;
      scroll-behavior: smooth;
      z-index: 9999;
      height: calc(100vh - 0px) !important;
      position: relative;
      .logWrappers {
        position: relative;
      }
      .backtoTop {
        position: fixed;
        bottom: 106px;
        left: 36%;
        background: linear-gradient(360deg, #856f1f -8.93%, #e3c96e 107.14%);
        color: black;
        border-radius: 8px;
        font-weight: bold;
        z-index: 34;
      }

      &::-webkit-scrollbar {
        width: 8px !important;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: #000 !important;
        border: 1px solid rgb(190, 188, 188);
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgb(190, 188, 188) !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
      .fightScreen {
        // padding: 0px 20px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid rgba(128, 128, 128, 0.699);
        .active-war-event {
          animation: war-event-blink 3s infinite linear;
        }
        .single-war-event {
          max-width: 100%;
          margin: 0;
          margin-bottom: 0.75rem;
          padding: 0px 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
      .image-container {
        img {
          height: 70px;
          padding: 4px;
        }
        .blue-image {
          border: 1px solid #00e1d4;
        }
        .orange-image {
          border: 1px solid #ff8d24;
        }
      }
    }
    ul li {
      padding: 20px;
      font-family: var(--bigShoulders);
      font-size: 28px;
      color: gray;
      font-weight: bold;
      margin-top: 10px;
      margin-left: 10px;
      cursor: pointer;
    }

    ul li.active {
      color: var(--primary-color);
      border: 1px solid var(--primary-color);
      border-bottom: 0;
    }
  }
  .war-attack-special {
    all: initial;
    pointer-events: none;
    position: fixed;
    left: 0;
    height: auto;
    z-index: 444444;
  }
  .war-animated-card {
    transition: box-shadow 1s linear;
    .war-attack-center {
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0px;
      height: 100%;
      max-width: 100%;
      width: auto;
      max-width: initial;
      z-index: 444444;
    }
    .war-attack-left {
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 160%;
      left: 100%;
      width: auto;
      max-width: initial;
      z-index: 444444;
    }
    .war-attack-right {
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: scaleX(-1) translateY(-50%);
      right: 100%;
      height: 160%;
      width: auto;
      max-width: initial;
      z-index: 444444;
    }
    .war-victim-right {
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 120%;
      width: auto;
      max-width: initial;
      z-index: 444444;
    }
    .war-victim-left {
      pointer-events: none;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: scaleX(-1) translate(-50%, -50%);
      height: 120%;
      width: auto;
      max-width: initial;
      z-index: 444444;
    }
  }
  .war-hp-popup {
    &:after {
      content: attr(data-content);
      position: absolute;
      top: -30px;
      left: 0;
      display: block;
      padding: 4px;
      width: 100%;
      font-size: 9px;
      text-align: center;
      font-weight: 600;
      animation: floating-hp-damage 3s ease-in forwards;
      z-index: 99999;
      left: 50%;
      color: #fff;
    }

    @keyframes floating-hp-damage {
      0% {
        opacity: 1;
        transform: translate(-50%, 0);
      }
      100% {
        opacity: 0;
        transform: translate(-50%, -30px);
      }
    }
  }
  .passive-hp-text {
    &:after {
      font-size: 15px !important;
      width: 150%;
    }
  }
  .bg-red-gradient {
    &:after {
      background: linear-gradient(
        0deg,
        rgb(205, 0, 58) 0%,
        rgb(233, 2, 2) 5%,
        rgba(255, 0, 43, 0) 100%
      );
    }
  }
  .text-green {
    &:after {
      color: rgb(2, 233, 137);
    }
  }
  .text-red {
    &:after {
      color: transparent;
      text-shadow: 0 0 rgb(233, 2, 2);
    }
  }
  .text-shadowed-red {
    &:after {
      color: rgb(233, 2, 2);
      text-shadow: 1px 0 gray, -1px 0 gray, 0 1px gray, 0 -1px gray,
        1px 1px gray, -1px -1px gray, 1px -1px gray, -1px 1px gray;
    }
  }
  .text-blue {
    &:after {
      color: rgb(0, 247, 255);
    }
  }
  .text-dead {
    &:after {
      // color: transparent;
      // text-shadow: 0 0 rgb(233, 2, 2);
      color: rgb(233, 2, 2);
    }
  }
  .bg-green-gradient {
    &:after {
      background: linear-gradient(
        0deg,
        rgb(0, 205, 82) 0%,
        rgb(2, 233, 137) 5%,
        rgba(255, 0, 43, 0) 100%
      );
    }
  }
  .bg-blue-gradient {
    &:after {
      background: linear-gradient(
        0deg,
        rgb(3, 179, 192) 0%,
        rgb(131, 121, 121) 5%,
        rgba(3, 247, 255, 0) 100%
      );
    }
  }
  .bg-dead {
    &:after {
      background: linear-gradient(
        0deg,
        rgb(103, 99, 99) 0%,
        rgb(131, 121, 121) 5%,
        rgba(255, 0, 0, 0) 100%
      );
    }
  }
}
.health-transition {
  transition: width 1s;
}
.warPageTimer {
  .react-countdown-simple {
    color: #70878f !important;
    font-size: 16px;
  }
  .react-countdown-simple__date-time {
    font-weight: 400;
    font-size: 16px;
    span {
      min-width: 10px !important;
    }
  }
  .react-countdown-simple__colon {
    padding: 0px 3px;
  }
}

.text-small {
  font-size: 10px !important;
}
.versus-text {
  font-family: "Big Shoulders Display";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #ffffff;
}

.transparent-bg-health {
  background: transparent !important;
}

.loadoutBackgroundBorder {
  border-image-slice: 27 27 27 27;
  border-image-width: 20px 20px 20px 20px;
  border-image-outset: 0px 0px 0px 0px;
  border-image-repeat: stretch stretch;
  border-image-source: url("../../images/backgrounds/warPageLoadoutBorder.png");
  border-style: solid;
}

.loadoutBackgroundActiveBorderSneakMove {
  border-image-slice: 27 27 27 27;
  border-image-width: 20px 20px 20px 20px;
  border-image-outset: 0px 0px 0px 0px;
  border-image-repeat: stretch stretch;
  border-image-source: url("../../images/backgrounds/activeBorder.png");
  border-style: solid;
}

.sneakMoveBgBorder {
  border-image-slice: 27 27 27 27;
  border-image-width: 20px 20px 20px 20px;
  border-image-outset: 0px 0px 0px 0px;
  border-image-repeat: stretch stretch;
  border-image-source: url("../../images/backgrounds/sneakmove.png");
  border-style: solid;
  &:hover {
    border-image-source: url("../../images/backgrounds/hoverSneakmove.png");
  }
}
.playerInfoWarScreen {
  .playerInfoImageAndItems {
    .warScreenPlayerItems {
      .warScreenItems {
        width: 28px;
        height: 28px;
      }
    }
  }
  .playerInfoNFTDetails {
    .playerNFTName {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }
    .playerNFTHealth {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #282727;
      text-shadow: 1px 1px 0px #000000;
      background-color: linear-gradient(270deg, #6ee381 -20.38%, #1e5628 100%);
    }
    .effectWarScreen {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      background: rgba(10, 14, 16, 0.98);
      border: 2px solid #882c2c;
      border-radius: 36px;
    }
  }
  .playerDefaultPrimaryWeapons {
    .warDefaultWeapons {
      width: 64px;
      height: 64px;
    }
    .warPrimaryWeapons {
      width: 64px;
      height: 64px;
    }
    .warPrimaryWeaponsNotTaken {
      width: 48px;
      height: 48px;
    }
  }
  .sneakMoveDetails {
    width: 64px;
    height: 64px;
  }
}
.blurImage {
  backdrop-filter: blur(45px);
}
.noBuffDebuff {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #959a9c;
}
.lowestLayer {
  position: absolute;
  bottom: -30px;
  width: 100%;
  height: 200px;
  background: linear-gradient(
    180deg,
    rgba(10, 14, 16, 0) 0%,
    rgba(10, 14, 16, 0.6) 100%
  );
}
.logsTabInWar {
  position: fixed;
  background-color: black;
  // background: rgb(135, 129, 129);
  width: 100%;
  height: 72px;
  bottom: 0;
  z-index: 99999;
  border-top: 1px solid #e3c96e;
  //   transform: matrix(1, 0, 0, -1, 0, 0);
  .warTabs {
    cursor: pointer;
  }
}
.inActiveLogTab {
  opacity: 0.5;
}
.warLogStatWrapper {
  .warYourStats {
    height: 60px;
    border-bottom: 1px solid #e3c96e;
    display: flex;
    justify-content: start;
    padding-left: 7px;
    align-items: center;
    .warGBText {
      font-family: "Big Shoulders Display";
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      color: #ffffff;
    }
  }
  .statsInWar {
    .statHeading {
      padding: 12px 24px;
      width: 100%;
      height: 48px;
      background: rgba(28, 36, 42, 0.5);
      .statsText {
        text-align: left;
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }
  }
  .primaryStatsWar {
    width: 350px;
    margin: auto;
  }
  .secondaryStatsWar {
    width: 350px;
    margin: auto;
  }
  .statsSkillKeysWar {
    color: #70878f !important;
  }
  .statsSkillValuesWar {
    color: white !important;
  }
}
.warLogsOnly {
  &:first-child {
    margin-top: 150px !important;
  }
  &:last-child {
    margin-bottom: 170px !important;
    border-bottom: none;
  }
}
.warPageSkillsStateBorder {
  width: inherit;
  height: inherit;
  position: relative;
  isolation: isolate;
  border-image-slice: 27 27 27 27;
  border-image-width: 20px 20px 20px 20px;
  border-image-outset: 0px 0px 0px 0px;
  border-image-repeat: stretch stretch;
  border-image-source: url("../../images/backgrounds/base.png");
  border-style: solid;
  border-width: 2px 2px 2px 2px;
}
.animateBar {
  transition: width ease-in 1s;
}
.sneakMoveOverlay {
  width: 88%;
  left: 5px;
  height: 30px;
  top: 29px;
  margin: auto;
  color: white;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.662);
  position: absolute;
}

.emptyLogState {
  text-transform: uppercase;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Big Shoulders Display";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  // line-height: 88px;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.4;
}

.warSpeedListWrapper {
  margin-bottom: 77px;
  .spedListHeading {
    position: sticky;
    top: 0;
    bottom: 0;
    background: rgba(10, 14, 16, 0.98);
    height: 60px;
    display: flex;
    justify-content: start;
    align-items: center;
    border-bottom: 1px solid #e3c96e;
    width: 100%;
    h1 {
      font-family: "Big Shoulders Display";
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      color: #ffffff;
    }
  }

  .spdListTable {
    height: 100%;
    overflow: auto;
  }
  .spdListTableHead {
    padding: 10px;
    display: flex;
    gap: 30px;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    letter-spacing: 0.02em;
    color: #70878f;
    .spdListTurn {
      width: 8%;
    }
    .spdListName {
      width: 80%;
    }
    .spdListSPD {
      width: 12%;
    }
  }
  .teamATableSpdList {
    .teamATextSpd {
      padding: 8px 12px;
      width: 100%;
      height: 40px;
      background: linear-gradient(
          90deg,
          rgba(0, 225, 212, 0.16) 2.33%,
          rgba(28, 36, 42, 0) 100%
        ),
        linear-gradient(0deg, rgba(28, 36, 42, 0.7), rgba(28, 36, 42, 0.7)),
        #0a0e10;
      display: flex;
      justify-content: start;
      align-items: center;
      padding-left: 10px;
      h1 {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
      }
    }
    .spdListTableData {
      padding: 10px;
      display: flex;
      gap: 30px;
      justify-items: start;
      align-items: center;
      width: 100%;
      height: 57.33px;
      background: linear-gradient(
          90deg,
          rgba(0, 225, 212, 0.16) 2.33%,
          rgba(28, 36, 42, 0) 100%
        ),
        linear-gradient(0deg, rgba(28, 36, 42, 0.7), rgba(28, 36, 42, 0.7)),
        #0a0e10;

      .spdListTurnValue {
        width: 10%;
      }
      .spdListNameValue {
        width: 80%;
        align-self: flex-start;
        .spdListImage {
          width: 33px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2px;
          border: 3px solid #00e1d429;
        }
        .spdListGBDetails {
          h2 {
            font-family: "Proxima Nova";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
          }
          p {
            font-family: "Proxima Nova";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #70878f;
          }
        }
      }
      .spdListSPDValue {
        width: 10%;
      }
    }
  }
  .teamBTableSpdList {
    .teamBTextSpd {
      padding: 8px 12px;
      width: 100%;
      height: 40px;
      background: linear-gradient(
          90deg,
          rgba(255, 141, 36, 0.224) 2.33%,
          rgba(28, 36, 42, 0) 100%
        ),
        linear-gradient(0deg, rgba(28, 36, 42, 0.7), rgba(28, 36, 42, 0.7)),
        #0a0e10;
      display: flex;
      justify-content: start;
      align-items: center;
      padding-left: 10px;
      h1 {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
      }
    }
    .spdListTableData {
      padding: 10px;
      display: flex;
      gap: 30px;
      justify-items: start;
      align-items: center;
      width: 100%;
      height: 57.33px;
      background: linear-gradient(
          90deg,
          rgba(255, 141, 36, 0.224) 2.33%,
          rgba(28, 36, 42, 0) 100%
        ),
        linear-gradient(0deg, rgba(28, 36, 42, 0.7), rgba(28, 36, 42, 0.7)),
        #0a0e10;
      .spdListTurnValue {
        width: 10%;
      }
      .spdListNameValue {
        width: 80%;
        align-self: flex-start;
        .spdListImage {
          width: 33px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2px;
          border: 3px solid #00e1d429;
        }
        .spdListGBDetails {
          h2 {
            font-family: "Proxima Nova";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
          }
          p {
            font-family: "Proxima Nova";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #70878f;
          }
        }
      }
      .spdListSPDValue {
        width: 10%;
      }
    }
  }
}

.sneakMovesListWrapper {
  margin-bottom: 77px;
  .sneakMoveHeading {
    background: rgba(10, 14, 16, 0.98);
    height: 60px;
    display: flex;
    justify-content: start;
    align-items: center;
    border-bottom: 1px solid #e3c96e;
    width: 100%;
    h1 {
      font-family: "Big Shoulders Display";
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      color: #ffffff;
    }
  }
  .warEditSneakMoves {
    width: 100%;
    height: 132px;
    background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
  }
  .sneakMoveSlotWar {
    width: 64px;
    height: 64px;
    // filter: drop-shadow(10px 10px 10px rgba(216, 176, 39, 0.8));
  }
  .swapSneakWar {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
  }
  .sneakMovesWarPageListEmpty {
    text-transform: uppercase;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    p {
      opacity: 0.5;
    }
  }
  .sneakMovesWarPageListPassive {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #70878f;
  }
}
.overlay-log-top {
  position: fixed;
  background: linear-gradient(
    0deg,
    #0a0f11 0%,
    rgba(10, 15, 17, 0.9) 37.4%,
    rgba(10, 14, 16, 0) 100%
  );
  width: 100%;
  height: 225px;
  top: 0;
  transform: matrix(1, 0, 0, -1, 0, 0);
}
.overlay-log-bottom {
  position: fixed;
  background: linear-gradient(
    0deg,
    #0a0f11 0%,
    rgba(10, 15, 17, 0.9) 37.4%,
    rgba(10, 14, 16, 0) 100%
  );
  width: 100%;
  height: 225px;
  bottom: 0;
  //   transform: matrix(1, 0, 0, -1, 0, 0);
}
.abilityTriggered {
  position: fixed;
  font-size: 18px;
  width: 100%;
  color: white;
  background: linear-gradient(
      90deg,
      rgba(136, 44, 44, 0.53) 0%,
      rgba(136, 44, 44, 0.422) 100%
    ),
    #0a0e10;
  height: 65px;
  z-index: 999999999;
  bottom: -62px;
  h1 {
    font-size: 24px;
    text-transform: uppercase;
    font-family: "Big Shoulders Display";
    background: linear-gradient(180deg, #856f1f 0%, #e3c96e 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
}

.sneakMoveOverlayStatus {
  width: 30px;
  left: 16px;
  height: 30px;
  top: -26px;
  margin: auto;
  color: white;
  z-index: -1;
  position: absolute;
  font-size: 10px;
  background: #0000001f;
  backdrop-filter: blur(90px);
}
.passiveSneakMoveOverlay {
  width: 88%;
  left: 2px;
  height: 30px;
  top: 29px;
  margin: auto;
  color: grey;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.662);
  position: absolute;
}
.deadIconGame {
  background-color: #282928;
  opacity: 0.9;
}
.sneakMoveAddupOverlay {
  width: 40px;
  height: 35px !important;
  left: 10px;
  height: 30px;
  bottom: -13px;
  margin: auto;
  color: white;
  z-index: 0;
  position: absolute;
  .removeSneakMove {
    color: #e3c96e;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
}
// .highlightBg {
//   // background-color: #ffffff;
//   animation: shadowMove 4s infinite;
// }
// @keyframes shadowMove {
//   0% {
//     box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
//   }
//   50% {
//     box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.4);
//   }
//   100% {
//     box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
//   }
// }
.sneakMoveActionType {
  background: linear-gradient(
      90deg,
      rgba(255, 198, 0, 0.3) 0%,
      rgba(255, 198, 0, 0) 100%
    ),
    #0a0e10;
  height: 88px;
  width: 100%;
}
.effectTakenType {
  background: linear-gradient(
      90deg,
      rgba(136, 44, 44, 0.3) 0%,
      rgba(136, 44, 44, 0) 100%
    ),
    #0a0e10;
  height: 88px;
  width: 100%;
}
.criticalHitType {
  background: linear-gradient(
      270deg,
      rgba(136, 44, 44, 0.4) 0%,
      rgba(0, 0, 0, 0) 43.75%,
      rgba(0, 0, 0, 0) 54.69%,
      rgba(136, 44, 44, 0.4) 100%
    ),
    #0a0e10;
  height: 88px;
  width: 100%;
  border-right: 4px solid #ff5353;
  border-left: 4px solid #ff5353;
}
.spdListTableDataSelectedTeamA {
  border: 1px dotted #00e1d4;
}
.spdListTableDataSelectedTeamB {
  border: 1px dotted #ff8d24;
}

.chat-container {
  text-align: initial;
}

.chat-header {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 24px;
  border-bottom: 1px solid #e3c96e;

  h3 {
    font-weight: 700;
  }
}

.chat-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 132px);
}

.chat-messageContainer {
  height: calc(100vh - 188px);
  padding-top: 28px;
  padding-left: 16px;
  padding-right: 16px;
  overflow-y: scroll;
}

.message {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 24px;

  &.own-message {
    flex-direction: row-reverse;
  }

  .user-avatar {
    flex: 0 0 auto;
    width: 44px;
    height: 56px;
    background: rgba(10, 14, 16, 0.98);
    border: 1px solid #485c65;
    padding: 2px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .message-content {
    display: flex;
    flex-direction: column;

    .user-name {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #70878f;
      margin-bottom: 8px;
    }

    .message-text {
      padding: 8px 12px;
      background: #1c242a;
      text-align: left;
      margin-bottom: 8px;
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: #ffffff;
      width: 228px;
      word-break: break-all;
    }

    .message-time {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.02em;
      color: #70878f;
    }
  }
}

.input-container {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0px 14px;
  background: #1c242a;

  form {
    display: flex;
    align-items: center;
    height: 56px;
    width: 100%;
  }

  .emoji-button {
    cursor: pointer;
    span {
      color: #70878f;
    }

    &.emoji-select {
      span {
        color: #fff;
      }
    }
  }

  .chat-inputField {
    flex: 1;

    .chat-input {
      width: 100%;
      -webkit-appearance: none;
      background: transparent;
      border: none;
      outline: none;
      padding: 0 8px;

      &:focus {
        outline: none;
      }
    }
  }

  .message-sendBtn {
    cursor: pointer;
  }
}

.emoji-wrapper {
  position: absolute;
  bottom: 56px;
  right: 33px;
}
