.rental-text {
    font-family: "Proxima Nova";
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.rental-text-md {
    font-family: "Proxima Nova";
    color: #9aa8ac;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

.rent-price-dropdown {
    font-family: "Big Shoulders Display";
    font-style: normal;
    // font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    letter-spacing: 0.5px;
    color: #fff;
}

.rent-time-countdown {
    color: #fff !important;
    font-family: "Proxima Nova" !important;
    font-size: 14px;
    line-height: 20px;
    .react-countdown-simple {
        color: white !important;
        font-weight: 600;
        padding: 0px;
        padding-right: 3px;
    }
    .react-countdown-simple__date-time {
        font-weight: 700;
        font-size: 14px;
        color: white !important;
        text-transform: lowercase;
        span {
            min-width: 10px !important;
        }
    }
    .react-countdown-simple__colon {
        padding: 0px;
    }
}
