.logBarWrapper {
  width: 400px;
  background-color: balck;
  height: 100vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  // scroll-snap-type: y mandatory;
  position: absolute;
  right: 0px;
  color: white;
  padding: 20px;
  .logHandler {
    position: relative;
    &:first-child {
      margin-top: 30px;
    }
    &:last-child {
      margin-bottom: 150px;
    }
    .eventName {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #70878f;
    }
    .logContext {
      font-size: 16px;
      color: white;
      text-align: justify;
    }
    .logCollection {
      font-size: 16px;
      color: white;
      text-align: justify;
    }
    .logSeperator {
      margin: 20px 0px;
    }
    .overlay-log-top {
      position: fixed;
      background: linear-gradient(
        0deg,
        #0a0f11 0%,
        rgba(10, 15, 17, 0.9) 37.4%,
        rgba(10, 14, 16, 0) 100%
      );
      width: 400px;
      height: 225px;
      top: 0;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
    .overlay-log-bottom {
      position: fixed;
      background: linear-gradient(
        0deg,
        #0a0f11 0%,
        rgba(10, 15, 17, 0.9) 37.4%,
        rgba(10, 14, 16, 0) 100%
      );
      width: 400px;
      height: 225px;
      bottom: 0;
      //   transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }
}

.goToCurrentLog {
  position: sticky;
  background-color: red;
  // bottom: 0;
  top: 0;
  left: 35%;
  cursor: pointer;
  width: 100px;
  z-index: 8;
}
