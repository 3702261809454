.popUpOverlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.678);
    backdrop-filter: blur(8px);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 3;
    overflow: "auto";
    .selectCharacterWrapper {
        background: url("../../../images/backgrounds/modalBg.svg"), #000;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        border: 2px solid #e3c96e;
        top: 50%;
        left: 50%;
        max-width: 1320px;
        min-height: 400px;
        max-height: 770px;
        // padding: 20px;
        transform: translate(-50%, -50%);
        .headingSelectCharacter {
            padding: 40px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            border: 2px solid;
            border-image: radial-gradient(circle at center, #e3ca6e5c, #856f1f00) 90;
            //   border-bottom: 2px solid #e3c96e;
            .headingText {
                font-family: var(--bigShoulders);
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 48px;
                background: linear-gradient(180deg, #856f1f 0%, #e3c96e 100%);
                background-clip: text;
            }
            color: transparent;
            text-transform: uppercase;
        }
        .closerPart {
            color: white;
            display: flex;
            gap: 30px;
            .crownIconWithValue {
                display: flex;
                align-items: center;
                gap: 10px;
                font-family: "Proxima Nova";
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                .crownVlaue {
                    align-self: end;
                }
            }
        }

        .selectCharacterBody {
            display: flex;

            // .selectCharacterLeftPart {
            //   overflow-y: auto;
            //   width: 50%;
            //   min-height: 400px;
            //   display: flex;
            //   flex-direction: column;
            //   flex-wrap: wrap;
            //   gap: 20px;

            //   @media screen and (max-width: 1060px) {
            //     width: 100%;
            //   }
            //   .allNFTFilters {
            //     display: flex;
            //     padding: 20px 15px 0px 15px;
            //     justify-content: space-between;
            //     .buyNFTBtn {
            //       // flex-basis: 25%;
            //       cursor: pointer;
            //       display: flex;
            //       justify-content: center;
            //       align-items: center;
            //       gap: 6px;
            //       padding: 2px 10px;
            //       color: white;
            //       background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
            //       border: 1px solid #e3c96e;
            //       text-transform: capitalize;
            //     }
            //     .searchNFT {
            //       .searchInput {
            //         position: relative;
            //         .searchIcon {
            //           position: absolute;
            //           top: 8px;
            //         }
            //         .closeSearchIcon {
            //           position: absolute;
            //           bottom: 0px;
            //           right: 0px;
            //           top: 10px;
            //           cursor: pointer;
            //         }
            //         .searchBox {
            //           padding: 5px 20px;
            //           color: white;
            //           border-bottom: 1px solid white;
            //           width: 270px;
            //           text-align: left;
            //           &::placeholder {
            //             text-align: right;
            //           }
            //           background: transparent;
            //           &:focus {
            //             outline: none;
            //             border-bottom: 1px solid #e3c96e;
            //           }
            //         }
            //       }
            //     }
            //     .sortNFTby {
            //       position: relative;
            //       .sortBy {
            //         cursor: pointer;
            //         color: white;
            //         display: flex;
            //         align-items: center;
            //         gap: 8px;
            //         border: 1px solid grey;
            //         padding: 4px 10px;

            //         .sortByText {
            //         }
            //       }
            //       .sortByDrop {
            //         box-shadow: 0px 0px 16px rgba(10, 14, 16, 0.75);
            //         background: black;
            //         position: absolute;
            //         color: white;
            //         width: 193px;
            //         z-index: 3;
            //         padding: 5px 10px;
            //         border: 1px solid grey;
            //         top: 100%;
            //         right: 0;
            //         p {
            //           font-weight: 400;
            //           font-size: 14px;
            //           cursor: pointer;
            //           color: #70878f;
            //           padding: 10px;
            //         }
            //       }
            //     }
            //   }
            //   .nftCards {
            //     padding: 10px;
            //     display: flex;
            //     flex-wrap: wrap;
            //     justify-content: left;
            //     gap: 10px;
            //     overflow-y: auto;
            //     color: grey;
            //     .searchNotFound {
            //       display: flex;
            //       justify-content: center;
            //       align-items: center;
            //       flex-direction: column;
            //       gap: 15px;
            //       .notFoundNFT {
            //         font-family: var(--bigShoulders);
            //         font-size: 32px;
            //       }
            //       .notFoundInfo {
            //         font-size: 18px;
            //         text-align: center;
            //       }
            //     }
            //     .nftAllCards {
            //       color: white;
            //       display: flex;
            //       flex-direction: column;
            //       gap: 5px;

            //       .nftName {
            //         align-self: center;
            //         font-family: "Proxima Nova";
            //         font-style: normal;
            //         font-weight: 600;
            //         font-size: 10px;
            //         line-height: 16px;
            //         color: #70878f;
            //       }
            //     }
            //   }
            //   // justify-content: space-around;
            //   .buyNftCard {
            //     width: 120px;
            //     height: 140px;
            //     border: 2px solid grey;
            //     position: relative;
            //     isolation: isolate;
            //     cursor: pointer;
            //     .buyNft {
            //       top: 50%;
            //       left: 50%;
            //       transform: translate(-50%, -50%);
            //       position: absolute;
            //       display: flex;
            //       justify-content: center;
            //       align-items: center;
            //       flex-direction: column;
            //       color: white;
            //       text-transform: uppercase;
            //       .buyText {
            //         font-size: 18px;
            //         font-weight: 500;
            //       }
            //     }
            //   }
            // }
            .equipItemLeftPart {
                display: flex;
                flex-direction: column;
                // justify-content: space-between;
                // width: 50%;
                min-height: 610px;
                border-right: 1px solid #e3c96e;
                // width: 455px;
                // padding: 20px;
                // gap: 50px;
                height: 610px;
                font-family: "Proxima Nova";
                .nftCharacterDetails {
                    display: flex;
                    max-height: 511px;
                    height: 511px;
                    min-height: 511px;
                    @include max-break-point(tablet-landscape) {
                        overflow-y: auto;
                    }
                    width: 100%;
                    justify-content: start;
                    .nftNotSelected {
                        font-family: var(--bigShoulders);
                        color: white;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 40px;
                        color: grey;
                        align-self: center;
                    }
                }

                .nftInfo {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 20px;
                    color: white;
                    border-bottom: 1px solid grey;
                    @include max-break-point(tablet-landscape) {
                        flex-direction: column;
                    }
                    .nftImage {
                    }
                }
                .nftDetails {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .nftNameClassType {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        .gbName {
                            font-style: normal;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 24px;
                            color: #ffffff;
                        }
                        .gbClassType {
                            display: flex;
                            // justify-content: center;
                            align-items: center;
                            gap: 10px;
                            color: #70878f;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 24px;

                            .gbType {
                            }
                            .gbClass {
                            }
                        }
                    }
                    .gbLevel {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        display: flex;
                        // justify-content: center;
                        align-items: center;
                        gap: 10px;
                        .gbLevelNow {
                        }
                        .circle {
                            background: white;
                        }
                        .gbLevelName {
                        }
                    }
                    .nftWeapons {
                        .weapons {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 8px;
                            .weaponsInfo {
                                .weaponName {
                                    font-weight: 700;
                                    font-size: 15px;
                                    line-height: 24px;
                                }
                                .isDefault {
                                    font-weight: 400;
                                    font-size: 10px;
                                }
                            }
                            .ranged {
                                display: flex;
                                gap: 5px;
                            }
                        }
                    }
                }
                .nftSkills {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    padding: 15px;
                    .nftSkillTitle {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 20px;
                        color: #70878f;
                    }
                    .nftActualSkills {
                        display: flex;
                        width: inherit;
                        height: inherit;
                        position: relative;
                        isolation: isolate;
                        border-image-slice: 27 27 27 27;
                        border-image-width: 20px 20px 20px 20px;
                        border-image-outset: 0px 0px 0px 0px;
                        border-image-repeat: stretch stretch;
                        border-image-source: url("../../../images/backgrounds/base.png");
                        border-style: solid;
                        border-width: 2px 2px 2px 2px;
                        // clip-path: polygon(
                        //   0.5em 0%,
                        //   calc(100% - 0.5em) 0%,
                        //   100% 0.5em,
                        //   100% calc(100% - 0.5em),
                        //   calc(100% - 0.5em) 100%,
                        //   0.5em 100%,
                        //   0% calc(100% - 0.5em),
                        //   0% 0.5em
                        // );
                        // &::before {
                        //   content: "";
                        //   position: absolute;
                        //   inset: 0;
                        //   z-index: -2;
                        //   background-color: grey;
                        //   background-color: transparent;
                        //   box-shadow: inset 0px 0px 0px 10px grey;
                        // }
                        // &::after {
                        //   content: "";
                        //   position: absolute;
                        //   inset: 0;
                        //   background: linear-gradient(
                        //     180deg,
                        //     rgba(2, 13, 20, 0.82) 0%,
                        //     rgba(2, 13, 20, 0.8) 100%
                        //   );
                        //   background-size: cover;
                        //   background-repeat: no-repeat;
                        //   z-index: -1;
                        //   clip-path: polygon(
                        //     0.5em 0.15em,
                        //     calc(100% - 0.5em) 0.15em,
                        //     calc(100% - 0.15em) 0.5em,
                        //     calc(100% - 0.15em) calc(100% - 0.5em),
                        //     calc(100% - 0.5em) calc(100% - 0.15em),
                        //     0.5em calc(100% - 0.15em),
                        //     0.15em calc(100% - 0.5em),
                        //     0.15em 0.5em
                        //   );
                        // }
                        .skillsLeft {
                            width: 50%;
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            padding: 10px;
                            // border-right: 1px solid grey;
                            .skillInfo {
                                display: flex;
                                // gap: 30px;
                                justify-content: space-between;

                                .skillName {
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: #70878f;
                                }
                                .skillNumber {
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: #ffffff;
                                }
                            }
                        }
                        .skillsRight {
                            width: 50%;
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            padding: 10px;
                            .skillInfo {
                                display: flex;
                                // gap: 30px;
                                justify-content: space-between;
                                .skillName {
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: #70878f;
                                }
                                .skillNumber {
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: #ffffff;
                                }
                            }
                        }
                    }
                }
                .startWar {
                    // align-self: flex-end;
                    color: white;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .startWarTimer {
                        font-family: "Big Shoulders Display";
                        font-style: normal;
                        font-weight: 700;
                        font-size: 35px;
                        line-height: 48px;
                        color: #70878f;
                        span {
                            background: linear-gradient(180deg, #856f1f 0%, #e3c96e 100%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                            color: transparent;
                            .react-countdown-simple {
                                color: #e3c96e !important;
                                font-family: var(--bigShoulders) !important;
                                font-size: 32px;
                                background: linear-gradient(
                                    180deg,
                                    #856f1f 0%,
                                    #e3c96e 100%
                                ) !important;
                                background-clip: text !important;
                            }
                            .react-countdown-simple__date-time {
                                font-weight: 700;
                                font-size: 32px;
                                span {
                                    min-width: 10px !important;
                                }
                            }
                            .react-countdown-simple__colon {
                                padding: 0px 3px;
                            }
                        }
                    }
                    .warContinueBtnDiv {
                        .contineWarBtn {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-family: "Big Shoulders Display";
                            font-style: normal;
                            font-weight: 700;
                            font-size: 30px;
                            border: 2px solid #856f1f;
                            background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
                            padding: 4px 8px 4px 8px;
                            gap: 4px;

                            width: 180px;
                            height: 58px;
                        }
                    }
                }
            }

            .equipItemMiddlePart {
                border: 1px solid #e3c96e;
                border-top: 0;
                box-shadow: 1px -1px 34px -6px rgba(227, 201, 110, 1),
                    1px -1px 34px -6px rgba(227, 201, 110, 1);
                -webkit-box-shadow: 1px -1px 34px -6px rgba(227, 201, 110, 1);
                -moz-box-shadow: 1px -1px 34px -6px rgba(227, 201, 110, 1);

                h1 {
                    text-transform: uppercase;
                    border-bottom: 1px solid grey;
                    text-align: center;
                    width: 100%;
                    font-family: "Big Shoulders Display";
                    color: #fff;
                    padding: 10px 60px;
                    font-size: 28px;
                    line-height: 36px;
                }
            }
            .equipItemRightPart {
                display: flex;
                flex-direction: column;
                // justify-content: space-between;
                width: 50%;
                min-height: 610px;
                // border: 1px solid #e3c96e;
                // gap: 50px;
                height: 610px;
                font-family: "Proxima Nova";
                font-family: var(--dm-san);

                .left-part {
                    border-right: 1px solid #e3c96e;
                    height: 100%;
                    padding: 10px;
                }

                .nftCharacterDetails {
                    display: flex;
                    max-height: 511px;
                    height: 511px;
                    min-height: 511px;
                    @include max-break-point(tablet-landscape) {
                        overflow-y: auto;
                    }
                    width: 100%;
                    justify-content: center;
                    .nftNotSelected {
                        font-family: var(--bigShoulders);
                        color: white;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 40px;
                        color: grey;
                        align-self: center;
                    }
                }

                .nftInfo {
                    display: flex;
                    gap: 20px;
                    color: white;
                    @include max-break-point(tablet-landscape) {
                        flex-direction: column;
                    }
                    .nftImage {
                    }
                }
                .nftDetails {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .nftNameClassType {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        .gbName {
                            font-style: normal;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 24px;
                            color: #ffffff;
                        }
                        .gbClassType {
                            display: flex;
                            // justify-content: center;
                            align-items: center;
                            gap: 10px;
                            color: #70878f;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 24px;

                            .gbType {
                            }
                            .gbClass {
                            }
                        }
                    }
                    .gbLevel {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        display: flex;
                        // justify-content: center;
                        align-items: center;
                        gap: 10px;
                        .gbLevelNow {
                        }
                        .circle {
                            background: white;
                        }
                        .gbLevelName {
                        }
                    }
                    .nftWeapons {
                        .weapons {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 30px;
                            .weaponsInfo {
                                .weaponName {
                                    font-weight: 700;
                                    font-size: 15px;
                                    line-height: 24px;
                                }
                                .isDefault {
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 20px;
                                }
                            }
                            .ranged {
                                display: flex;
                                gap: 5px;
                            }
                        }
                    }
                    .nftSkills {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        .nftSkillTitle {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #e3c96e;
                        }
                        .nftActualSkills {
                            display: flex;
                            gap: 30px;
                            .skillsLeft {
                                width: 50%;
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                .skillInfo {
                                    display: flex;
                                    // gap: 30px;
                                    justify-content: space-between;

                                    .skillName {
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 20px;
                                        color: #70878f;
                                    }
                                    .skillNumber {
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 20px;
                                        color: #ffffff;
                                    }
                                }
                            }
                            .skillsRight {
                                width: 50%;
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                .skillInfo {
                                    display: flex;
                                    // gap: 30px;
                                    justify-content: space-between;
                                    .skillName {
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 20px;
                                        color: #70878f;
                                    }
                                    .skillNumber {
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 20px;
                                        color: #ffffff;
                                    }
                                }
                            }
                        }
                    }
                }
                .startWar {
                    // align-self: flex-end;
                    color: white;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .startWarTimer {
                        font-family: "Big Shoulders Display";
                        font-style: normal;
                        font-weight: 700;
                        font-size: 35px;
                        line-height: 48px;
                        color: #70878f;
                        span {
                            background: linear-gradient(180deg, #856f1f 0%, #e3c96e 100%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                            color: transparent;
                            .react-countdown-simple {
                                color: #e3c96e !important;
                                font-family: var(--bigShoulders) !important;
                                font-size: 32px;
                                background: linear-gradient(
                                    180deg,
                                    #856f1f 0%,
                                    #e3c96e 100%
                                ) !important;
                                background-clip: text !important;
                            }
                            .react-countdown-simple__date-time {
                                font-weight: 700;
                                font-size: 32px;
                                span {
                                    min-width: 10px !important;
                                }
                            }
                            .react-countdown-simple__colon {
                                padding: 0px 3px;
                            }
                        }
                    }
                    .warContinueBtnDiv {
                        .contineWarBtn {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-family: "Big Shoulders Display";
                            font-style: normal;
                            font-weight: 700;
                            font-size: 30px;
                            border: 2px solid #856f1f;
                            background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
                            padding: 4px 8px 4px 24px;
                            gap: 4px;

                            width: 180px;
                            height: 58px;
                        }
                    }
                }
            }
        }
    }
}

.circle {
    width: 5px;
    height: 5px;
    background-color: #70878f;
    border-radius: 50%;
}

/* width */
::-webkit-scrollbar {
    width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e3c96e;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

.upgradeStats {
    color: green;
    font-weight: 500;
}

.emptyloadout {
    font-family: var(--bigShoulders);
    color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: grey;
    align-self: center;
    text-transform: uppercase;
}

.nftSkills {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    .nftSkillTitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: #70878f;
    }
    .nftActualSkills {
        display: flex;
        width: inherit;
        height: inherit;
        position: relative;
        isolation: isolate;
        border-image-slice: 27 27 27 27;
        border-image-width: 20px 20px 20px 20px;
        border-image-outset: 0px 0px 0px 0px;
        border-image-repeat: stretch stretch;
        border-image-source: url("../../../images/backgrounds/base.png");
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        // clip-path: polygon(
        //   0.5em 0%,
        //   calc(100% - 0.5em) 0%,
        //   100% 0.5em,
        //   100% calc(100% - 0.5em),
        //   calc(100% - 0.5em) 100%,
        //   0.5em 100%,
        //   0% calc(100% - 0.5em),
        //   0% 0.5em
        // );
        // &::before {
        //   content: "";
        //   position: absolute;
        //   inset: 0;
        //   z-index: -2;
        //   background-color: grey;
        //   background-color: transparent;
        //   box-shadow: inset 0px 0px 0px 10px grey;
        // }
        // &::after {
        //   content: "";
        //   position: absolute;
        //   inset: 0;
        //   background: linear-gradient(
        //     180deg,
        //     rgba(2, 13, 20, 0.82) 0%,
        //     rgba(2, 13, 20, 0.8) 100%
        //   );
        //   background-size: cover;
        //   background-repeat: no-repeat;
        //   z-index: -1;
        //   clip-path: polygon(
        //     0.5em 0.15em,
        //     calc(100% - 0.5em) 0.15em,
        //     calc(100% - 0.15em) 0.5em,
        //     calc(100% - 0.15em) calc(100% - 0.5em),
        //     calc(100% - 0.5em) calc(100% - 0.15em),
        //     0.5em calc(100% - 0.15em),
        //     0.15em calc(100% - 0.5em),
        //     0.15em 0.5em
        //   );
        // }
        .skillsLeft {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px;
            // border-right: 1px solid grey;
            .skillInfo {
                display: flex;
                // gap: 30px;
                justify-content: space-between;

                .skillName {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #70878f;
                }
                .skillNumber {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #ffffff;
                }
            }
        }
        .skillsRight {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px;
            .skillInfo {
                display: flex;
                // gap: 30px;
                justify-content: space-between;
                .skillName {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #70878f;
                }
                .skillNumber {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #ffffff;
                }
            }
        }
    }
}
