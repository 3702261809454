.popUpOverlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.678);
    backdrop-filter: blur(8px);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 3;
    overflow: "auto";
    .selectCharacterWrapper {
        background: url("../../../images/backgrounds/modalBg.svg"), #000;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        border: 2px solid #e3c96e;
        top: 50%;
        left: 50%;
        max-width: 1240px;
        min-height: 400px;
        max-height: 770px;
        // padding: 20px;
        transform: translate(-50%, -50%);
        .headingSelectCharacter {
            padding: 40px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            border: 2px solid;
            border-image: radial-gradient(circle at center, #e3ca6e5c, #856f1f00) 90;
            //   border-bottom: 2px solid #e3c96e;
            .headingText {
                font-family: var(--bigShoulders);
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 48px;
                background: linear-gradient(180deg, #856f1f 0%, #e3c96e 100%);
                background-clip: text;
                .react-countdown-simple {
                    color: #e3c96e !important;
                    font-family: var(--bigShoulders) !important;
                    font-size: 40px;
                    background: linear-gradient(180deg, #856f1f 0%, #e3c96e 100%) !important;
                    background-clip: text !important;
                }
                .react-countdown-simple__date-time {
                    font-weight: 700;
                    font-size: 40px;
                    span {
                        min-width: 10px !important;
                    }
                }
                .react-countdown-simple__colon {
                    padding: 0px 3px;
                }
            }
            color: transparent;
            text-transform: uppercase;
        }
        .closerPart {
            color: white;
            display: flex;
            gap: 30px;
            .crownIconWithValue {
                display: flex;
                align-items: center;
                gap: 10px;
                font-family: "Proxima Nova";
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                .crownVlaue {
                    align-self: end;
                }
            }
        }

        .selectCharacterBody {
            display: flex;

            .selectCharacterLeftPart {
                overflow-y: auto;
                width: 50%;
                min-height: 400px;
                display: flex;
                flex-direction: column;
                // flex-wrap: wrap;
                gap: 10px;

                .allNFTFilters {
                    display: flex;
                    padding: 20px 15px 0px 15px;
                    justify-content: space-between;
                    width: 100%;
                    max-height: 52px;
                    .buyNFTBtn {
                        // flex-basis: 25%;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 6px;
                        padding: 2px 10px;
                        color: white;
                        background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
                        border: 1px solid #e3c96e;
                        text-transform: capitalize;
                    }
                    .searchNFT {
                        .searchInput {
                            position: relative;
                            .searchIcon {
                                position: absolute;
                                top: 8px;
                            }
                            .closeSearchIcon {
                                position: absolute;
                                bottom: 0px;
                                right: 0px;
                                top: 10px;
                                cursor: pointer;
                            }
                            .searchBox {
                                width: 260px;
                                padding: 5px 20px;
                                color: white;
                                border-bottom: 1px solid white;
                                text-align: left;
                                &::placeholder {
                                    text-align: right;
                                }
                                background: transparent;
                                &:focus {
                                    outline: none;
                                    border-bottom: 1px solid #e3c96e;
                                }
                            }
                        }
                    }
                    .sortNFTby {
                        position: relative;
                        .sortBy {
                            cursor: pointer;
                            color: white;
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            border: 1px solid grey;
                            padding: 4px 10px;

                            .sortByText {
                            }
                        }
                        .sortByDrop {
                            box-shadow: 0px 0px 16px rgba(10, 14, 16, 0.75);
                            background: black;
                            position: absolute;
                            color: white;
                            width: 193px;
                            z-index: 3;
                            padding: 5px 10px;
                            border: 1px solid grey;
                            top: 100%;
                            right: 0;
                            p {
                                font-weight: 400;
                                font-size: 14px;
                                cursor: pointer;
                                color: #70878f;
                                padding: 10px;
                            }
                            .filter-selected {
                                font-weight: 700;
                                color: #e3c96e;
                            }
                        }
                    }
                }
                .nftCards {
                    width: 100%;
                    max-height: 515px;
                    padding: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: left;
                    gap: 10px;
                    overflow-y: auto;
                    color: grey;
                    .searchNotFound {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        gap: 15px;
                        .notFoundNFT {
                            font-family: var(--bigShoulders);
                            font-size: 32px;
                        }
                        .notFoundInfo {
                            font-size: 18px;
                            text-align: center;
                        }
                    }
                    .nftAllCards {
                        color: white;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;

                        .nftName {
                            align-self: center;
                            font-family: "Proxima Nova";
                            font-style: normal;
                            font-weight: 600;
                            font-size: 10px;
                            line-height: 16px;
                            color: #70878f;
                        }
                    }
                }
                // justify-content: space-around;
                .buyNftCard {
                    width: 120px;
                    height: 140px;
                    border: 2px solid grey;
                    position: relative;
                    isolation: isolate;
                    cursor: pointer;
                    .buyNft {
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        color: white;
                        text-transform: uppercase;
                        .buyText {
                            font-size: 18px;
                            font-weight: 500;
                        }
                    }
                }
            }
            .selectCharacterRightPart {
                display: flex;
                flex-direction: column;
                // justify-content: space-between;
                width: 50%;
                min-height: 610px;
                border: 1px solid #e3c96e;
                padding: 20px;
                // gap: 50px;
                height: 610px;
                font-family: "Proxima Nova";

                @media screen and (max-width: 1060px) {
                    overflow: auto !important;
                }

                .nftCharacterDetails {
                    display: flex;
                    max-height: 511px;
                    height: 511px;
                    min-height: 511px;
                    @include max-break-point(tablet-landscape) {
                        overflow-y: auto;
                    }
                    width: 100%;
                    justify-content: center;
                    .nftNotSelected {
                        font-family: var(--bigShoulders);
                        color: white;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 40px;
                        color: grey;
                        align-self: center;
                    }
                }

                .nftInfo {
                    display: flex;
                    gap: 20px;
                    color: white;
                    @include max-break-point(tablet-landscape) {
                        flex-direction: column;
                    }
                    .nftImage {
                    }
                }
                .nftDetails {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .nftNameClassType {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        .gbName {
                            font-style: normal;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 24px;
                            color: #ffffff;
                        }
                        .gbClassType {
                            display: flex;
                            // justify-content: center;
                            align-items: center;
                            gap: 10px;
                            color: #70878f;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 24px;

                            .gbType {
                            }
                            .gbClass {
                            }
                        }
                    }
                    .gbLevel {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        display: flex;
                        // justify-content: center;
                        align-items: center;
                        gap: 10px;
                        .gbLevelNow {
                        }
                        .circle {
                            background: white;
                        }
                        .gbLevelName {
                        }
                    }
                    .nftWeapons {
                        .weapons {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 30px;
                            .weaponsInfo {
                                .weaponName {
                                    font-weight: 700;
                                    font-size: 15px;
                                    line-height: 24px;
                                }
                                .isDefault {
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 20px;
                                }
                            }
                            .ranged {
                                display: flex;
                                gap: 5px;
                            }
                        }
                    }
                    .nftSkills {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        .nftSkillTitle {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #e3c96e;
                        }
                        .nftActualSkills {
                            display: flex;
                            gap: 20px;
                            .skillsLeft {
                                width: 50%;
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                .skillInfo {
                                    display: flex;
                                    // gap: 30px;
                                    justify-content: space-between;

                                    .skillName {
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 20px;
                                        color: #70878f;
                                    }
                                    .skillNumber {
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 20px;
                                        color: #ffffff;
                                    }
                                }
                            }
                            .skillsRight {
                                width: 50%;
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                .skillInfo {
                                    display: flex;
                                    // gap: 30px;
                                    justify-content: space-between;
                                    .skillName {
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 20px;
                                        color: #70878f;
                                    }
                                    .skillNumber {
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 20px;
                                        color: #ffffff;
                                    }
                                }
                            }
                        }
                    }
                }
                .startWar {
                    // align-self: flex-end;
                    color: white;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    @media screen and (max-width: 1060px) {
                        flex-direction: column;
                        justify-content: flex-start;
                        gap: 20px;
                        width: 100%;
                    }
                    .startWarTimer {
                        font-family: "Big Shoulders Display";
                        font-style: normal;
                        font-weight: 700;
                        font-size: 35px;
                        line-height: 48px;
                        color: #70878f;
                        span {
                            background: linear-gradient(180deg, #856f1f 0%, #e3c96e 100%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                            color: transparent;
                            .react-countdown-simple {
                                color: #e3c96e !important;
                                font-family: var(--bigShoulders) !important;
                                font-size: 32px;
                                background: linear-gradient(
                                    180deg,
                                    #856f1f 0%,
                                    #e3c96e 100%
                                ) !important;
                                background-clip: text !important;
                            }
                            .react-countdown-simple__date-time {
                                font-weight: 700;
                                font-size: 32px;
                                span {
                                    min-width: 10px !important;
                                }
                            }
                            .react-countdown-simple__colon {
                                padding: 0px 3px;
                            }
                        }
                    }
                    .warContinueBtnDiv {
                        .contineWarBtn {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-family: "Big Shoulders Display";
                            font-style: normal;
                            font-weight: 700;
                            font-size: 30px;
                            border: 2px solid #856f1f;
                            background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
                            padding: 4px 8px 4px 24px;
                            gap: 4px;

                            width: 180px;
                            height: 58px;
                        }
                    }
                }
            }
        }
    }
}

.circle {
    width: 5px;
    height: 5px;
    background-color: #70878f;
    border-radius: 50%;
}

/* width */
::-webkit-scrollbar {
    width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e3c96e;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}
.contineWarBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Big Shoulders Display";
    font-style: normal;
    font-weight: 700;
    // font-size: 30px;
    border: 2px solid #856f1f;
    background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
    padding: 4px 8px 4px 24px;
    gap: 4px;

    // width: 180px;
    // height: 58px;
}

.normalButton {
    font-family: "Big Shoulders Display";
    font-style: normal;
    font-weight: 700;
    // font-size: 30px;
    border: 2px solid #856f1f;
    background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
    padding: 4px 8px;
    gap: 4px;
}

.disable {
    cursor: no-drop;
    filter: grayscale(100%);
    pointer-events: none;
}

.makeInActive {
    cursor: no-drop;
    filter: grayscale(100%);
}

.chooseCharacterNFTTab {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 2px 10px;
    color: white;
    background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
    border: 1px solid grey;
    text-transform: capitalize;
}
.chooseCharacterSelectedNFTTab {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 2px 10px;
    color: white;
    background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
    border: 1px solid #e3c96e;
    text-transform: capitalize;
}

.nftWeapons {
    .weapons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        .weaponsInfo {
            .weaponName {
                font-weight: 700;
                font-size: 15px;
                line-height: 24px;
            }
            .isDefault {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
            }
        }
        .ranged {
            display: flex;
            gap: 5px;
        }
    }
}
