.customTooltip {
  position: relative;
  border: none;
  outline: none;
  cursor: pointer;

  .tooltipTop {
    bottom: 100%;
    right: 50%;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: grey transparent transparent transparent;
    }
  }
  .tooltipBottom {
    top: 100%;
    right: 50%;
    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent grey transparent;
    }
  }
  .tooltipLeft {
    bottom: -150%;
    right: 230%;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 104%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent grey;
    }
  }
  .tooltipRight {
    top: -130%;
    left: 0%;
    &::after {
      content: "";
      position: absolute;
      bottom: 50%;
      right: 104%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent grey transparent transparent;
    }
  }

  .tooltipText,
  .tooltipTop,
  .tooltipBottom,
  .tooltipLeft,
  .tooltipRight {
    visibility: hidden;
    background-color: #70878f;
    color: white;
    border: 5px solid #111111;
    font-size: 14px;
    padding: 8px;
    position: absolute;
    z-index: 100;
    width: 150px;
    max-height: 90px;
    transform: translateX(52%);
  }

  &:hover {
    .tooltipText {
      visibility: visible;
    }
  }
}
.border-green {
  color: #fff;
  background-color: rgb(55, 55, 55);
  font-size: 30px;
  border: 1px solid #485c65;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  max-width: 500px;
  background: #1c242a;
  border: 1px solid #d9d9d9;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 110%;
  z-index: 99999;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px 10px 5px 10px;
  border-style: solid;
  border-color: transparent transparent transparent #d9d9d9;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
