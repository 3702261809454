.connect-success {
  font-family: proxima-nova, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  .wallet-connected-success {
    font-size: 18px;
    font-weight: 400 !important;
    letter-spacing: 0.1px;
    color: white;
    opacity: 0.7;
  }
  .wallet-connected-address {
    color: #079b9d;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

//disconnected
.disconnect-wallet {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  p {
    font-size: 24px;
    color: white;
  }
  .confirm-cancel-btns {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
