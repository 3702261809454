.ledgerWrapper {
  font-family: proxima-nova, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  .ledger-heading {
    color: white;
    font-size: 18px;
  }
  .ledger-address-body {
    .ledger-address-list {
      //   padding: 20px 0px;
      color: white;

      .address-container {
        padding: 5px 0px;
        text-overflow: ellipsis;
        cursor: pointer;

        .address-text {
          font-size: 16px;

          &:hover {
            color: #068385;
          }
        }

        &.active {
          color: #068385;
          font-weight: bold;
        }
      }
    }
  }

  .address-text {
    font-size: 16px;
  }

  .ledger-button-group {
    display: flex;
    gap: 30px;
  }

  .ledger-page-navigation {
    margin-top: 20px;
    .pagination {
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 20px;
    }
  }
}

.ledger-connecting-text {
  font-family: proxima-nova, sans-serif;
  color: white;
  font-size: 18px;
}
