.rapidRecoverNFTWrapper {
  font-family: "Proxima Nova";
  font-family: var(--dm-san);

  padding: 7px;
  display: flex;
  flex-direction: column;
  //   gap: 5px;
  align-items: center;
  .playerNumber {
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
  }
  .rapidRecoverNFTText {
    // width: 90%;
    margin: auto;
    .rapidRecoverNFTTopText {
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 24px;
      padding: 7px;
      text-align: center;

      color: #ffffff;
    }
    .rapidRecoverNFTInfo {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      padding: 7px;
      text-align: center;

      color: #ffffff;
    }
  }
  .rapidRecoverNFTButton {
    .notNowBtn {
      font-weight: 600;
      font-size: 20px;
      line-height: 36px;
      text-align: center;
      text-transform: uppercase;
      color: #fff5d0;
      cursor: pointer;
    }
  }
}
