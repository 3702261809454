.character-filter-container {
    position: sticky;
    top: 0;
    .panel-title-wrapper {
        display: flex;
        align-items: baseline;
        color: rgba(254, 252, 232, 1);
        justify-content: space-between;
        padding: 24px;
        
        .panel-title {
            font-family: "Proxima Nova";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: white;
        }
        .panel-title-clear {
            color: #70878F;
            font-family: 'Proxima Nova';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.24px;
            cursor: pointer;
        } 
    }

    .filters-section {
        display: flex;
        flex-direction: column;
        // row-gap: 24px;

        .filter-wrapper {
            .filter-title-wrapper {
                padding: 0 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                img {
                    transition: all 200ms ease-in-out;
                }

                &.filter-list-invisible {
                    img {
                        transform: rotate(180deg);
                    }
                }

                .filter-title {
                    font-family:  "Proxima Nova";
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 24px;
                    color: white;
                }
            }

            .filter-list {
                padding-top: 12px;
            }
        }
    }
}

.horizontal-line {
    width: 100%;
    height: 1px;
    background-color: rgba(64, 64, 64, 1);
}