.stylized-wrapper {
  position: relative;
  width: fit-content;
  --background-color: linear-gradient(
    180deg,
    #4b3c29 0%,
    rgba(54, 44, 35, 0.8) 52.18%,
    #4b3c29 100%
  );
  --border-color: #e3c96e;
  --border-width: 1em;
  --edge-size: 0.08em;
  isolation: isolate;

  .stylized-button {
    // min-width: 300px;
    width: max-content;
    height: 50px;
    cursor: pointer;
    background: var(--background-color);
    padding: 0.5em 1.5em;
    border: 0;
    color: #fff5d0;
    text-transform: uppercase;
    font-family: var(--bigShoulders);
    position: relative;
    isolation: isolate;
    display: inline-grid;
    place-content: center;
    font-weight: 700;
    font-size: 32px;
    clip-path: polygon(
      var(--border-width) 0%,
      calc(100% - var(--border-width)) 0%,
      100% 50%,
      calc(100% - var(--border-width)) 100%,
      var(--border-width) 100%,
      0% 50%
    );
    transition: color 250ms;
    .stylized-text {
      font-size: 20px;
      border: 2px solid;
      border-image: radial-gradient(circle at center, #e3c96e38, #856f1f00) 2;
    }
  }

  .stylized-button::before {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--border-color);
    z-index: -2;
  }
  .stylized-button::after {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--background-color);
    z-index: -1;

    clip-path: polygon(
      var(--border-width) 0.05em,
      calc(100% - var(--border-width)) 0.05em,
      calc(100% - var(--edge-size)) 50%,
      calc(100% - var(--border-width)) calc(100% - 0.05em),
      var(--border-width) calc(100% - 0.05em),
      var(--edge-size) 50%
    );
  }

  .box-1 {
    position: absolute;
    border: 2px solid #e3c96e;
    width: 18px;
    height: 18px;
    top: calc(50% - 10px);
    left: 20px;
    transform: rotate(45deg);
  }
  .box-2 {
    position: absolute;
    border: 2px solid #e3c96e;
    width: 18px;
    height: 18px;
    top: calc(50% - 10px);
    right: 20px;
    z-index: -1;
    transform: rotate(45deg);
  }
}
