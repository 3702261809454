.gameRewardWrapperOverlay {
  width: 100%;
  height: 100vh;
  min-width: 1400px;
  position: fixed;
  // background-color: #071722;
  background: url("../../../images/backgrounds/rewardBG.png");
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  z-index: 12;

  @media screen and (max-width: 1075px) {
    overflow: auto;
    min-width: 100%;
  }
  .gameRewardWrapper {
    .rewardText {
      @media screen and (max-width: 1075px) {
        padding-top: 100px;
      }
      p {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
      }
      h1 {
        font-family: "Big Shoulders Display";
        font-style: normal;
        font-weight: 900;
        font-size: 88px;
        line-height: 88px;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
    .rewardBgDD {
      width: 603px;
      height: 440px;
      background: radial-gradient(
        41.45% 49.45% at 50% 51.55%,
        rgba(255, 83, 83, 0.429) 0%,
        rgba(255, 83, 83, 0) 100%
      );
    }
    .rewardBgPay {
      width: 603px;
      height: 440px;
      background: radial-gradient(
        41.45% 49.45% at 50% 51.55%,
        #ad964576 0%,
        rgba(10, 14, 16, 0) 100%
      );
    }
    .rewardBgHH {
      width: 603px;
      height: 440px;
      background: radial-gradient(
        41.45% 49.45% at 50% 51.55%,
        #e3ca6e87 0%,
        rgba(10, 14, 16, 0) 100%
      );
    }
    .rewardSpinner {
      -webkit-animation-name: rotate;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      -moz-animation-name: rotate;
      -moz-animation-duration: 2s;
      -moz-animation-iteration-count: infinite;
      -moz-animation-timing-function: linear;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url("../../../images/backgrounds/Rays.png");
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        -webkit-animation: spin 5s linear infinite;
        -moz-animation: spin 5s linear infinite;
        animation: spin 5s linear infinite, fade-in 2s ease-in-out;
        top: 5%;
        left: 2px;
        z-index: -1;
        background-size: 50%;
      }
    }
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.backgroundHide {
  position: absolute;
  background: radial-gradient(
    41.45% 49.45% at 50% 51.55%,
    #ad9645a4 0%,
    rgba(10, 14, 16, 0) 100%
  );

  width: 300px;
  height: 255px;
  z-index: -1;
  top: -38px;
  left: -86px;
  border-radius: 100%;
}
