.notification-container {
  box-sizing: border-box;
  position: fixed;
  z-index: 999999;
}

.top-right {
  top: 0.75rem;
  right: 0.75rem;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.bottom-right {
  bottom: 0.75rem;
  right: 0.75rem;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.top-left {
  top: 0.75rem;
  left: 0.75rem;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.bottom-left {
  bottom: 0.75rem;
  left: 0.75rem;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.notification {
  &.error {
    background: linear-gradient(
        98.28deg,
        rgba(255, 83, 83, 0.24) 0%,
        rgba(0, 0, 0, 0) 100%
      ),
      rgba(10, 14, 16, 0.85);
    border: 1px solid #485c65;
  }
  &.normal,
  &.normal-with-btns {
    background: #171717;
    border: 0.0625rem solid var(--primary-color);
  }
  &.success {
    background: linear-gradient(
        98.28deg,
        rgba(34, 114, 47, 0.3) 0%,
        rgba(0, 0, 0, 0) 100%
      ),
      rgba(10, 14, 16, 0.85);
    border: 1px solid #485c65;
  }
  &.warn {
    background: linear-gradient(
        98.28deg,
        rgba(240, 203, 71, 0.24) 0%,
        rgba(0, 0, 0, 0) 100%
      ),
      rgba(10, 14, 16, 0.85);
    border: 1px solid #485c65;
  }

  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  padding: 10px;
  gap: 0.75rem;
  // border-radius: 1rem;
  color: #ffffff;
  opacity: 1;
  margin-top: 0.9375rem;

  background-repeat: no-repeat;
}

.notification:hover {
  opacity: 1;
  cursor: pointer;
}

.notification-title {
  text-align: left;
  margin: 0;
  margin-bottom: 3px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  color: #ffffff;
}
.toastTextBtn {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 12%;
  .notification-message {
    margin: 0;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #9aa8ac;
    // white-space: nowrap;
  }
  .toastBtn {
    font-weight: 400;
    width: 120px;
    height: 35px;
    background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
    border: 1px solid #856f1f;
  }
}

.toaster-body {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  .toasterTop {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    .notification-image {
      align-self: flex-start;
    }
    .close-toaster {
      align-self: flex-start;
      margin-left: auto;
    }
    .toastTitleText {
      display: flex;
      justify-content: center;
      align-items: baseline;
      width: 100%;
      border-bottom: 1px solid #485c65;
    }
  }
  .smallToast {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    .notification-image {
      align-self: flex-start;
    }
    .close-toaster {
      align-self: flex-start;
      margin-left: auto;
    }
    .toastTitleText {
      display: flex;
      justify-content: end;
      align-items: baseline;
      width: 100%;
      .notification-title {
        margin: 0;
        // margin-bottom: 3px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-top: 5px;

        color: #ffffff;
      }
    }
  }
}
.notification-image {
  // margin-top: 0.1875rem;
  // margin-right: 0.5rem;
}

.notification-image img {
  width: 3rem;
  height: 3rem;
}

.toast {
  width: 25rem;
}

.notification-container button.close-toaster {
  position: relative;
  right: -0.25rem;
  top: 0.28rem;
  float: right;
  font-weight: 400;
  color: #bebebe;
  outline: none;
  border: none;
  text-shadow: 0 0.0625rem 0 #fff;
  opacity: 1;
  line-height: 1;
  font-size: 0.75rem;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@include max-break-point(mobile-phone) {
  .toast {
    width: 20rem !important;
  }
}
