.emeraldcity-leaflet-container {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // gap: 20px;
  // margin-top: 20px;
  // margin-bottom: 80px;
  margin-top: -15px;
  z-index: -1;
}

// .emeraldcity-leaflet-wrapper {
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
//   margin: 15px 0px;
// }

.emeraldcity-leaflet-btn {
  width: 215px !important;
}
.leaflet-control-zoom {
  display: none;
}
.leaflet-container {
  height: 100vh !important;
}
