.wars-history-tab-content {
    .green-border {
        border: 1px solid var(--green-color);
    }

    .red-border {
        border: 1px solid #ff5353;
    }

    .checkbox-label {
        font: var(--font-14);
        padding: 10px 16px;
        color: var(--grey-color);

        .checkbox-input {
            display: none;

            &:checked {
                + .custom-checkbox {
                    border: 1px solid var(--yellow-color);
                    &::after {
                        content: "";
                        background-color: var(--yellow-color);
                        width: 12px;
                        height: 9px;
                        clip-path: polygon(
                            15.08% 50.4%,
                            6.49% 62.82%,
                            46.47% 90.48%,
                            96.94% 17.52%,
                            84.71% 9.06%,
                            42.83% 69.6%
                        );
                    }
                }
            }
        }

        .custom-checkbox {
            cursor: pointer;
            width: 20px;
            height: 20px;
            border: 2px solid var(--grey-color);
            display: grid;
            place-content: center;
        }
    }

    .wars-history-table-wrapper {
        .wars-table {
            .team-block {
                min-width: 324px;
                // width: 25%;
            }

            .rewards-block {
                // width: 12%;
                min-width: 240px;
            }
            .spec-block {
                // width: 12%;
                min-width: 180px;
            }

            .war-items {
                .war-item {
                    > td {
                        padding-bottom: 23px;
                    }

                    .start-time-block {
                        font: normal 500 20px/24px var(--proximaNova);
                        color: #fff;
                        // font-size: 18px;
                    }

                    .team-block {
                        .user-nft-marker {
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                            background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
                            border: 1px solid var(--light-grey-color);
                            box-shadow: 0px 0px 4px rgba(10, 14, 16, 0.75);
                            text-align: center;
                            z-index: 1;
                            position: absolute;
                            top: 12%;
                            left: -23%;
                        }

                        .war-result-wrapper {
                            gap: 4px;
                            color: #fff;

                            .user-team {
                                font: normal 400 12px/16px var(--proximaNova);
                            }

                            .result {
                                padding: 4px 8px;
                                font: var(--font-14);
                                font-weight: 700;
                            }
                        }
                    }

                    .rewards-block {
                        grid-template-columns: 56px 56px;
                        grid-template-rows: 56px 56px;
                    }

                    .replay-btn-wrapper {
                        min-width: 150px;
                        width: 15%;

                        .primary-button {
                            margin: 0;
                            width: 125px;
                            font: var(--font-14);
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}
.paginationDiv {
    border: 1px solid #e3c96e;
    padding: 5px;
    background-color: #485c65;
}
