.header-wrapper {
  --headerItemBackground: url("../../../images/backgrounds/goldenBg.svg");
  height: 72px;
  background: rgba(10, 14, 16, 0.98);
  // max-width: 90rem;
  margin: auto;
  // position: relative;
  // isolation: isolate;
  // z-index: 4;
  .header-item-wrapper {
    min-width: 1400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-icons {
      position: relative;
      display: flex;
      gap: 20px;
      .notification-menu-list {
        width: 143px;
        background: rgba(10, 14, 16, 0.98);
        li {
          margin: 8px 0px;
          cursor: pointer;
        }
        position: absolute;
        list-style: none;
        color: white;
        border: 2px solid #c7a939;
        padding: 15px;
        top: 60px;
        left: -80px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        &::before {
          content: "";
          position: absolute;
          top: -15px;
          right: 13px;
          width: 10px;
          height: 10px;
          border: 7px solid transparent;
          border-bottom: 7px solid #c7a939;
        }
      }
      .user-menu-list {
        z-index: 4;
        width: 163px;
        background: rgba(10, 14, 16, 0.98);
        display: flex;
        flex-direction: column;
        gap: 20px;
        li {
          // margin: 10px 0px;

          cursor: pointer;
        }
        li:hover {
          color: rgba(255, 255, 255, 0.728);
          // background: linear-gradient(90deg, #0a0e10 0%, #485c65 166.67%);
        }
        position: absolute;
        list-style: none;
        color: #70878f;
        border: 2px solid #9aa8ac;
        padding: 15px;
        top: 90px;
        // left: 30px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        // &::before {
        //   content: "";
        //   position: absolute;
        //   top: -13px;
        //   right: 30px;
        //   width: 10px;
        //   height: 10px;
        //   border: 7px solid transparent;
        //   border-bottom: 7px solid #9aa8ac;
        // }
      }
    }
    .header-items {
      display: flex;
      font-family: var(--bigShoulders);
      .selected-item-header {
        background: var(--headerItemBackground),
          radial-gradient(
            100% 114.47% at 50.43% 0%,
            rgba(10, 14, 16, 0) 38.02%,
            #fac813 100%
          );
        background-repeat: no-repeat;
        background-size: cover;
        border: 1px solid #ffc600;
      }
      .unSelected-item-header {
        background: var(--headerItemBackground);
        background-repeat: no-repeat;
        background-size: cover;
        // filter: invert(0.3);
        border: 1px solid grey;
      }
      .alertEnable {
        border-bottom: 1px solid #7b6d38;
        box-shadow: 0px 2px 9px 3px #7b6d38;
      }

      .header-item {
        cursor: pointer;
        width: 158px;
        height: 71px;
        isolation: isolate;
        z-index: 2;
        font-weight: 800;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #ffffff;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        transition: background-color 0.5s ease;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0;
          background: var(--headerItemBackground),
            radial-gradient(
              80.62% 114.47% at 50.43% 0%,
              rgba(10, 14, 16, 0) 38.02%,
              #ffc800c0 100%
            );
          transition: height 0.5s ease;
          z-index: -2;
        }
        &:hover {
          background: var(--headerItemBackground),
            radial-gradient(
              80.62% 114.47% at 50.43% 0%,
              rgba(10, 14, 16, 0) 38.02%,
              #ffc800c0 100%
            );
          &:after {
            height: 100%;
          }
          background-size: cover;
          background-repeat: no-repeat;
        }

        .header-item-alert {
          position: absolute;
          width: 25px;
          box-shadow: 0px 0px 9px 3px #e3c96e;
          height: 25px;
          z-index: -1;
          border: 1px solid #e3c96e;
          bottom: calc(-20% + 1px);
          transform: rotate(45deg);
          z-index: 2;
          background: var(--headerItemBackground),
            radial-gradient(
              80.62% 114.47% at 50.43% 0%,
              #7b6d38 38.02%,
              #a08629 100%
            );
          background-size: cover;
          background-repeat: no-repeat;
          filter: invert(0);
          .alertText {
            color: white;
            transform: rotate(315deg);
          }
        }

        .header-item-style-box-1 {
          position: absolute;
          width: 25px;
          height: 25px;
          border: 1px solid #e3c96e;
          bottom: calc(-20% + 1px);
          transform: rotate(45deg);
          z-index: 2;
          background: var(--headerItemBackground),
            radial-gradient(
              80.62% 114.47% at 50.43% 0%,
              #7b6d38 38.02%,
              #ffc600 100%
            );
          background-size: cover;
          background-repeat: no-repeat;
        }
        .header-item-style-box-2 {
          position: absolute;
          width: 20px;
          height: 20px;
          border: 1px solid #e3c96e;
          bottom: calc(-20% + 4px);
          transform: rotate(45deg);
          z-index: 1;
          right: 55%;
          background: var(--headerItemBackground),
            radial-gradient(
              80.62% 114.47% at 50.43% 0%,
              #b8a14d 38.02%,
              #ffc600 100%
            );
          background-size: cover;
          background-repeat: no-repeat;
        }
        .header-item-style-box-3 {
          position: absolute;
          width: 20px;
          height: 20px;
          border: 1px solid #e3c96e;
          bottom: calc(-20% + 4px);
          transform: rotate(45deg);
          z-index: 1;
          right: 30%;
          background: var(--headerItemBackground),
            radial-gradient(
              80.62% 114.47% at 50.43% 0%,
              #b8a14d 38.02%,
              #ffc600 100%
            );
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
.connectWalletDrop {
  margin-right: 30px;
}
.Selected-dropdown {
  background: linear-gradient(
    180deg,
    rgba(10, 14, 16, 0) 0%,
    rgba(255, 198, 0, 0.5) 100%
  );
  opacity: 0.7;
}
.crown-text-color {
  font-family: "Big Shoulders Display";
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  display: flex;
}

.drop-down-menu-wallet {
  border-left: 1px solid #485c65;
  border-right: 1px solid #485c65;
  width: 158px;
  height: 71px;
  // margin-right: 20px;
  cursor: pointer;
}
.drop-down-menu-volume {
  border-right: 1px solid #485c65;
  width: 50px;
  height: 71px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.text-header-wallet {
  font-family: "Big Shoulders Display";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  display: flex;
  height: 71px;
  justify-content: center;
  align-items: center;
}

.wallet-connection-dropdown {
  font-family: "Big Shoulders Display";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #70878f;
}
