.volumeControllerWrapper {
    z-index: 4;
    width: 187px;
    background: rgba(10, 14, 16, 0.98);
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    list-style: none;
    color: #70878f;
    border: 2px solid #9aa8ac;
    padding: 15px;
    top: 90px;
    overflow: hidden;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    .volumeController {
        .volumeText {
            font-family: "Proxima Nova";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 8px;
            color: #70878f;
        }
        .masterController {
            input[type="range"]::-webkit-slider-runnable-track {
                background: linear-gradient(to left, #e3c96e, #e3c96e) no-repeat left;
                background-size: var(--slider-progress-master, 0%) 100%;
                background-repeat: "no-repeat";
                background-position: "center";
            }
        }
        .effectController {
            input[type="range"]::-webkit-slider-runnable-track {
                background: linear-gradient(to left, #e3c96e, #e3c96e) no-repeat left;
                background-size: var(--slider-progress-effect, 0%) 100%;
                background-repeat: "no-repeat";
                background-position: "center";
            }
        }
        .musicController {
            input[type="range"]::-webkit-slider-runnable-track {
                background: linear-gradient(to left, #e3c96e, #e3c96e) no-repeat left;
                background-size: var(--slider-progress-music, 0%) 100%;
                background-repeat: "no-repeat";
                background-position: "center";
            }
        }
        .volumeIncDec {
            input[type="range"] {
                -webkit-appearance: none;
                width: 100%;
                height: 8px;
                background-color: #ddd;
            }

            input[type="range"]::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 11px;
                height: 11px;
                background-color: white;
                cursor: pointer;
            }
            .volumeSlider {
                width: 100%;
            }
        }
    }
}
